const envUtils = {
  getCurrentProfile: () => {
    return process.env.REACT_APP_PROFILE;
  },
  isProfileMatchesWithValue: (value) => {
    return process.env.REACT_APP_PROFILE === value;
  },
  getCurrentMondayApp: () => {
    console.log(process.env.REACT_APP_MONDAY);
    return process.env.REACT_APP_MONDAY.toString();
  },
  
};

export default envUtils;
