import React, {useState, useEffect} from 'react';
import { BsFileTextFill } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import EnquiryForm from '../Marketing/EnquiryForm';
import {Button} from "antd";
import { MdMarkEmailRead } from "react-icons/md";
import { analytics } from '../../firebase';
import { logEvent } from '@firebase/analytics';
import { version } from '../../../src/version';

function Help() {
  const [open, setOpen] = useState(false);  
  const customStyle = {
    color : "#1B2F78",
  }

  const buttonStyle = {
    color : "#1B2F78", paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0
  }
  const handleOk = () => {
    setOpen(true);
    };

  const handleCancel = (value) => {
    setOpen(value);
    };

  const EnquiryFormModal = () => {    
    if (open) {      
        return (
            <EnquiryForm show={open} setShow={setOpen} open={open} handleOk={handleOk} handleCancel={handleCancel}
                onCancel={() => setOpen(false)}                
            />
        )
    }
    else {
        return (<></>)
    }
    }

  useEffect(() =>{
    logEvent(analytics,"help" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  return (
    <div style={{ margin: '20px' }}>
        <p> Version: <b>{version}</b></p>
        <p>Welcome to TimeRely! This application helps you to manage timesheets using app's unified board view. 
          Here, you can effortlessly add time entries, handle them through single or bulk approvals, 
          and make edits or resubmissions as needed. Dive into comprehensive analysis by applying filters such as user, project, or timeline to scrutinize all entries. 
          Generate summary reports based on projects or users and seamlessly integrate them back into your chosen Monday.com boards. </p>
        <p></p>
        <BsFileTextFill style={{ marginRight: '5px', color:'rgb( 79, 94, 229)' }} />
        <a href="https://apprely.com/timerely-guide/" target="_blank" rel="noopener noreferrer" style={customStyle}>TimeRely Manual</a>
        <p></p>
        <FaYoutube style={{ marginRight: '5px' , color:'red'}}/>
        <a href="https://www.youtube.com/watch?v=QEFiUPK2NbM&t=115s" target="_blank" rel="noopener noreferrer" style={customStyle}>TimeRely You Tube Guide</a>
        <p></p>            
        <MdMarkEmailRead style={{ marginRight: '5px' , color:'green'}}/>        
        <Button type="link" className="primary" style= {buttonStyle}  onClick={() => setOpen(true)}>
          {" "}
          Contact us{" "}
        </Button>
        <EnquiryFormModal/>
    </div>
  );
}

export default Help
