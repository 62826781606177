import React, { useState, useEffect, Fragment, useCallback } from "react";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Empty } from 'antd';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import moment from "moment";
import { Dropdown, Table, Button, Select, Modal, Tooltip as TooltipN, Space, Card, Skeleton } from "antd";
import { read, utils, writeFile } from "xlsx";
import {
  ExportOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./chart.css"
import {
  collection,
  query,
  where,
  and,
  onSnapshot,
  or,
} from "firebase/firestore";
import { constants } from "../../constants";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import sentryUtils from "../../utils/sentryUtils";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  let loggedInUser = useSelector(state => state.userSlice.user)
  const [dataSource, setDataSource] = useState([]);
  const [selfViewDataSource, setSelfViewDataSource] = useState([]);
  const [userLogData, setUserLogData] = useState([])
  const [userProjectIds, setUserProjectIds] = useState([])
  const [count, setCount] = useState(2);
  const [loading, setLoading] = useState(true);
  const [selfView, setSelfView] = useState(true);
  const [teamView, setTeamView] = useState(false);


  const weekOptions = [
    {
      value: "thisweek",
      label: "This week",
    },
    {
      value: "lastweek",
      label: "Last week",
    }
  ];

  const [selectedWeek, setSelectedWeek] = useState(weekOptions[0])

  const handleTeamview = () => {
    setTeamView(true)
    setSelfView(false);
  };
  const handleselfview = () => {
    setSelfView(true)
    setTeamView(false);
  };

  const selfViewButtonStyle = {
    color: selfView ? "#1B2F78" : "grey",
    background: selfView ? "#FBFBFB" : "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: selfView ? "600" : "400",
    borderColor: selfView ? "none":"#E0E0E0",
    borderRadius: "2px",
  };

  const teamViewButtonStyle = {
    color: teamView ? "#1B2F78" : "grey",
    background: teamView ? "#FBFBFB" : "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "14px",
    borderColor: teamView ? "none":"#E0E0E0",
    fontWeight: teamView ? "600" : "400",
    borderRadius: "2px",
    padding: "4px 15px"
  };


  const user = firebase.auth().currentUser;
  const dispatch = useDispatch();

  function formatTime(totalMins) {
    var hours = Math.floor(totalMins / 60);
    var minutes = totalMins % 60;
    return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
  }

  const createDataSource = (userLogData) => {

    let newData = null
    let finalDataSourceLength = 1
    let finalDataSource = []

    let groupByProject = groupBy(userLogData, 'project', true)
    // console.log(groupByTask)

    if (groupByProject.length) {

      finalDataSourceLength = groupByProject.length

      var d = [] // for day
      var h = [] // for logHours
      var b = [] // for billable

      var docIds = []
      d.length = 7
      b.length = 7

      groupByProject.forEach((element, index) => {
        docIds = []
        d = []
        h = []
        b = []

        element.forEach((v) => {
          h.push(v.logHours)
          d.push(v.logDate)
          b.push(v.billable)
        })

        // console.log("billable",b)
        let totalBillableHrs = 0;
        let totalNonBillableHrs = 0;

        for (let i = 0; i < d.length; i++) {
          if (h[i]) {
            let time = h[i].split(':');
            let totalMins = (parseInt(time[0]) * 60) + parseInt(time[1]);
            if (b[i]) {
              totalBillableHrs += totalMins;
            } else {
              totalNonBillableHrs += totalMins;
            }
          }
        }

        let billableHours = formatTime(totalBillableHrs);
        let nonBillableHours = formatTime(totalNonBillableHrs);

        newData = {
          key: index + 1,
          project: element[0].project.value,
          totalbillable_hrs: billableHours,
          totalnonbillable_hrs: nonBillableHours
        };
        finalDataSource.push(newData)
      })
      // console.log('finalDataSource', finalDataSource)
      setDataSource(finalDataSource);
      // setCount(count + finalDataSourceLength);
    } else {
      // setDataSource([]);
      setCount(0);
    }
  };

  function groupBy(collection, property, groupByKeyAttribute = false) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      if (groupByKeyAttribute) {
        val = (typeof collection[i][property] == 'object') ? collection[i][property].key : collection[i][property];
      } else {
        val = (typeof collection[i][property] == 'object') ? collection[i][property].value : collection[i][property];
      }
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const db = firebase.firestore();
          const accountId = localStorage.getItem("accountId");
          const timeEntryRef = db.collection("timeEntry");

          let start, end;

          if (selectedWeek === "lastweek") {
            start = moment().startOf("week").subtract(1, "week").format(constants.dateTime.DATE);
            end = moment().endOf('week').subtract(1, "week").format(constants.dateTime.DATE);
            // console.log("end", end,)
          } else {
            start = moment().startOf('week').format(constants.dateTime.DATE);
            end = moment().endOf('week').format(constants.dateTime.DATE);
          }
          start = new Date(new Date(start).setUTCHours(-5, -30, 0));
          end = new Date(new Date(end).setUTCHours(18, 29, 59));

          let queryRef;

          if (selfView) {
            queryRef = await timeEntryRef
              .where("userId", "==", user.uid)
              .where("accountId", "==", accountId)
              .where("deleted", "==", false)
              .where("logDate", ">=", start)
              .where("logDate", "<=", end)
              .get();
          } 
          if (teamView) {
            queryRef = timeEntryRef
              .where("accountId", "==", accountId)
              .where("deleted", "==", false)
              .where("logDate", ">=", start)
              .where("logDate", "<=", end)
              // .where("approvers", "array-contains", user.uid)
              // .get()
            if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
              queryRef = await queryRef
                .get()
            } else {
              queryRef = await queryRef
                .where("approvers", "array-contains", user.uid)
                .get()
            }
          }

          const updatedData = [];
          queryRef.forEach((doc) => {
            const data = doc.data();
            data.logDate = moment(data.logDate.seconds * 1000).format(
              constants.dateTime.DATE
            );
            updatedData.push(data);
          });

          const userLogData = updatedData.map(element => ({
            docId: element.docId,
            project: element.project,
            task: element.task,
            subtask: element.subtask,
            logDate: element.logDate,
            logHours: element.logHours,
            billable: element.billable,
            description: element.description,
            approvalStatus: element.approvalStatus,
          }));

          const userProjectIds = updatedData.map(element => element.project.key);

          setUserProjectIds(userProjectIds);
          setUserLogData(userLogData);
          createDataSource(userLogData);
          setLoading(false);
          console.log("dashboard")
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
          sentryUtils.captureFirestoreException('fetchData', error, 'Dashboard');
      }
      };

      fetchData();
    }
  }, [selfView, teamView,selectedWeek,user]);

  useEffect(() =>{
    const dashboardView = teamView?"teamView":"selfView"
    logEvent(analytics,"dashbord_view_" + dashboardView , { "account_id": localStorage.getItem("accountId") });
  }, [teamView])

  const handleWeekChange = selectedOption => {
    setSelectedWeek(selectedOption);
  };

  // there will minimum 4 rows
  const adjustDataLength = (data) => {
    const { projects, billable, nonbillable } = data;
    const targetLength = 4;

    while (projects.length < targetLength) {
      projects.push(" ");
    }

    while (billable.length < targetLength) {
      billable.push(null);
    }

    while (nonbillable.length < targetLength) {
      nonbillable.push(null);
    }

    return data;
  };

  const barData = {
    projects: [],
    billable: [],
    nonbillable: []
  };



  const timeStringToHours = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const totalHours = hours + (minutes / 60);
    return totalHours;
  };

  if (userProjectIds.length > 0 && userLogData.length > 0){  dataSource.forEach(item => {
    barData.projects.push(item.project)
    barData.billable.push(timeStringToHours(item.totalbillable_hrs))
    barData.nonbillable.push(timeStringToHours(item.totalnonbillable_hrs))
  })} 
  
  adjustDataLength(barData);

  const truncatedLabels = barData.projects.map(project => {
    if (project.length > 12) {
      return project.substring(0, 12) + '...';
    }
    else {
      return project;
    }
  });

  const barFormattedData = {
    labels: truncatedLabels,
    datasets: [
      {
        label: "Non Billable",
        data: barData.nonbillable,
        backgroundColor: 'rgba(244,190,50)',
        stack: 'Stack 0',
        barPercentage: 0.4

      },
      {
        label: "Billable",
        data: barData.billable,
        backgroundColor: 'rgba(45,206,183)',
        stack: 'Stack 0',
        barPercentage: 0.4

      },
    ]
  };

  let scrollData = (arr1, arr2) => {
    let result = []
    for (let i = 0; i < arr1.length; i++) {
      result.push(arr1[i] + arr2[i]);
    }
    return [result.reduce((max, current) => {
      return current > max ? current : max;
    }, result[0])];
  };

  const scrollBarFormattedData = {
    labels: [""],
    datasets: [
      {
        label: " ",
        data: scrollData(barData.nonbillable, barData.billable),
        barPercentage: 0,
      }
    ]
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis:'y',
      intersect: false,
    },
    layout: {
      padding: {
        right: 20,
        left: 1
      }
    },
    indexAxis: "y",
    scales: {
      y: {
        stacked: true,
        grid: {
          ticklength: 8,
          drawTicks: false
        },
        ticks: {
          textStrokeWidth: 100,
          font: {
            size: 12
          }
        },
        afterFit(scale) {
          scale.width = 90;
        },
        reverse: true
      },
      x: {
        beginAtZero: true,
        min: 0,
        max: 50,
        stacked: true,
        ticks: {
          stepSize: 10,
          display: false
        },
        afterFit(scale) {
          scale.height -= 100;
        },
        grid: {
          drawBorder: false
        }
      },
    },
    plugins: {
      tooltip: {
        filter: function (tooltipItems, data) {
          return tooltipItems.label !== " "
        },
        itemSort: function (a, b) {
          console.log(a);
          return b.raw - a.raw;
        },
        animation:false,
        callbacks: {
          title: function (tooltipItems, data) {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              if ((tooltipItem.label !== " ") && tooltipItem.dataset.label !== " ") {
                sum += Number(tooltipItem.formattedValue)
              }
            });

            if ((tooltipItems.some(item => item.label !== " "))) {
              return "Total Summary" + " " + sum
            }
            else {
              return;
            }
          },
          label: function (tooltipItems, data) {
            if (tooltipItems.label !== " " && tooltipItems.dataset.label !== " ") {
              console.log(tooltipItems)
              return tooltipItems.dataset.label + " : " + tooltipItems.formattedValue + "hrs"
            }
          },
        }
      },
      legend: {
        display: false
      }

    },
  };

  const scrollBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    layout: {
      padding: {
        right: 1,
        bottom: 1,
        left: 1
      }
    },
    indexAxis: "y",
    scales: {
      y: {
        stacked: true,
        grid: {
          ticklength: 8,
          drawTicks: false
        },
        ticks: {
          font: {
            size: 12
          }
        },
        afterFit(scale) {
          scale.width = 90;
        },
      },
      x: {
        beginAtZero: true,
        min: 0,
        max: 50,
        stacked: true,
        ticks: {
          stepSize: 10,
        },
        afterTickToLabelConversion: (ctx) => {
          console.log("ticks", ctx.ticks)
          ctx.ticks.forEach(item => {
            let label = String(item.label)
            if (!item.label.endsWith("hrs")) {
              item.label = label + " hrs";
            }
          })
        },
        grid: {
          drawTicks: false,
          drawBorder: false
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        labels: {
          generateLabels: (chart) => {
            return [
              {
                text: "Nonbillable",
                strokeStyle: 'rgba(244,190,50)',
                fillStyle: 'rgba(244,190,50)'
              },
              {
                text: "Billable ",
                strokeStyle: 'rgba(45,206,183)',
                fillStyle: 'rgba(45,206,183)'
              }
            ]
          },
          boxWidth: (legend) => legend.maxHeight
        }

      }

    },
  };

  const configBar = {
    height: "100px",
    options: barOptions,
    data: barFormattedData,
  }

  const configscrollableBar = {
    options: scrollBarOptions,
    data: scrollBarFormattedData,
  }

  // pie chart
  const sumBillable = barData.billable.reduce((acc, curr) => acc + curr, 0);
  const sumNonBillable = barData.nonbillable.reduce((acc, curr) => acc + curr, 0);

  const pieData = { item: ["Nonbillable", "billable"], hrs: [sumNonBillable, sumBillable] }

  const formatedpieData = {
    labels: pieData.item,
    datasets: [
      {
        label: 'Hrs',
        data: pieData.hrs,
        backgroundColor: [
          'rgba(244,190,50)',
          'rgba(45,206,183)'
        ],
        cutout: '70%',
        rotation: 45
      }]
  }

  const pieOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: 10
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 20,
          boxWidth: (legend) => legend.maxHeight,
          align: 'start'
        }
      }

    }
  };

  const doughnutLabelIsLine = {
    id: 'doughnutLabelIsLine',
    afterDraw(chart, args, options) {
      const { ctx, chartArea: { top, bottom, left, right, width, height } } = chart;
      ctx.save();

      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
          const halfheight = height / 2;
          const halfwidth = width / 2;

          const xLine = x >= halfwidth ? x + 12 : x - 12;
          const yLine = y >= halfheight ? y + 15 : y - 15;
          const extraLine = x >= halfwidth ? 35 : -35;
          const extraxLine = x >= halfwidth ? 12 : -12;
          const extrayLine = y >= halfheight ? -9 : 9;

          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = dataset.backgroundColor[index];
          ctx.stroke();

          const total = chart.data.datasets[i].data.reduce((acc, val) => acc + val, 0);
          const percentage = Math.round((chart.data.datasets[i].data[index] / total) * 100) + "%";

          ctx.font = '12px Arial';
          ctx.fillStyle = dataset.backgroundColor[index];

          ctx.textAlign = x >= halfwidth ? 'left' : 'right';
          ctx.fillText(percentage, xLine + extraxLine, yLine - extrayLine);
        });
      });
      ctx.restore();
    }
  };


  const textCentre = {
    id: "textcentre",
    beforeDatasetDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart
      const xCoor = chart.getDatasetMeta(0).data[0].x
      const yCoor = chart.getDatasetMeta(0).data[0].y

      ctx.save()
      ctx.font = "25px Poppins";
      ctx.fillStyle = '#3464CE';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const total = chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
      ctx.fillText(`${total}`, xCoor, yCoor - 8
      )

      ctx.font = "12px Poppins";
      ctx.fillStyle = '#9B9B9B';
      ctx.fillText(`Week Hours`, xCoor, yCoor + 12
      )
      ctx.restore();
    }
  }

  const configPie = {
    data: formatedpieData,
    options: pieOptions,
    plugins: [doughnutLabelIsLine, textCentre],
  }

  let filteredProjects = barData.projects.filter(project => project.length > 1);

  const containerBodyStyle = {
    height: 250,
    width: 800,
    marginBottom: 0,
    paddingBottom: 0
  };



  const labelLength = barFormattedData.labels.length
  if (labelLength > 4) {
    const newHeight = 250 + ((labelLength - 4) * 50)
    containerBodyStyle.height = `${newHeight}px`;
  }

  const actualMaxtick = scrollBarFormattedData.datasets[0].data[0]
  let maxTick = Math.ceil(actualMaxtick / 25) * 25
  if (maxTick > 45) {
    let newStepSize = (maxTick / 5)
    if (Math.abs(maxTick - actualMaxtick) < Math.round(newStepSize/5)) {
      maxTick += 25;
      newStepSize += 5;
    }
    barOptions.scales.x.max = maxTick
    barOptions.scales.x.ticks.stepSize = newStepSize
    scrollBarOptions.scales.x.max = maxTick 
    scrollBarOptions.scales.x.ticks.stepSize = newStepSize
  }

  const exportFile = useCallback(
    (fileType) => {
      console.log(fileType);
      let exportData = [];
      dataSource.forEach((record, index) => {
        exportData.push({
          Index: index + 1,
          Project: record.project,
          BillableHrs: record.totalbillable_hrs,
          NonBillableHrs: record.totalnonbillable_hrs,
        });
      });
      /* generate worksheet */
      const ws = utils.json_to_sheet(exportData);
      /* create workbook and append worksheet */
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      /* export to XLSX */
      writeFile(
        wb,
        `${(selfView ? "Selfview-" : "Teamview") + moment().format("DD-MM-YYYY-hh-mm") + fileType}`
      );
    },
    [selfView, dataSource]
  );
  const items = [
    {
      key: "1",
      onClick: () => exportFile(".xlsx"),
      label: <span> XLSX (MS Excel) </span>,
    },
    {
      key: "2",
      onClick: () => exportFile(".xls"),
      label: <span> XLS (MS Excel 1997-2004) Compatible </span>,
    },
    {
      key: "3",
      onClick: () => exportFile(".csv"),
      label: <span> CSV (Comma Seperated Value) </span>,
    },
  ];

  return (
    <div style={{ padding: '25px', display: 'flex', gap: '20px', overflow: "auto scroll" }}>
      <Card
        bordered={false}
        style={{
          boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.4)',
          width: 950,
          borderRadius: '10px',
          height: 430,
        }}
        bodyStyle={{ padding: "12px 24px 12px 24px" }}>
        <div className="card-header" style={{ display: 'flex', flexDirection: 'column', padding: "5px 10px 5px 10px" }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.1rem', paddingBottom: '0.1rem' }}>
            <span style={{ marginRight: 20, fontWeight: 'bold', fontSize: '18px', fontFamily: 'Poppins' }}>Timeline </span>
            <div className="view-button" style={{ marginLeft: 12, width: 200 }}>
              <Button onClick={handleselfview} style={selfViewButtonStyle} active={selfView}>Self view</Button>
              <Button onClick={handleTeamview} style={teamViewButtonStyle} active={teamView}>Team view</Button>
            </div>
            <div style={{ marginLeft: 250 }}>
              <Space direction="horizontal">
                <Select
                  placeholder="This week"
                  style={{ width: 140, marginTop: 0, fontSize: '14px', fontFamily: 'Poppins' }}
                  options={weekOptions}
                  value={selectedWeek}
                  onChange={handleWeekChange}
                />
                <Dropdown
                  menu={{ items }}
                  disabled={!userLogData.length}
                  placement="bottomLeft"
                  arrow
                  style={{ lineHeight: '1.5', fontSize: '14px', fontFamily: 'Poppins' }}
                >
                  <Button
                    type="primary"                    
                    disabled={userLogData.length > 0 ? false:true}
                    icon={<ExportOutlined />}
                  >
                    Export As
                  </Button>
                </Dropdown>

              </Space>
            </div>
          </div>
          <span style={{ marginRight: 20, fontSize: '14px', fontFamily: 'Poppins', marginBottom: '0.25rem', marginTop: '0' }}>Total {`${filteredProjects.length} ${filteredProjects.length < 2 ? "project" : "projects"}`}  </span>
          {loading ? (
            <Skeleton.Image active={loading} />
          ) : (
            <>
              <div className="container" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <div className="containerBody" style={containerBodyStyle}>
                  <Bar {...configBar} />
                </div>
              </div>
              <div className="container" style={{ overflow: "hidden", marginTop: 0, paddingTop: 0, maxHeight: 80 }}>
                <div className="containerBody" style={{ height: 60, width: 800 }}>
                  <Bar {...configscrollableBar} />
                </div>
              </div>
            </>
          )}
        </div>

      </Card>

      <Card
        title="Use Hour Summary"
        bordered={false}
        style={{ boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.4)', width: 450, borderRadius: '10px', height: 430 }}>
        {loading ? (
          <Skeleton.Image active={loading} />
        ) : (
          <div className="doughnut-container">
            <div className="doughnutBody" style={{ minHeight: 250, minWidth: 250 }}>
              {
                !userLogData.length ? <Empty/> : <Doughnut {...configPie} />
              }
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
export default Dashboard;