import React , {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom";

import { collection, query, where, and, onSnapshot } from "firebase/firestore";
import firestore from "../../firebase";
import subscriptionStatus from "../../constants/subscription"


import { Sidebar } from './Sidebar'
import { Topbar } from './Topbar';
import { setToggleSidebar, setToggleMobileSidebar } from '../../store/UserData';
import { Enquiry } from '../Marketing/Enquiry'
import { Resubmission } from './Resubmission'
import {GuidedTour} from '../GuidedTour'
import {BillingBanner} from './BillingBanner'

export const Master = () => {
    let sidebarStyle = useSelector(state => state.userSlice.sidebarStyle)
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false); 
    const [maxUserLimit, setMaxUserLimit] = useState(0);
    const [userSubscriptionStat, setUserSubscriptionStat] = useState(null);
    const navigate = useNavigate()
    const changeNavStyle = () => {
        if (sidebarStyle.indexOf('toggled') == -1) {
            sidebarStyle = `${sidebarStyle} toggled`
        } else {
            sidebarStyle = sidebarStyle.replace(/toggled/g, '');
        }
        dispatch(setToggleSidebar(sidebarStyle))
    }

    const changeMobileNavStyle = () => {

        if (sidebarStyle.indexOf('mobile_sidebar_toggled') == -1) {
            sidebarStyle = `${sidebarStyle} mobile_sidebar_toggled`
        } else {
            sidebarStyle = sidebarStyle.replace(/mobile_sidebar_toggled/g, '');
        }
        dispatch(setToggleMobileSidebar(sidebarStyle))
    }

    useEffect(() => {
        const accountId = localStorage.getItem("accountId");
        const mondayRef = collection(firestore, "mondayAccounts");
        const mondayQuery = query(
          mondayRef,
          and(
            where("accountId", "==", accountId),
          )
        );
        const accSnapshot = onSnapshot(mondayQuery, (querySnapshot) => {
          if (querySnapshot.size > 0) {
            const subscriptionStat = querySnapshot.docs[0]?.data()?.subscriptionStatus;
            console.log("subscriptionStat", subscriptionStat)
            setUserSubscriptionStat(subscriptionStat)
            if (subscriptionStat === subscriptionStatus.SUBSCRIPTION_EXPIRED) {
                navigate(`/SubscriptionExpired/${subscriptionStatus.SUBSCRIPTION_EXPIRED}`)
            } else if (subscriptionStat === subscriptionStatus.TRIAL_ENDED) {
                navigate(`/TrialEnded/${subscriptionStatus.TRIAL_ENDED}`)
            } else {
                if (subscriptionStat === subscriptionStatus.SUBSCRIBED){
                setShowAlert(false);
                } else {
                setShowAlert(true)
                } 
                const userCount = querySnapshot.docs[0]?.data()?.usersLimit;
                console.log("maxUserLimit", userCount)
                setMaxUserLimit(userCount);
            } 
            
          }
        });
        return () => {
          console.log("componentWillUnmount");
          accSnapshot();
        };
      }, [])


    return (
        <div>
            {/* <body id="page-top"> */}

            {/* <!-- Page Wrapper --> */}
            <div id="wrapper">

                {userSubscriptionStat && <><Sidebar navStyle={sidebarStyle} sidebarHandler={changeNavStyle} subscriptionStat={userSubscriptionStat}/>

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">
                        {/* add billing banner */}
                        <BillingBanner maxUserLimit={maxUserLimit}/>
                        <Enquiry showAlert={showAlert} />
                        <Resubmission/>
                        {/* <GuidedTour/> */}
                        <GuidedTour sidebarStyle={sidebarStyle} />
                        {/* <Topbar navStyle={sidebarStyle} sidebarHandler={changeMobileNavStyle} /> */}
                        <Outlet />

                    </div>
                    {/* <!-- End of Main Content --> */}

                    {/* <Footer /> */}

                </div>
                {/* <!-- End of Content Wrapper --> */}
                </>}
            </div>
            {/* <!-- End of Page Wrapper --> */}
            {/* </body> */}
        </div>

    )
}
