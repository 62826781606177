import React, { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import MondayConfig from "../../configs/MondayConfig";
import PropTypes from "prop-types";
import "../../index.css";
import subscriptionStatus from "../../constants/subscription";
import accessDeniedImage from "../../assets/images/subscription.png";
import { useParams } from "react-router-dom";

const Subscription = ({ theme }) => {
  const [isVisible, setIsVisible] = useState(true);
  const { status } = useParams();
  console.log('status:', status);
  console.log("inside subscription page!");
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);

  let imageSrc;
  let paragraphTitle;
  let paragraphText;

  // setCondition(props.condition)
  // eslint-disable-next-line default-case
  switch (status) {
    case subscriptionStatus.SUBSCRIPTION_EXPIRED:
      imageSrc = accessDeniedImage;
      paragraphTitle = "Subscription Expired";
      paragraphText =
        "Your subscription has expired. To avoid service interruption, please renew or upgrade your plan.";
      break;
    case subscriptionStatus.USER_LIMIT_REACHED:
      imageSrc = accessDeniedImage;
      paragraphTitle = "Maximum User Limit Reached!";
      paragraphText =
        "You’ve reached the maximum user limit for your current subscription. Consider upgrading to accommodate more users.";

      break;
    case subscriptionStatus.TRIAL_ENDED:
      imageSrc = accessDeniedImage;
      paragraphTitle = "Trial Subscription Ended!";
      paragraphText =
        "Your trial period has ended. To continue enjoying our services, please choose a plan that suits your needs.";
      break;
    case subscriptionStatus.ACCESS_DENIED:
      imageSrc = accessDeniedImage;
      paragraphTitle = "Access Denied!";
      paragraphText =
        'As you have cancel authorization, you are unable to use the "TimeRely" app.';
      break;
    // default:
    //   paragraphTitle = 'Default Title';
    //   paragraphText = 'Default text for cases where no condition is met.';
  }

  const openMarketplace = () => {
    monday.execute("openPlanSelection");
  };
  useEffect(() => {
    document.body.setAttribute("data-theme", `${theme ?? 'light'}`);
  }, [theme]);

  if (!isVisible) {
    return null;
  }
  return (
    <div className="main-container form-container">
      <div className="form-container form-card">
        {/* <button className="close-button" onClick={() => setIsVisible(false)}>
          &times;
        </button> */}
        <img src={imageSrc} alt="Description" className="form-image" />
        <hr className="form-line form-line-hidden" />
        <p className="form-paragraph form-paragraph-title">{paragraphTitle}</p>
        <hr className="form-line form-line-hidden" />
        <p className="form-paragraph form-paragraph-text">{paragraphText}</p>
        {status !== subscriptionStatus.ACCESS_DENIED && (
          <button className="see-plan-button" onClick={openMarketplace}>
            Upgrade Plan &gt;
          </button>
        )}
      </div>
    </div>
  );
};

Subscription.propTypes = {
  condition: PropTypes.oneOf([
    "subscriptionExpired",
    "maximumLimitExceeded",
    "trialExpired",
  ]).isRequired,
  onSeePlanClick: PropTypes.func.isRequired,
};

export default Subscription;
/*
border, border colour, pass constants, use constants, see plans button condition
*/