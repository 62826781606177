import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'userData',
    initialState: {
        user: null,
        sidebarStyle: 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion',
    },
    reducers: {
        setToggleSidebar: (state, action) => {
            return {
                ...state,
                sidebarStyle: action.payload
            }
        },
        setToggleMobileSidebar: (state, action) => {
            return {
                ...state,
                sidebarStyle: action.payload
            }
        },
        sidebarMenuClicked: (state, action) => {
            return {
                ...state,
                pageTitle: action.payload
            }
        },
        userUpdatedInfo: (state, action) => {
            return {
                ...state,
                user: action.payload
            }
        },

    },
})

export const { setToggleSidebar, setToggleMobileSidebar, sidebarMenuClicked, userUpdatedInfo } = userSlice.actions;

export default userSlice.reducer;