import React, { useState, useEffect, useRef} from "react";
import {useSelector } from "react-redux"
import EnquiryForm from "./EnquiryForm"
import {  
  Button,  
  Alert,  
} from "antd";
import { setEnquiryHeight, setEnquiryClose } from '../../store/TableStyleData'
import { useDispatch } from "react-redux"

export const Enquiry = ({showAlert}) => {  
  const [open, setOpen] = useState(false);
  const [alertClose, setAlertClose] = useState(false)
  let loggedInUser = useSelector(state => state.userSlice.user)
  // console.log("loggedinuser",loggedInUser)

  const handleOk = () => {
    setOpen(true);
    };

  const handleCancel = (value) => {
    setOpen(value);
    };

    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
  });
  const dispatch = useDispatch();
  const bannerHeightRef = useRef(0);

  
  // useEffect to handle window resize
  useEffect(() => {
      const handleResize = () => {
          // Update window size
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight
          });
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  });

  // useEffect to update fullelementRef
  useEffect(() => {
      dispatch(setEnquiryHeight(bannerHeightRef.current));
      dispatch(setEnquiryClose(alertClose))
  }, [alertClose,bannerHeightRef, dispatch]);

  const EnquiryFormModal = () => {
    if (open) {      
        return (
            <EnquiryForm show={open} setShow={setOpen} open={open} handleOk={handleOk} handleCancel={handleCancel}
                onCancel={() => setOpen(false)}                
            />
        )
    }
    else {
        return (<></>)
    }
  }
  
  const TextComponent = () => {
    return (
      <div className="link_contactus">                    
            {"You are using a free version. Please reach out to us for demo, support or customizations."}  
            &nbsp;          
        <Button type="link" className="primary" onClick={() => setOpen(true)}>  
        {" "}        
          Contact us{" "}
        </Button>
      </div>
    );
  };  

  return (
    <div className="banner_enquiry" ref={bannerHeightRef}>
      {showAlert && (<Alert
        description={<TextComponent />}
        type="warning"
        banner="true"
        showIcon
        closable
        onClose={()=>{
          console.log("closed")
          setAlertClose(true)
        }}
      />
      )}         
      <EnquiryFormModal />         
    </div>
  );
};
export default Enquiry;
