import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux'
import { Space, Table, Button, Typography } from "antd";
import toastHandler from "../../firebaseService/toastHandler";
import firestore from "../../firebase";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import reports from "../../firebaseService/reportsFunctions";
// import ExportFileButton from "../Common/ExportFileButton";
import ExportFileButton from "../Common/ExportUserSummary"
import moment from "moment";
import { DatePicker, Popconfirm } from "antd";
import _ from 'lodash';
import fetchMondayAccount from '../../utils/commonUtils'
import { collection, query, where, or, and, onSnapshot } from "firebase/firestore";
import { constants } from "../../constants";
import {FaCog} from "react-icons/fa";
import settingFunctions from "../../firebaseService/settingFunctions";
// import "./antd.css";
import BoardInfo from "../BoardInfo";
import { Link } from 'react-router-dom';
import {
  FaUsers
} from "react-icons/fa";
import sentryUtils from "../../utils/sentryUtils";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";
const { Text } = Typography;
const disabledFutureYears = (current) => {
  return current && current > moment().endOf('year');
};
export default function Summary(props) {
  const {callSummary} = props;
  const user = firebase.auth().currentUser;
  let loggedInUser = useSelector(state => state.userSlice.user)
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(moment().format('YYYY'));
  const [sync, setSync] = useState(false);
  const [projectSettings, setProjectSettings] = useState();
  const [usersSettings, setUsersSetting] = useState({});  
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(true);
  const [type, setType] = useState('');  
  const [rowKeys, setRowKeys] = useState([]);
  const [uniqueKeySet, setUniqueKeySet] = useState([]);  
  const [mondayBoardSync,setMondayBoardSync] = useState(false)

  const openSettingForm = (type) => { 
    setOpen(true); 
    getAccountInfo();
    setType('projects')
    setTitle('Project summary settings')  
    
  }

  const handleUserSummary = () => {    
    callSummary("UserSummary");       
  };

  const saveSetting = async (values) => {
    try {

      let db = firebase.firestore();
      let msg = 'Setting saved';
      msg = 'Projects settings updated successfully.'
      
      db.collection("mondayAccounts").where("accountId", "==", localStorage.getItem("accountId")).limit(1).get().then(query => {
        const account = query.docs[0];
        if (account) {
          let tmp = account.data();
          tmp.projectSummarySettings = { workspace: values.workspace, board: values.project }
          // console.log(values.project.key)
          let data = {boardId : values.project.key}
          // console.log('data board',data)
          settingFunctions.updateProjectSetting(data)
          setProjectSettings(tmp.projectSummarySettings)
          account.ref.update(tmp);
          toastHandler.success(msg);
        } else {
          toastHandler.info('Account not available.')
        }
        setOpen(false)
      }).catch((error) => { 
        toastHandler.error(error);
        sentryUtils.captureFirestoreException('saveSetting', error, 'Summary');
      });

    } catch (error) {
      toastHandler.error('Error while updating settings.')
    }
  }


  const handleCancel = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setMondayBoardSync(true);
    getAccountInfo();
  }
  var projectsList = [];
  // const [projectsList, setprojectsList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [userProjects, setUserProjects] = useState([]);

  const db = firebase.firestore();

  const getAccountInfo = async () => {
    let result = await fetchMondayAccount()
    let projectInfo = _.get(result, 'projectSummarySettings') || null;
    if (projectInfo) {
      setProjectSettings(projectInfo);
    } else if(mondayBoardSync) {
      toastHandler.info('Please update project setting from "Setting" icon.')
    }
    setMondayBoardSync(false);
  }

  useEffect(() => {
    getAccountInfo();
    setYear(moment().format('YYYY'));
  }, [mondayBoardSync])

  useEffect(() => {
    const summaryRef = db.collection("projectsSummary");
    console.log("account",localStorage.getItem("accountId"))
    let summaryDocSnapshotListner = summaryRef
      .where("accountId", "==", localStorage.getItem("accountId"))
      .where("deleted", "==", false)
      .where("year", "==", year)
      .onSnapshot((docs) => {
        if (docs.size >= 1) {
          let summary = [];
          docs.forEach((doc) => {
            summary = doc.data();
          });
          let summaryData = summary["summaryArray"];
          if (summaryData.length > 0) {
            let data = formatSummaryData(summaryData);
            setDataSource(data);
          } else {
            setDataSource([]);
            toastHandler.info(`Records not available for the year ${year}`);
          }
        } else {
          toastHandler.info(
            `Data is not created for the year  ${year} .Please click on sync button.`
          );
          setDataSource([]);
        }
        setLoading(false);
      });
    return () => {
      summaryDocSnapshotListner();
      console.log("summary component unmounted");
    };
  }, [year, userProjects]);

  useEffect(() => {

    try {
      const teamsRef = collection(firestore, "teams");
      let q = query(
        teamsRef,
        and(
          where("accountId", "==", localStorage.getItem("accountId")),
          where("isDeleted", "==", false),
          or(
            where("projectManager.userId", "==", user.uid),
            where("teamCreator.userId", "==", user.uid),
            where("lead.userId", "==", user.uid),
            where("teamMemberIds", "array-contains", user.uid)
          )
        )
      );
      if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
        q = query(
            teamsRef,
            and(
                where("accountId", "==", localStorage.getItem("accountId")),
                where("isDeleted", "==", false),
            )
        );
      }

      const teamsSnapshot = onSnapshot(
        q,
        (querySnapshot) => {
          let projects = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            let projectInfo = _.get(data, 'project.key') || {};
            projects.push(projectInfo);
          });
          setUserProjects(projects)
        },
        (error) => {
          console.log("inside teamsSnapshot", error.message);
          toastHandler.error("Error while fetching team list");
          sentryUtils.captureFirestoreException('Teams', error, 'Summary');
      }
      );
      return () => {
        teamsSnapshot();
      };
    } catch (error) {
      console.log(error);
      sentryUtils.captureGenericException('Teams', error, 'Summary');
    }
  }, [user]);

  function formattedData(month, element) {
    let data = element.filter((e) => {
      return e.logMonth === month;
    })
    data.forEach(item => {
      item.billableTotalHrs = item.billableTotalHrs === "00:00" ? "__:__" : item.billableTotalHrs;
      item.nonBillableTotalHrs = item.nonBillableTotalHrs === "00:00" ? "__:__" : item.nonBillableTotalHrs;
  });
    return data;
  }
  // function formattedUserSumArray(month, element) {
  //   let data = element.filter((e) => {
  //     return e.logMonth === month;
  //   })
  //   data.forEach(item => {
  //     item.billableTotalHrs = item.billableTotalHrs === "00:00" ? "__:__" : item.billableTotalHrs;
  //     item.nonBillableTotalHrs = item.nonBillableTotalHrs === "00:00" ? "__:__" : item.nonBillableTotalHrs;
  // });
  //   return data;
  // }
  const formatSummaryData = (summaryData) => {
    let data = [];
    summaryData.forEach((element, index) => {
      if (userProjects.length && userProjects.indexOf(element.projectKey) != -1) {
        data.push({
          key: parseInt(element.projectKey),
          projectKey: element.projectKey,
          projectName: element.projectName,
          total: {
            totalBillable: element.totalBillable==="00:00" ? "__:__":element.totalBillable,
            totalNonBillable: element.totalNonBillable==="00:00" ? "__:__":element.totalNonBillable,
          },
          
          jan: formattedData("01", element.sumArray),
          feb: formattedData("02", element.sumArray),
          mar: formattedData("03", element.sumArray),
          apr: formattedData("04", element.sumArray),
          may: formattedData("05", element.sumArray),
          jun: formattedData("06", element.sumArray),
          jul: formattedData("07", element.sumArray),
          aug: formattedData("08", element.sumArray),
          sept: formattedData("09", element.sumArray),
          oct: formattedData("10", element.sumArray),
          nov: formattedData("11", element.sumArray),
          dec: formattedData("12", element.sumArray),
          usersArray: element.usersArray,
        });
      }
    });
    return data;
  };
  const syncMondayboard = () => {
    setLoading(true);
    reports.updateMondaySummaryBoard(year).then((result) => {
      setLoading(false);
    });
  };
  const syncSummary = () => {
    setLoading(true);
    reports.updateSummaryDoc(year).then((result) => {
      setLoading(false);
      setSync(true);
    });
  };

  const HrsComponent = (props) => {
    let hrs = props.hrs?props.hrs:"__:__"
    let billable = props.billable;
    let fontColor = props.fontColor; 

    const textStyle = {
      color: fontColor // Apply custom font color
    };
    return (
      <>
        <Text type={billable ? "success" : "danger"} style={textStyle}>{hrs}</Text>
        {/* <span className="hrs">{" Hrs"}</span> */}        
      </>
    );
  };

  for (let i = 0; i < dataSource.length; i++) {
    projectsList.push(dataSource[i]?.projectName);
  }

  projectsList = projectsList
    .filter(function (v, i, self) {
      return i == self.indexOf(v);
    })
    .map(function (value) {
      return { text: value, value: value };
    });
  const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    
  const generateMonthColumn = (month) => {
    return {
      title: month,
      dataIndex: month.toLowerCase(),
      key: month.toLowerCase(),
      width: 80,
      children: [
        {
          title: "Billable",
          dataIndex: `${month.toLowerCase()}-billable`,
          align: "center",
          width: 40,
          key: `${month.toLowerCase()}-billable`,
          render: (_, record) => (
            <HrsComponent hrs={record[month.toLowerCase()][0]["billableTotalHrs"]} billable={true}  />
          ),
        },
        {
          title: "NonBillable",
          dataIndex: `${month.toLowerCase()}-non-billable`,
          align: "center",
          width: 40,
          key: `${month.toLowerCase()}-non-billable`,
          render: (_, record) => (
            <HrsComponent hrs={record[month.toLowerCase()][0]["nonBillableTotalHrs"]} />
          ),
        },
      ],
    };
  };
    
  const columns = [
    {
      title: "Project",
      key: "project",
      dataIndex: "project",
      width: 100,
      fixed: "left",
      filters: projectsList,
      onFilter: (value, record) => record.projectName.indexOf(value) > -1,
      sorter: (a, b) => a?.projectName?.localeCompare(b?.projectName),
      render: (_, record) => record.projectName,
    },
    {
      title: "Total",
      colSpan: 2,
      width: 80,
      children: [
        {
          title: "Billable",
          dataIndex: "total",
          align: "center",
          width: 40,
          key: "total",
          //   fixed: "right",
          render: (_, record) => {
            return {
              props: {
                style: { background: "rgb(209 255 209)"},
              },
              children: (
                <HrsComponent
                  hrs={record.total.totalBillable}
                  billable={true}
                  fontColor="black"
                />
              ),
            };
          },
        },
        {
          title: "NonBillable",
          dataIndex: "total",
          align: "center",
          width: 40,
          key: "total",
          //   fixed: "right",
          render: (_, record) => {
            return {
              props: {
                style: { background: "#fbdcdc" },
              },
              children: (
                <HrsComponent
                  hrs={record.total.totalNonBillable}
                  billable={false}
                  fontColor="black"
                />
              ),
            };
          },
        },
      ],
    },
    //Month
    ...months.map(month => generateMonthColumn(month)),
  ];
  
  const keyOrder = ['projectName','userName', 'totalBillable', 'totalNonBillable'];  //'userKey',
  let monthKeyArray = [];
  useEffect(() => {
    //set export data
    let data = [];
    let formatedData = [];
    const monthName = {
      jan: '01',
      feb: '02',
      mar: '03',
      apr: '04',
      may: '05',
      jun: '06',
      jul: '07',
      aug: '08',
      sept: '09',
      oct: '10',
      nov: '11',
      dec: '12'
  };
    // console.log("dataSource",dataSource)
    dataSource.forEach((element, index) => {
      const childRows = _.get(element, 'usersArray') || [];
      formatedData.push([{ ...element, userName: 'All User Summary' }])
      formatedData.push(formatUserData(childRows, element));
    });
    // console.log("formattedData",formatedData)
    
    formatedData.forEach((element, index) => {
      let transformedData = {};
      element.forEach((item, index) => {
        let jsonKeys = {};
        for (const monthKey in item) {
          if (item.hasOwnProperty(monthKey)) {
            const monthData = item[monthKey];
            if (typeof (monthData) === 'object') {
              // console.log("monthkey",monthData)
              if (monthKey == 'total') {
                jsonKeys['totalBillable'] = monthData.totalBillable;
                jsonKeys['totalNonBillable'] = monthData.totalNonBillable;
              } else {
                if (monthKey != 'usersArray') {
                    let monthKeyVal = ""
                    let monthKeyNo =""
                    let billableHrs = ""
                    let nonBillableHrs = ""
                    if (Object.values(monthName).includes(monthKey))
                    {
                      //key is jan..dec and val is 01..12
                      monthKeyVal = Object.keys(monthName).find(key => monthName[key] === monthKey); // is used for jan..dec
                      monthKeyNo = monthKey 
                      billableHrs = monthData.billableTotalHrs
                      nonBillableHrs= monthData.nonBillableTotalHrs                                                         // for 01 -12
                      // console.log("monthval",monthKeyVal)
                    }
                    else
                    {
                      monthKeyVal=monthKey      //jan--dec
                      monthKeyNo = monthName[monthKey] //01--12
                      // console.log("monthKey",monthKeyVal + monthKeyNo)
                      billableHrs = monthData[0]["billableTotalHrs"]
                      // console.log("billable",billableHrs)
                      nonBillableHrs = monthData[0]["nonBillableTotalHrs"]
                    }
                    // console.log("monthkey",monthKey + " " + monthData)

                    const billableKey = `${monthKeyNo}-${monthKeyVal}_billable`;
                    const nonBillableKey = `${monthKeyNo}-${monthKeyVal}_nonbillable`;
                    monthKeyArray.push(billableKey);
                    monthKeyArray.push(nonBillableKey);
                    jsonKeys[billableKey] = billableHrs //monthData.billableTotalHrs;
                    jsonKeys[nonBillableKey] = nonBillableHrs //monthData.nonBillableTotalHrs;
                  // }
                  // else
                  // {
                  //   //code for user summarry
                  //   console.log("month not include",monthKey)
                  //   const monthValue = monthName[monthKey]
                  //   const billableKey = `${monthKey}-${monthValue}_billable`;
                  //   const nonBillableKey = `${monthKey}-${monthValue}_nonbillable`;
                  //   monthKeyArray.push(billableKey);
                  //   monthKeyArray.push(nonBillableKey);
                  //   jsonKeys[billableKey] = monthData.billableTotalHrs;
                  //   jsonKeys[nonBillableKey] = monthData.nonBillableTotalHrs;
                  // }
                }
              }
            } else {
              jsonKeys[monthKey] = monthData;
            }
          }
          transformedData = { ...transformedData, ...jsonKeys }
        }
        data.push(transformedData);
      });
    });

    let uniqueSet = new Set(monthKeyArray);
    let newArray = Array.from(uniqueSet);
    newArray = _.sortBy(newArray)
    let finalKeyArray = keyOrder.concat(newArray)
    // console.log(finalKeyArray)
    setUniqueKeySet(finalKeyArray);
    const rearrangedData = data.map((obj) => {
      const newObj = {};
      finalKeyArray.forEach((key) => {
        newObj[key] = obj[key];
      });
      delete newObj['Aug-usersArray_billable']
      delete newObj['Aug-usersArray_nonbillable']

      return newObj;
    });
    setExportData(rearrangedData);
  }, [dataSource]);

  const onYearChange = (date, yearString) => {
    setYear(yearString);
  };

  useEffect(() =>{
    logEvent(analytics,"project_summary" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  const expandedRowRender = (record) => {
    const childColumn = [
      {
        title: "User Name",
        key: "userName",
        dataIndex: "userName",
        width: 120,
        fixed: "left",
        onFilter: (value, record) => record.userName.indexOf(value) > -1,
        sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
        render: (_, record) => record.userName,
      },
      {
        title: "Total",
        colSpan: 2,
        width: 80,
        children: [
          {
            title: "billable",
            dataIndex: "total",
            key: "total",
            //   fixed: "right",
            align: "center",
            width: 40,
            render: (_, record) => {
              return {
                props: {
                  style: { background: "rgb(209 255 209)" },
                },
                children: (
                  <HrsComponent
                    hrs={record.total.totalBillable} 
                    billable={true}
                    fontColor="black"
                  />
                ),
              };
            },
          },
          {
            title: "non billable",
            dataIndex: "total",
            align: "center",
            width: 40,
            key: "total",
            //   fixed: "right",
            render: (_, record) => {
              return {
                props: {
                  style: { background: "#fbdcdc" },
                },
                children: (
                  <HrsComponent
                    hrs={record.total.totalNonBillable}
                    billable={false}
                    fontColor="black"
                  />
                ),
              };
            },
          },
      ],
    },
      //Month
      ...months.map(month => generateMonthColumn(month)),
    ];
    //child
  const childRows = _.get(record, 'usersArray') || [];
  let userData = formatUserData(childRows, record);
  userData = _.sortBy(userData, 'userName')
    return (
      
      <Table
        className="summary"
        loading={loading}
        columns={childColumn}
        dataSource={userData}
        rowKey={"userKey"}
        scroll={{ x: 2800 }}
        size={'small'}
        bordered
        pagination={false}
      />
    );
  
  };
  const expandedRow = {
    expandedRowRender,
    expandRowByClick: true, // Allow expanding rows by clicking anywhere on the row
    rowKeys,
  };

  useEffect(() => {
    const expandedRowKeys = dataSource.map((record) => record.key);
    setRowKeys(expandedRowKeys);
    // console.log("expanded row key projedct ",expandedRowKeys)

    const projectNames = dataSource.map(record => ({ text: record.projectName, value: record.projectName }));
    // for (let i = 0; i < projectNames.length; i++) {
    //   projectsList.push(projectNames);
    // }
    // setprojectsList(projectNames);

  }, [dataSource]);

  const formatUserData = (summaryData, record) => {
    let data = [];
    // console.log("summaryData",summaryData)
    // console.log("record",record)
    summaryData.forEach((element, index) => {
      // console.log("element",element)
      let params = {};
       for (let i = 0; i < element.userSumArrays.length; i++) {
         params = {
           ...params,
          [element.userSumArrays[i].logMonth]: {
            billableTotalHrs: element.userSumArrays[i].billableTotalHrs==="00:00" ? "__:__":element.userSumArrays[i].billableTotalHrs,
            nonBillableTotalHrs: element.userSumArrays[i].nonBillableTotalHrs==="00:00" ? "__:__":element.userSumArrays[i].nonBillableTotalHrs,
          },
        };
      }
      data.push({
        key: parseInt(element.userId),
        userKey: parseInt(element.userId),
        userName: element.userName,
        total: {
          totalBillable: element.totalBillable==="00:00"?"__:__":element.totalBillable,
          totalNonBillable: element.totalNonBillable==="00:00"?"__:__":element.totalNonBillable,
        },
        ...params,
        projectName: record.projectName,
        
        jan: formattedData("01", element.userSumArrays),
        feb: formattedData("02", element.userSumArrays),
        mar: formattedData("03", element.userSumArrays),
        apr: formattedData("04", element.userSumArrays),
        may: formattedData("05", element.userSumArrays),
        jun: formattedData("06", element.userSumArrays),
        jul: formattedData("07", element.userSumArrays),
        aug: formattedData("08", element.userSumArrays),
        sept: formattedData("09", element.userSumArrays),
        oct: formattedData("10", element.userSumArrays),
        nov: formattedData("11", element.userSumArrays),
        dec: formattedData("12", element.userSumArrays),
        // ...params,
        // userName: element.userName,
      });
    });
    // console.log("data",data)
    return data;
  };
  
  return (
    <div class="card mb-4">
      <div class="card-header py-3">
        <div className="float-left">
          <DatePicker
            defaultValue={moment(year)}
            onChange={onYearChange}
            picker="year"
            disabledDate={disabledFutureYears}
          />
        </div>
        <div className="float-right">
          
          <Space direction="horizantal">            
          {/* {(loggedInUser?.userRole === constants.usersRole.ADMIN || loggedInUser?.userRole === constants.usersRole.SUPER_ADMIN) && (
            <Button
                type="text"
                onClick={() => openSettingForm('projects')}
                icon={<FaCog />}  
                className="settings-icon"              
              />
              )}  */}
            <Button style={{ color: '#1B2F78' }} icon={<FaUsers />} onClick={handleUserSummary}>
                &nbsp; User Summary 
            </Button>
            {/* {(loggedInUser?.userRole === constants.usersRole.ADMIN || 
              loggedInUser?.userRole === constants.usersRole.SUPER_ADMIN) && (  
              projectSettings?.board?.label ?(
              <Popconfirm
                title={`Are you confirm to save summary within - ${projectSettings?.board?.label} board?`}
                okText="Yes"
                cancelText="No"
                onConfirm={() => syncMondayboard()}
                disabled={!projectSettings || dataSource.length <= 0}
              >
              <Button type="primary"
                disabled={!projectSettings || dataSource.length <= 0}
                onClick={() => handleClick()}>
                <i class="fas fa-sync"></i>
                &nbsp; Sync Monday Board
              </Button>
              </Popconfirm>
              ):(
              <Button type="primary"
              onClick={() => handleClick()}>
              <i class="fas fa-sync"></i>
              &nbsp; Sync Monday Board
            </Button>)
            )} */}
            <Button type="primary" onClick={() => syncSummary()} /* disabled={dataSource.length <= 0} */>
              <i class="fas fa-sync"></i>
              &nbsp; Sync
            </Button>
            <ExportFileButton
              uniqueKeySet={uniqueKeySet}
              exportInfo={{
                exportData: exportData,
                from: "projectSummary",
              }}
            />
          </Space>
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={"projectKey"}
        scroll={{ x: 2800, y: 600 }}
        expandable={expandedRow}
        bordered
        size={'small'}
        sticky
      />
      {open ? <BoardInfo
        open={open}
        projectSettings={projectSettings}
        title={title}
        type={type}
        handleCancel={handleCancel}
        saveSetting={saveSetting}
        usersSettings={usersSettings}
      /> : null}
      <br />
    </div >
  );
}


