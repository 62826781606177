import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from "react-redux"

export const PageNotFound = () => {
    const user = useSelector(state => state.user)

    return (
        <>
            {/* <!-- Begin Page Content --> */}
            {/* <div class="container-fluid"> */}

                {/* <!-- 404 Error Text --> */}
                <div class="text-center">
                    <div class="error mx-auto" data-text="404">404</div>
                    <p class="lead text-gray-800 mb-5">Page Not Found</p>
                    <p class="text-gray-500 mb-0">It looks like you found a glitch in the matrix...</p>
                    { (user) && <NavLink to="/dashboard">&larr; Back to Dashboard</NavLink>}
                    
                </div>

            {/* </div> */}
            {/* <!-- /.container-fluid -->     */}
        </>
    )
}
