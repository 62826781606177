import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./UserData";
import timesheetSlice from "./FirestoreData";
import tableStyleSlice from "./TableStyleData";


const reducers = combineReducers({
    userSlice,
    timesheetSlice,
    tableStyleSlice
})

export default configureStore({
    reducer: reducers,
});