import React from 'react';
import { useSelector } from 'react-redux'
import { useState } from "react";
import { Tabs } from 'antd';
import CustomReport from '../reports/customReport';
import TimeSheetTableView from '../TimeSheetTableView/TimeSheetTableView';
import Dashboard from '../Charts/Dashboard';
import AllSummaries from './AllSummaries';
import { constants } from '../../constants';
const { TabPane } = Tabs;

const TabComponent = () => {
  let loggedInUser = useSelector(state => state.userSlice.user)
  const [selectedTab, setSelectedTab] = useState(1); 

  const handleTabClick = (tab) => {    
    setSelectedTab(tab);
  };

  return (
    <Tabs defaultActiveKey="1" tabBarStyle={{ marginLeft: '20px', marginBottom: '5px' }} onTabClick={handleTabClick} >
      {/* <TabPane tab="Dashboard" key="1" >
        <TimeSheetTableView />
      </TabPane> */}

      <TabPane tab="Dashboard" key="1" >
        <Dashboard/>
      </TabPane>

      {loggedInUser && loggedInUser.userRole !== constants.usersRole.MEMBER && (<TabPane tab="Summary" key="2">
        <AllSummaries selectedTab={selectedTab} />        
      </TabPane>)}     

      {loggedInUser && loggedInUser.userRole !== constants.usersRole.MEMBER && (<TabPane tab="Reports" key="3">
        <CustomReport />
      </TabPane>)}

    </Tabs>
  );
};

export default TabComponent;
