import { getFunctions, httpsCallable } from "firebase/functions";
import toastHandler from "./toastHandler";
import {functions} from "../firebase";
import envUtils from "../utils/envUtils";
import * as Sentry from "@sentry/react";
import { analytics } from "../firebase";
import { logEvent } from "@firebase/analytics";
import sentryUtils from "../utils/sentryUtils";

const subscriptions = {
  getSubscriptionDetails: async () => {  
    let toastId = 'processingSubscriptionToast';

    let resData = httpsCallable(functions, "api/getAccountSubscriptionInfo");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getAccountSubscriptionInfo");
    }

    try {
      const result = await resData();
      const data = result.data;
      if (!data.error) {
        return data;
      } else {
        const error = data.error;
        toastHandler.update(error, toastId, 'error');
        sentryUtils.captureAPIMessageException('SubscriptionDetails', error, "SubscriptionFunction");
        return null;
      }
    } catch (error) {
      toastHandler.update(error, toastId, 'error');
      sentryUtils.captureAPIException('SubscriptionDetails', error, "SubscriptionFunction");
      return null; 
    }
  },
};

export default subscriptions;