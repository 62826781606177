import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import { Space, Button, Modal, Row, Col } from 'antd';
import toastHandler from '../../firebaseService/toastHandler';
import Selectworkspace from '../Common/FormikComponents/Selectworkspace';
import Selectboard from '../Common/FormikComponents/Selectboard';

export const validateSchema = yup.object().shape({
    workspace: yup.string().required('Please select workspace'),
    project: yup.string().required('Please select project'),
});

const BoardInfo = (props) => {
    const { disabled, projectSettings, title, handleCancel, open, saveSetting, usersSettings, type } = props;
    const [selectedWorkSpace, setSelectedWorkSpace] = useState({});
    const [selectedProject, setSelectedproject] = useState({});
    const onFinish = (values) => {
        let params = {
            workspace: { ...selectedWorkSpace, key: selectedWorkSpace.key + "" },
            project: { ...selectedProject },
        }
        saveSetting(params);
    };

    return (
        <React.Fragment>
            <Formik
                validationSchema={validateSchema}
                onSubmit={onFinish}
                initialValues={{
                    workspace: type === 'projects' ? _.get(projectSettings, 'workspace.value') : _.get(usersSettings, 'workspace.value') || '',
                    project: type === 'projects' ? _.get(projectSettings, 'board.value') : _.get(usersSettings, 'board.value') || '',
                }}
            >
                {(formikBag) => {
                    const { values, setFieldValue, errors } = formikBag;
                    return (
                        <Modal
                            open={open}
                            width={500}
                            transitionName=""
                            maskTransitionName={title}
                            title={title}
                            okText="Create"
                            cancelText="Cancel"
                            onCancel={handleCancel}
                            footer={[
                                <Button key="saveBtn" type="primary" className="button-style" htmlType="submit" form="boardForm" onClick={() => onFinish(formikBag.values)}>
                                    Save
                                </Button>,
                                <Button key="back" style={{ color: '#1B2F78' }}onClick={handleCancel}>
                                    Cancel
                                </Button>,
                            ]}
                        >

                            <Row>
                                <Col span={11}>
                                    <span style={{ fontSize: '14px' }}>Workspace</span>
                                    <span style={{ color: "red" }}>*</span>

                                    <Field
                                        name="workspace"
                                        component={Selectworkspace}
                                        placeholder="Select Workspace"
                                        value={values.workspace}
                                        disabled={disabled}
                                        cb={(newVal) => {
                                            setFieldValue('workspace', newVal[0].value);
                                            setSelectedWorkSpace(newVal[0]);
                                            let initialWorkspaceValue = formikBag.initialValues.workspace;
                                            if (initialWorkspaceValue != newVal[0].value) {
                                                setFieldValue('project', '');
                                            }
                                        }}
                                        error={_.get(errors, 'workspace') || null}
                                    />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={11}>
                                    <span style={{ fontSize: '14px' }}>Project</span>
                                    <span style={{ color: "red" }}>*</span>

                                    <Field
                                        name="project"
                                        component={Selectboard}
                                        placeholder="Select Project"
                                        disabled={disabled}
                                        value={values.project}
                                        selectedWorkSpace={selectedWorkSpace}
                                        cb={(newVal) => {
                                            setSelectedproject(newVal[0])
                                            setFieldValue('project', newVal[0]?.value);
                                        }}
                                        error={_.get(errors, 'project') || null}
                                    />
                                </Col>
                            </Row>

                        </Modal>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};

export default BoardInfo;
