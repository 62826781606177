import React from 'react';
import UserSummary from '../UserSummary/index';
import Summary from '../Summary/Summary';
import { useState, useEffect } from "react";
import "firebase/compat/auth";
import _ from 'lodash';

const AllSummaries = (props) => {  
  
  const {selectedTab} = props;  
  const [defaultSummary, setDefaultSummary] = useState("Summary"); 

  //added for Project & User Summary button click 
  const callSummary = (defaultSummary) => {     
    setDefaultSummary(defaultSummary);    
  }; 

  useEffect(() => {    
    setDefaultSummary("Summary");    
  }, [selectedTab])
  
  return (
    <>
      {defaultSummary === "Summary" ?
        <Summary callSummary={callSummary}/>:
        <UserSummary callSummary={callSummary}/>}
    </>
  );
};

export default AllSummaries;
