import _ from 'lodash';
import React, { useState, useEffect, Fragment, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Avatar,
  Divider,
  List,
  Skeleton,
  Space,
  Result,
  Tooltip,
} from "antd";
import "firebase/compat/auth";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import moment from "moment";
import {
  UpCircleOutlined,
  DownCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import timeEntry from "../../firebaseService/timesheetFunctions";
import teamFunctions from "../../firebaseService/teamFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import Comment from "../Comment/Comment";
import toastHandler from "../../firebaseService/toastHandler";
import {
  collection,
  query,
  where,
  and,
  onSnapshot,
  or,
} from "firebase/firestore";
import { constants } from "../../constants";
import DateRange from "../../common/DateRange";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";
import sentryUtils from "../../utils/sentryUtils";

export default function ApprovalList() {

  let loggedInUser = useSelector(state => state.userSlice.user)
  const [orgDataSource, setOrgDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [outerColumns, setOuterColumns] = useState([]);
  const [expandableObj, setExandableObj] = useState(null);
  const [rowKey, setRowKey] = useState("id");
  const [projectfilteredInfo, setProjectFilteredInfo] = useState("");
  const [groupByKey, setGroupByKey] = useState(null);

  const [lastPage, setLastPage] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let [startIndex,setStartIndex] = useState();
  let [endIndex,setEndIndex] = useState();
  let [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [projectIds, setProjectIds] = useState([]);
  const [projects, setProjects] = useState({});
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [reset, setReset] = useState(false);
  const [commentFieldError, setCommentFieldError] = useState('');
  let navigate = useNavigate();
  if(loggedInUser && !loggedInUser.hasTeam){
    navigate('/master/AllSetting')
  }
  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      console.log('record', record)
      console.log('selected', selected)
      console.log('selected rows', selectedRows)
      if (selected) {
        setSelectedRecords(prev => [...prev, record])
      } else {
        setSelectedRecords(prev =>
          prev.filter(
            obj => obj.id !== record.id)
          )
      }

    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log('changeRows', changeRows)
      console.log('selected', selected)
      console.log('selected rows', selectedRows)
      
      if (selected) {
        setSelectedRecords(prev => [...prev, ...changeRows])
      } else {
        setSelectedRecords(prev =>
          prev.filter(obj => !changeRows.map(row => row.id).includes(obj.id))
        );
      }
    }
  };
  
  const [outerRowSelection, setOuterRowSelection] = useState({
    ...rowSelection,
  });

  
  const [hasMoreData, setHasMoreData] = useState(true);
  const [startDocument, setStartDocument] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [realTimeData, setRealTimeData] = useState([]);
  const [listeners, setListeners] = useState([]);

  let projectsList = [];

  const user = firebase.auth().currentUser;
  const [recordData, setRecordData] = useState({});

  const dateFormat = constants.dateTime.DATE;
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));

  useEffect(() =>{
    logEvent(analytics,"approval_list" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  useEffect(() => {
    const teamsRef = collection(firestore, "teams");
    if (user != null && user != undefined) {
      let q = query(
        teamsRef,
        and(
          where("isDeleted", "==", false),
          where("accountId", "==", localStorage.getItem("accountId")),
          or(
            where("projectManager.userId", "==", user.uid),
            where("teamCreator.userId", "==", user.uid),
            where("lead.userId", "==", user.uid)
          )
        )
      );
      if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
        q = query(
            teamsRef,
            and(
                where("accountId", "==", localStorage.getItem("accountId")),
                where("isDeleted", "==", false),
            )
        );
      }

      const adminteamsListSnapshot = onSnapshot(
        q,
        (querySnapshot) => {
          const projectKeys = [];
          const myProjects = {};
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            projectKeys.push(data.project.key);
            myProjects[data.project.key] = data.teamName;
          });
          console.log("inside teamsListSnapshot", myProjects)
          setProjects(myProjects);
          setProjectIds(projectKeys);
        },
        (error) => {
          console.log("inside teamsListSnapshot", error.message);
          toastHandler.error("Error while fetching team list");
          sentryUtils.captureFirestoreException('teamList', error, 'ApprovalList');
        }
      );

      return () => {
        adminteamsListSnapshot(); // This detach "firestore snapshot listener"
        console.log("componentWillUnmount");
      };
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (user && (projectIds.length > 0)) {
      console.log("Inside timeEntry collection")
      var db = firebase.firestore();
      const accountId = localStorage.getItem("accountId");
      const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection

      var listenersArray = listeners;
      var dataArray = [];

      const start = new Date(new Date(startDate).setUTCHours(-5, -30, 0) + 24*60*60*1000);
      const end = new Date(new Date(endDate).setUTCHours(18, 29, 59) + 24*60*60*1000);
      let queryRef = timeEntryRef
        .where("accountId", "==", accountId)
        .where("deleted", "==", false)
        .where("approvalStatus", "in", ["Pending", "Resubmitted"])
        .where("logDate", ">=", start)
        .where("logDate", "<=", end)
        .orderBy("logDate","desc")

      if(startDocument!==0){
          queryRef = queryRef.startAfter(startDocument)
      }
      if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
        queryRef = queryRef
          .limit(pageSize)
          .get()
      } else {
        queryRef = queryRef
          .where("approvers", "array-contains", user.uid)
          .limit(pageSize)
          .get()
      }
      
      queryRef.then((snapshots) => {
          if (snapshots.docs.length) {
            snapshots.docs.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              console.log('timesheet data', data)
              data.logDate = moment(data.logDate.seconds * 1000).format(
                constants.dateTime.DATE
              );
              data.teamName = projects[parseInt(data.project.key)];
              
              dataArray.push(data);
            });
            // previous starting boundary becomes new ending boundary
            let startDoc = snapshots.docs[snapshots.docs.length - 1];
            setStartDocument(startDoc);

            if (dataArray.length < pageSize) {
              setHasMoreData(false);
            }
            // add newly fetched records
            let newDataArray = orgDataSource.concat(dataArray);
            setOrgDataSource(newDataArray);
            console.log('newDataArray', newDataArray)
            //create listener for every document
            let listener = timeEntryRef
              .where("approvers", "array-contains", user.uid)
              .where("approvalStatus", "in", ["Pending", "Resubmitted"])
              .where("accountId", "==", accountId)
              .where("deleted", "==", false)
              .where("logDate", ">=", start)
              .where("logDate", "<=", end)
              .orderBy("logDate")
              .startAt(snapshots.docs[0])
              .endAt(snapshots.docs[snapshots.docs.length - 1])
              .onSnapshot((docs) => {
                let updatedData = [];
                docs.forEach((doc) => {
                  let data = doc.data();
                  data.id = doc.id;
                  data.logDate = moment(data.logDate.seconds * 1000).format(
                    constants.dateTime.DATE
                  );
                  data.teamName = projects[parseInt(data.project.key)];
                  updatedData.push(data);
                });

                setRealTimeData(updatedData);
              });

            listenersArray.push(listener);
            setListeners(listenersArray);

            setLoading(false);

            // dispatch(setUserTimesheetData([newDataArray]));
          } else {
            setHasMoreData(false);
            setLoading(false);

            toastHandler.info("No more records.");
          }
        })
        .catch((error) => {
          sentryUtils.captureFirestoreException('PendingForApproval', error, 'ApprovalList');
        });
    }
    setLoading(false);
  }, [projectIds, startDate, endDate, loadMore, reset]);

  const displayUpdates = () => {
    if (groupByKey != null) {
      changeDataFormat(
        groupBy(orgDataSource, groupByKey),
        groupByKey,
        projectfilteredInfo
      );
    } else {
      setDataSource(orgDataSource);
    }
  };
  useEffect(() => {
    displayUpdates();
  }, [groupByKey, orgDataSource]);

  useEffect(() => {
    return () => {
      detachListeners(); // This detach "firestore snapshot listener"
      console.log("componentWillUnmount");
    };
  }, []);

  function detachListeners() {
    listeners.forEach((listener) => listener());
  }

  function resetPage() {
    console.log('resetPage Called')
    detachListeners()
    setStartDocument(0);
    setOrgDataSource([]);
    setDataSource([]);
    setLastPage(0);
    setCurrentPage(1);
    setHasMoreData(true);
    setReset(!reset)
    setSelectedRecords([]);
  }

  useEffect(() => {
    if (realTimeData.length && orgDataSource.length) {
      const arrCopy = [...orgDataSource];
      for (let i = 0; i < orgDataSource.length; i++) {
        for (let j = 0; j < realTimeData.length; j++) {
          // To overwrite existing records with real time changes
          if (orgDataSource[i]["id"] === realTimeData[j]["id"]) {
            arrCopy[i] = realTimeData[j];
          }
        }
      }

      let finalDataSource = _.cloneDeep(arrCopy);
      setOrgDataSource(finalDataSource);
    }
  }, [realTimeData]);

  for (let i = 0; i < orgDataSource.length; i++) {
    projectsList.push(orgDataSource[i]?.project.value);
  }

  projectsList = projectsList
    .filter(function (v, i, self) {
      return i == self.indexOf(v);
    })
    .map(function (value) {
      return { text: value, value: value };
    });

  const handleComment = (row) => {
    console.log(row);
    setRecordData(row);
    setOpenComment(true);
  };

  const columns = [
    Table.SELECTION_COLUMN,
    {
      title: "Date",
      key: "logDate",
      dataIndex: "logDate",
      sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
      width: constants.width.dateColumnWidth,
      ellipsis: true,
    },
    {
      title: "User",
      key: "userName",
      dataIndex: "userName",
      width: constants.width.userColumnWidth,
      ellipsis: true,
      sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
    },
    {
      title: "Team",
      key: "teamName",
      dataIndex: "teamName",
      width: constants.width.teamColumnWidth,
      ellipsis: true,
      sorter: (a, b) => a?.teamName?.localeCompare(b?.teamName),
    },
    {
      title: "Project",
      key: "project",
      dataIndex: "project",
      width: constants.width.projectColumnWidth,
      ellipsis: true,
      filters: projectsList,
      onFilter: (value, record) =>
        record.project?.value
          ? record.project?.value === value
          : record.projectString?.indexOf(value) > -1,
      render: (_, record) => <span>{record.project?.value}</span>,
      sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => <span>{record.task?.value}</span>,
    },
    {
      title: "Subtask",
      dataIndex: "subtask",
      key: "subtask",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => <span>{record.subtask?.value}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: constants.width.descriptionClomnWidth,
      ellipsis: {
        showTitle: false,
      },
      render: (e, record) => (
        <Tooltip placement="leftBottom" title={record.description}>
          {_.capitalize(_.trim(record.description)) || '-'}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: 'center',
      width: constants.width.statusColumnWidth,
      render: (_, record) => (
        <span className={record.approvalStatus.toLowerCase()}>
          {record.approvalStatus.charAt(0).toUpperCase() +
            record.approvalStatus.slice(1)}
        </span>
      ),
    },
    {
      title: "Billable",
      key: "billable",
      dataIndex: "billable",
      width: constants.width.smallColumnWidth,
      render: (billable) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            {billable ? (
              <Tooltip placement="right" title={"Biilable"}>
                {" "}
                <span className="billable">
                  {" "}
                  <CheckOutlined />
                </span>{" "}
              </Tooltip>
            ) : (
              <Tooltip placement="right" title={"Non biilable"}>
                {" "}
                <span className="billable"> </span>
                <span className="non-billable">
                  {" "}
                  <CloseOutlined />{" "}
                </span>{" "}
              </Tooltip>
            )}
          </div>
        )
      }

    },
    {
      title: "Hour(s)",
      key: "logHours",
      dataIndex: "logHours",
      width: constants.width.hoursColumnWidth,
      ellipsis: true,
      render: (_, record) => (
        <>
          {" "}
          {record.logHours}{" "}
          <span className="hrs">
            {" "}
            {record.logHours?.split(":")[0] == "00" ? "Mins" : "Hrs"}
          </span>{" "}
        </>
      ),
    },
    {
      title: "Comments",
      key: "comment",
      dataIndex: "comment",
      width: constants.width.commentColumnWidth,
      render: (_, record) => {

        return (
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span>
              <Button
                type="primary"
                size="small"
                title="Comment"
                style={{ backgroundColor:'#2143BC' }}
                onClick={() => handleComment(record)}
              >
                <FontAwesomeIcon icon={faComment} />
              </Button>
            </span>
          </div>
        )
      },
    },
  ];
  useEffect(() => {
    setOuterColumns(columns);
  }, [projectsList.length]);

  useEffect(() => {    
    if (user) {      
      var db = firebase.firestore();
      const accountId = localStorage.getItem("accountId");
      const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection
      const start = new Date(new Date(startDate).setUTCHours(-5, -30, 0) + 24*60*60*1000);
      const end = new Date(new Date(endDate).setUTCHours(18, 29, 59) + 24*60*60*1000);      
      let queryRef = timeEntryRef
        .where("accountId", "==", accountId)
        .where("deleted", "==", false)
        .where("approvalStatus", "in", ["Pending", "Resubmitted"])
        .where("logDate", ">=", start)
        .where("logDate", "<=", end)
      if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
        
      } else {
        queryRef = queryRef
          .where("approvers", "array-contains", user.uid)          
      }
      const snapshot = onSnapshot(
        queryRef,
        (querySnapshot) => {          
          if (querySnapshot.size === 0) {
            console.log("querySnapshot size 0");
          }
          else {    
            total = querySnapshot.size;
            startIndex = (currentPage - 1) * pageSize + 1;            
            endIndex = Math.min((startIndex + pageSize - 1), total);         
            setTotal(querySnapshot.size); 
            setStartIndex(startIndex);
            setEndIndex(endIndex);           
          }
        })
    }
    
  }, [startDate, endDate]);

  // Approve Model code start
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setCommentFieldError('');
    setOpen(false);
  };

  const updatedApprovalStatus = (values, approvalStatus) => {
    // console.log("Received values of form: ", values);
    // console.log(selectedRecords)

    setLoading(true);
    const approvalsToUpdate = selectedRecords.map(function (value) {
      return {
        projectBoardId: value.project.key,
        timeEntryId: value.id,
        user_id: value.userId,
        task_id: value.task.key,
        subtask_id : value.subtask?.key,
        log_date: value.logDate,
        log_hours: value.logHours,
      };
    });
    let updatedData = {
      approvalStatus: approvalStatus,
      reviewerComment: values.comment,
      approvalsToUpdate: approvalsToUpdate,
    };
    console.log(updatedData);

    timeEntry.updateApproval(updatedData);

    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 500);
    setTimeout(() => {
      resetPage()
    }, 3000);
  };
  const ApprovalForm = ({ open, onCreate, onCancel }) => {
    const [form] = Form.useForm();

    const { TextArea } = Input;

    const columns2 = [
      {
        title: "Task Name",
        dataIndex: "taskName",
        width: constants.width.taskColumnWidth,
        ellipsis: true,
        render: (_, record) => <span>{record.task.value}</span>,
      },
      {
        title: "User",
        dataIndex: "userName",
        width: constants.width.userColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
        render: (_, record) => <span>{record.userName}</span>,
      },
      {
        title: "Team",
        dataIndex: "teamName",
        width: constants.width.teamColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.teamName?.localeCompare(b?.teamName),
        render: (_, record) => <span>{record.teamName}</span>,
      },
      {
        title: "Date",
        dataIndex: "logDate",
        width: constants.width.dateColumnWidth,
        ellipsis: true,
        sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: constants.width.descriptionClomnWidth,
        ellipsis: {
          showTitle: false,
        },
        render: (e, record) => (
          <Tooltip placement="leftBottom" title={record.description}>
            {_.capitalize(_.trim(record.description)) || '-'}
          </Tooltip>
        ),
      },
      {
        title: "Status",
        dataIndex: "approvalStatus",
        align: 'center',
        width: constants.width.statusColumnWidth,
        ellipsis: true,
        render: (_, record) => (
          <span className={record.approvalStatus.toLowerCase()}>
            {record.approvalStatus}
          </span>
        ),
      },
    ];

    return (
      <Modal
        open={open}
        // width={200}
        transitionName=""
        maskTransitionName=""
        title="Confirmation"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        footer={[
          <Button
            key="approve"
            type="primary"
            disabled={!selectedRecords.length}
            className="button-style"
            onClick={() => {
              setCommentFieldError(''); // Reset the error state
              updatedApprovalStatus(form.getFieldsValue(), "Approved");
              form.resetFields();
            }}
          >
            Approve All
          </Button>,
          <Button
            key="reject"
            type="primary"
            disabled={!selectedRecords.length}
            className="button-style"
            // loading={loading}
            onClick={() => {
              setCommentFieldError(''); // Reset the error state
              if(form.getFieldValue('comment') === undefined || form.getFieldValue('comment') === ''){
                setCommentFieldError('Please add comment'); // Reset the error state
              } else {
                updatedApprovalStatus(form.getFieldsValue(), "Rejected");
                form.resetFields();
              }
              
            }}
          >
            Reject All
          </Button>,
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        {!selectedRecords.length ? (
          <Result status="warning" title="Records not selected." />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: "public" }}
          >
            {/* <Table
              columns={columns2}
              rowKey={"id"}
              dataSource={selectedRecords}
              pagination={{ pageSize: 50 }}
              scroll={{ x: 1000, y: 240 }}
              size="small"
            /> */}

            <Form.Item
              name="comment"
              label="Comment"
              // rules={[{ required: true, message: "Please add comment!" }]}
              validateStatus={commentFieldError ? 'error' : ''}
              help={commentFieldError}
            >
              <TextArea placeholder="Add comment here..." />
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  };
  // Approve Model Code End

  const expandedRowRender = (record) => {
    const innerColumns = [
      Table.SELECTION_COLUMN,
      {
        title: "Date",
        key: "logDate",
        dataIndex: "logDate",
        width: constants.width.dateColumnWidth,
        ellipsis: true,
        sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
        render: (_, record) => record.logDate || "-",
      },
      {
        title: "User",
        key: "userName",
        dataIndex: "userName",
        width: constants.width.userColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
        render: (_, record) => record.user || "-",
      },
      {
        title: "Team",
        key: "teamName",
        dataIndex: "teamName",
        width: constants.width.teamColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.teamName?.localeCompare(b?.teamName),
        render: (_, record) => record.teamName || "-",
      },
      {
        title: "Project",
        key: "project",
        dataIndex: "project",
        width: constants.width.projectColumnWidth,
        ellipsis: true,
        sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
        render: (_, record) => record.project.value || "-",
      },
      {
        title: "Task",
        dataIndex: "task",
        key: "task",
        width: constants.width.taskColumnWidth,
        ellipsis: true,
        render: (_, record) => record.task.value || "-",
      },
      {
        title: "Subtask",
        dataIndex: "subtask",
        key: "subtask",
        width: constants.width.taskColumnWidth,
        ellipsis: true,
        render: (_, record) => record.subtask?.value || "-",
      },
      {
        title: "Hours",
        key: "hours",
        dataIndex: "hours",
        width: constants.width.hoursColumnWidth,
        ellipsis: true,
        filteredValue: projectfilteredInfo || null,
        onFilter: (value, record) => record.projectName.indexOf(value) > -1,
        render: (_, record) => (
          <>
            {" "}
            {record.hours}{" "}
            <span className="hrs">
              {" "}
              {record.hours.split(":")[0] == "00" ? "Mins" : "Hrs"}
            </span>{" "}
          </>
        ),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: constants.width.descriptionClomnWidth,
        ellipsis: {
          showTitle: false,
        },
        render: (e, record) => (
          <Tooltip placement="leftBottom" title={record.description}>
            {_.capitalize(_.trim(record.description)) || '-'}
          </Tooltip>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        align: 'center',
        key: "status",
        width: constants.width.statusColumnWidth,
        ellipsis: true,
        render: (_, record) => (
          <span className={record.approvalStatus.toLowerCase()}>
            {record.approvalStatus}
          </span>
        ),
      },
      {
        title: "Billable",
        key: "billable",
        dataIndex: "billable",
        width: constants.width.billableColumnWidth,
        ellipsis: true,
        render: (billable) =>
          billable ? (
            <Tooltip placement="right" title={"Biilable"}>
              {" "}
              <span className="billable">
                {" "}
                <CheckOutlined />
              </span>{" "}
            </Tooltip>
          ) : (
            <Tooltip placement="right" title={"Non biilable"}>
              {" "}
              <span className="billable"> </span>
              <span className="non-billable">
                {" "}
                <CloseOutlined />{" "}
              </span>{" "}
            </Tooltip>
          ),
      },

      // {
      //   title: "APPROVAL",
      //   dataIndex: "approvalStatus",
      //   key: "approvalStatus",
      //   width: 170,
      //   render: () => (
      //     <Button
      //       onClick={showModal}
      //       style={{ background: "#51AB0B", color: "#FFFFFF" }}
      //       shape="round"
      //     >
      //       Approve / Reject
      //     </Button>
      //   ),
      // },
      {
        title: "Comments",
        key: "comment",
        dataIndex: "comment",
        width: constants.width.commentColumnWidth,
        render: (_, record) => (
          <span>
            <Button
              type="primary"
              size="small"
              title="Comment"
              style={{ backgroundColor:'#2143BC' }}
              onClick={() => handleComment(record)}
            >
              <FontAwesomeIcon icon={faComment} />
            </Button>
          </span>
        ),
      },
    ];

    const dataRows = record.data.map((item, index) => ({
      ...item,
      id: item.id,
      date: item.logDate,
      hours: item.logHours,
      user: item.userName,
      billable: item.billable,
      approvalStatus: item.approvalStatus,
      status: item.approvalStatus,
      logCost: "-",
      invoiceStatus: item.invoiceStatus,
      userName: item.userName,
      projectName: item.project?.value,
      task: item.task,
      subtask : item.subtask,
      teamName: item.teamName,
      workSpace: "-",
    }));

    return (
      <div className="inner-table">
        <Table
          rowKey={"id"}
          columns={innerColumns}
          showHeader={true}
          rowSelection={{ ...rowSelection }}
          dataSource={dataRows}
          pagination={{ pageSize: 5 }}
          size="small"
        />
      </div>
    );
  };

  function groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val =
        typeof collection[i][property] == "object"
          ? collection[i][property].value
          : collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  function changeDataFormat(groupedClientData, groupByCol) {
    setOuterRowSelection(null);
    let taskListNew = [];
    for (let i = 0; i < groupedClientData.length; ++i) {
      let projectNameString = "";
      projectNameString += groupedClientData[i].map(function (element) {
        return element.project?.value;
      });

      taskListNew.push({
        key: i.toString(),
        logDate: moment(new Date(groupedClientData[i][0]?.logDate)).format(
          constants.dateTime.DATE
        ), //logDate
        projectString: projectNameString,
        hours: "", 
        approvalStatus: groupedClientData[i][0]?.approvalStatus, 
        userName: groupedClientData[i][0]?.userName, 
        userId: groupedClientData[0]?.userId,
        data: groupedClientData[i],
      });
    }

    setDataSource(taskListNew);
    const outerColumnsNew = [
      {
        title: `Grouped by ${groupByCol
          .replace("approvalStatus", "status")
          .replace("logDate", "date")
          .replace("userId", "user")}`,
        dataIndex: groupByCol,
        key: groupByCol,
        render: (_, record) =>
          typeof record["data"][0][groupByCol] == "object"
            ? record["data"][0][groupByCol]["value"]
            : record[groupByCol === "userId" ? "userName" : groupByCol],
      },
      { title: "", dataIndex: "project" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
    ];
    setOuterColumns(outerColumnsNew);
    setExandableObj({
      expandedRowRender,
      expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
          <UpCircleOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
        ),
    });
    setRowKey("key");
  }

  const setInitData = () => {
    setDataSource(orgDataSource);
    setOuterColumns(columns);
    setExandableObj(null);
    setRowKey("id");
    setGroupByKey(null);
    setOuterRowSelection({ ...rowSelection });
  };
  const groupTableRows = (selectedOption) => {
    let opt = selectedOption;
    let groupByKeyArr = [];
    groupByKeyArr["logDate"] = "groupByDate";
    groupByKeyArr["approvalStatus"] = "groupByStatus";
    groupByKeyArr["project"] = "groupByProject";
    groupByKeyArr["userId"] = "groupByUser";

    if (groupByKeyArr[selectedOption]) {
      opt =
        typeof selectedOption == "object"
          ? selectedOption.value
          : groupByKeyArr[selectedOption];
    } else {
      opt = selectedOption.value;
    }
    switch (opt) {
      case "clear":
        setInitData();
        break;
      case "groupByDate":
        setGroupByKey("logDate");
        changeDataFormat(groupBy(orgDataSource, "logDate"), "date");
        break;
      case "groupByStatus":
        setGroupByKey("approvalStatus");
        changeDataFormat(
          groupBy(orgDataSource, "approvalStatus"),
          "approvalStatus"
        );
        break;
      case "groupByProject":
        setGroupByKey("project");
        changeDataFormat(groupBy(orgDataSource, "project"), "project");
        break;
      case "groupByUser":
        setGroupByKey("userId");
        changeDataFormat(groupBy(orgDataSource, "userId"), "userId");
        break;
      default:
    }
  };
  const onClose = () => {
    setOpenComment(false);
  };

  const getNextPageRange = (currentPage,lastPage) =>{    
    if(currentPage === lastPage){
      setLoadMore(!loadMore);
      setCurrentPage(currentPage + 1);
    }        
    currentPage = currentPage + 1;    
    startIndex = (currentPage - 1) * pageSize + 1;       
    endIndex = Math.min((startIndex + pageSize - 1), total);     
    setStartIndex(startIndex);
    setEndIndex(endIndex);    
  };

  const getPrevPageRange = () =>{    
    currentPage = currentPage - 1;    
    startIndex = (currentPage - 1) * pageSize + 1;       
    endIndex = Math.min((startIndex + pageSize - 1), total);         
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination.current);
    setProjectFilteredInfo(filters.project);
    currentPage = pagination.current;    
    startIndex = (currentPage - 1) * pageSize + 1;    
    endIndex = Math.min((startIndex + pageSize - 1), total);         
    setStartIndex(startIndex);
    setEndIndex(endIndex);  
  };

  const updateDateRange = (range = []) => {
    if (range !== null) {
      const dateRange = range.map((date) => moment(date).format(dateFormat));
      const [start, end] = dateRange;

      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(moment().startOf("month").format(dateFormat));
      setEndDate(moment().format(dateFormat));
    }
    setStartDocument(0);
    setOrgDataSource([]);
    setDataSource([]);
    setLastPage(0);
    setCurrentPage(1);
    setHasMoreData(true);
  };

  const itemRender = (_, type, originalElement) => { 
    setLastPage(_);   
    if (type === "prev") {      
      return (
      <>
        <span style= {{marginRight:"10px"}}>{startIndex} - {endIndex} of {total}</span>      
        <Button type="text" 
                disabled={ currentPage === 1} 
                onClick={getPrevPageRange}> Prev </Button>
      </>
      );
    }
    if (type === "next") {
      return (
        <>
          <Button
            type="text"
            disabled={!hasMoreData && currentPage === lastPage}            
            onClick={() =>{                             
              getNextPageRange(currentPage,lastPage)}            
          }
          >
            Next
          </Button>
          
        </>
      );
    }
    return originalElement;
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [cardHeight, setCardHeight] = useState(0)
  const [ebannerHeight, setEBannerHeight] = useState(0)
  const [rbannerHeight, setRBannerHeight] = useState(0)

  const cardRef = useRef(null);
  const enquiryHeight = useSelector(state => state.tableStyleSlice.enquiryHeight);
  const resubmissionHeight = useSelector(state => state.tableStyleSlice.resubmissionHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      },);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const [tableLength, setTableLength] = useState(" ")
  useEffect(()=>{
    if (resubmissionHeight) {
      const bannerHeight = resubmissionHeight.offsetHeight;
      setEBannerHeight(bannerHeight)
    }
    if (enquiryHeight) {
      const bannerHeight = enquiryHeight.offsetHeight;
      setRBannerHeight(bannerHeight)
    }
    if (cardRef.current) {
      const height = cardRef.current.offsetHeight;
      setCardHeight(height)
    }
    setTableLength(`calc(100vh - 125px - ${rbannerHeight}px - ${ebannerHeight}px - ${cardHeight}px)`
    )
  },)

  return (
    <div class="card mb-4">
      <div class="card-header py-3" ref={cardRef}>
        <DateRange updateDateRange={updateDateRange} dateFormat={dateFormat} />

        <div className="float-right">
          <Button
            onClick={showModal}
            style={{ marginRight: 10 }}
            shape="default"
            type="primary"
            disabled={selectedRecords.length > 0 ? false : true}
            className={selectedRecords.length > 0 ? "button-style":"button-disabled-style"}
          >
            Approve / Reject
          </Button>

          <Space direction="horizantal">
            <Select
              labelInValue
              defaultValue={"Group by"}
              style={{ width: 160 }}
              onChange={groupTableRows}
              disabled={!dataSource.length}
              options={[
                { value: "clear", label: "Clear" },
                { value: "groupByDate", label: "Group By Date" },
                { value: "groupByUser", label: "Group By User" },
                { value: "groupByStatus", label: "Group By Status" },
                { value: "groupByProject", label: "Group By Project" },
              ]}
            />
          </Space>
        </div>
      </div>
      <Table
        loading={loading}
        columns={outerColumns}
        expandable={expandableObj}
        dataSource={dataSource}
        pagination={{
          pageSize: pageSize,
          current: currentPage,
          itemRender: itemRender,
          // position: ["topRight"],
        }}
        onChange={onChange}
        rowKey={rowKey}
        rowSelection={outerRowSelection}
        // scroll={{ x: 'auto' }}
        scroll={{ x: 1300, y:tableLength }}
      />
      <Comment
        open={openComment}
        setOpen={setOpenComment}
        commentData={recordData}
      />

      <ApprovalForm
        open={open}
        onCreate={updatedApprovalStatus}
        onCancel={() => {
          setOpen(false);
          setCommentFieldError('');
        }}
      />
    </div>
  );
}
