import React, { useState } from 'react';
import { Modal, Divider, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import congrats from '../../assets/images/congrats.png'
import create_team from '../../assets/images/create_team.png'
import team_building from '../../assets/images/team_building.png'
import team from '../../constants/team';

function CreateTeamModal(props) {
  console.log('props', props)   
  const { show, setShow, setOpenCreateForm, firstTimeLogin} = props;
  const title_style = {
    color: "#1B2F78",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',
    margin: '20px 10px'
  }

  const subtext_style = {
    color: "#909090",
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
  }

  const next_btn_style = {
    backgroundColor: "#1B2F78",
    color: "white",
    borderRadius: '3.25px',
    boxShadow: '0px 1.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
    fontFamily: 'Poppins',
    fontSize: '15px',
    display: 'flex', 
    justifyContent: 'flex-end' 
  }

  const modalStyle = {
    textAlign: 'center',
    width: '562px', // Adjust width as needed
    // height: '316px',
    margin:'0px',
    overflowY: 'auto', // Enable vertical scrolling if content exceeds height
    overflowX: 'auto',
    border: '1px solid #99A5D3', // Add border style here
  };

  const modalStyle0 = {
    textAlign: 'center',
    width: '562px', // Adjust width as needed
    // height: '290px',
    margin:'0px',
    overflowY: 'auto', // Enable vertical scrolling if content exceeds height
    overflowX: 'auto',
    border: '1px solid #99A5D3', // Add border style here
  };

  return (
    <Modal 
      open={show}
      onCancel={() => { setShow(false) }}
      footer={null}
      centered
      destroyOnClose={true}
      style={firstTimeLogin ? modalStyle : modalStyle0}
    >
      <div >
        <img style= {{width:'80px', height:'80px'}}
              src={firstTimeLogin ? congrats : team_building}
              alt="congrats"
            />
        <p style={title_style}>{firstTimeLogin ? team.TITLE : team.TITLE0 } </p>
        <p style={subtext_style}>{firstTimeLogin ? team.DESCRIPTION : team.DESCRIPTION0}</p>
        <Divider />
        <div 
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button 
          // className='next_btn_style'
          type="primary" 
          style={next_btn_style}
          onClick={() => { 
            setShow(false) 
            setOpenCreateForm(true)
            // setFirstTimeLogin(false)
          }}
          >Next</Button>
        </div>
        
      </div>
     </Modal>
  );
};

export default CreateTeamModal;

