import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { collection, query, where, or, and, onSnapshot } from "firebase/firestore";
import { Space, Table, Popconfirm, Button, Tooltip, Popover, Select } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "firebase/compat/auth";
import firestore from "../../firebase";
import toastHandler from "../../firebaseService/toastHandler";
import { constants } from "../../constants";
import users from "../../firebaseService/userFunctions";
import sentryUtils from "../../utils/sentryUtils";

const UserList = () => {
    let loggedInUser = useSelector(state => state.userSlice.user)
    var usersList = [];
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [showPopconfirm, setShowPopconfirm] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const handleSelect = (record, role) => {
        record.popConfirm = true
        record.newRole = role
        setShowPopconfirm(true);
        setSelectedRecord(record)
    };
    
    const handlePopconfirmConfirm = () => {
        updateUserRole(selectedRecord);
        setShowPopconfirm(false);
    };

    const handlePopconfirmCancel = () => {
        setShowPopconfirm(false);
    };

    useEffect(() => {

        try {
            const userRef = collection(firestore, "users");
            let q = query(
                userRef,
                where("accountId", "==", localStorage.getItem("accountId")),
                where("isAuthorized", "==", true),
            );
            
            const usersSnapshot = onSnapshot(
                q,
                (querySnapshot) => {
                    let users = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        data.popConfirm = false
                        if (loggedInUser.userId === data.userId)
                            data.disabled = true
                        else
                            data.disabled = false
                        users.push(data);
                    });
                    setUserData(users);
                    setLoading(false)
                    console.log("inside usersSnapshot");
                },
                (error) => {
                    console.log("inside teamsSnapshot", error.message);
                    toastHandler.error("Error while fetching team list");
                    sentryUtils.captureFirestoreException('fetchUser', error, 'UsersIndex');
                }
            );
            setLoading(false);
            return () => {
                usersSnapshot();
                console.log("componentWillUnmount");
            };
        } catch (error) {
            setLoading(false);
            console.log(error);
            sentryUtils.captureGenericException('fetchUser', error, 'UsersIndex');
        }
    }, []);

    for (let i = 0; i < userData.length; i++) {
        usersList.push(userData[i]?.userName);
    }

    usersList = usersList.filter(function (v, i, self) { return i == self.indexOf(v); }).map(function (value) { return { text: value, value: value } });
    
    const updateUserRole = async(record) => {
        const updateUserData = {
            userId : record.userId,
            accountId : record.accountId,
            newRole : record.newRole,
        }
        users.updateUserRole(updateUserData);
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
            // width: constants.width.teamNameColumnWidth - 30,
            ellipsis: true,
            fixed: 'left',
            sorter: (a, b) => a?.userName.localeCompare(b?.userName),
            filters: usersList,
            onFilter: (value, record) => ((record.userName.indexOf(value) > -1)),
            filterSearch: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // width: constants.width.smallColumnWidth - 30,
            ellipsis: true,
            fixed: 'left',
            sorter: (a, b) => a?.email.localeCompare(b?.email),
        },
        {
            title: 'User Role',
            key: 'userRole',
            // width: constants.width.teamNameColumnWidth,
            dataIndex: 'userRole',
            render: (_, record) =>(
                <div>
                    <Popconfirm
                            title="Are you sure to update the role?"
                            okText="Yes"
                            cancelText="No"
                            open={record.popConfirm && showPopconfirm}
                            onConfirm={handlePopconfirmConfirm}
                            onCancel={handlePopconfirmCancel}
                    >
                    <Select
                        mode="single"
                        allowClear={false}
                        style={{ width: "150px" }}
                        disabled={record.disabled}
                        placeholder="Please select"
                        optionLabelProp="label"
                        options={[
                            { value: constants.usersRole.ADMIN, label: 'Admin' },
                            { value: 'Member', label: 'Member' },
                            { value: constants.usersRole.SUPER_ADMIN, label: 'Super Admin' }]}
                        value = {record.userRole}
                        onSelect={(newRole) => {
                            if (newRole !== record.userRole) {
                                handleSelect(record, newRole)
                            } else {
                            // Handle the case where the selected value is the same as the current role
                            console.log('User role already the same');
                            }
                        }}
                    />
                    </Popconfirm>
                    
                </div>
            ),
        },
    ];
    
    return (
        <div class="card mb-4">
            
            <Table style={{ marginRight: '10px' }}
                loading={loading}
                columns={columns}
                dataSource={userData}
                size={'small'}
                rowKey={'createdDate'}
            />
            
        </div>
    );
}

export default UserList;