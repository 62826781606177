import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Row } from 'antd';
import init from 'monday-sdk-js/src/client';
import toastHandler from '../../../firebaseService/toastHandler';
import sentryUtils from '../../../utils/sentryUtils';

const Selectboard = (props) => {
    const { placeholder, data, form, field, cb, error, value, disabled, selectedWorkSpace } = props;
    const { name } = field;
    const monday = init();
    const [boardData, setBoardData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [busy, setBusyBoard] = useState(false);
    const fetchBoards = async () => {
        console.log('fetchBoards called')
        if (
            selectedWorkSpace !== null &&
            selectedWorkSpace !== '' &&
            selectedWorkSpace.key !== undefined
        ) {
            setBusyBoard(true);
            let query = `query {
        boards(workspace_ids:[` + selectedWorkSpace.key + `], limit: 500) {
          id
          name
        }
      }`;
            const tokendata = await monday.get('sessionToken');
            const response = await monday.api(query, {
                options: { token: tokendata.data },
            }).catch((err) => {
                setBusyBoard(false);
                console.log('error in fetchBoard--->', err.message);
                toastHandler.error(err.message);
                sentryUtils.captureMondayException('fetchBoards', error, 'selectBoard');
            });

            let boardList = response.data.boards || [];
            boardList = _.orderBy(boardList, ['name'], ['asc']);
            let project = [];
            boardList.forEach((item, index) => {
                //Note: Subitems are considered as board & returns in monday board qurey which is invalid case. So below condition is added. 
                if (item.name.indexOf('Subitems of ') === -1) {
                    project.push({
                        key: item.id,
                        label: item.name,
                        value: item.id,
                    });
                }
            });
            setBoardData(project);
            setBusyBoard(false);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(selectedWorkSpace)) {
            fetchBoards();
        }
    }, [selectedWorkSpace]);

    useEffect(() => {
        if (boardData.length > 0 && value) {
            const data = boardData.filter((item) => item.value === value);
            cb(data);
            setSelectedValue(data[0]);
        } else {
            setSelectedValue(null);
        }
    }, [boardData, value])

    return (
        <div>
            <Row>
                <Select
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    name={name}
                    value={selectedValue}
                    showSearch
                    optionFilterProp='label'
                    onChange={(e) => {
                        const selectedValue = boardData.filter((item) => item.value === e);
                        cb(selectedValue)
                    }}
                    loading={busy}
                >
                    {boardData?.map((item) => (
                        <Select.Option key={item.key} value={item.value} label={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div >
    )
};

export default Selectboard;
