import React, { useEffect, useState }  from 'react'
import { useSelector } from "react-redux"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import firestore from "../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { collection, query, where, and, onSnapshot } from "firebase/firestore";

import toastHandler from "../firebaseService/toastHandler";
import { constants } from '../constants';
import { useDispatch } from "react-redux";
import { sidebarMenuClicked } from '../store/UserData';
import authFunctions from '../firebaseService/authFunctions';
import sentryUtils from '../utils/sentryUtils';
import subscriptionStatus from '../constants/subscription';

const ProtectedRoute = ({ children }) => {

    const user = firebase.auth().currentUser;
    console.log("User: ", user)
    const dispatch = useDispatch();

    let location = useLocation();
    const currentRoute = useLocation().pathname.toLowerCase();
    const navigate= useNavigate();

    const [userRole, setUserRole] = useState([])
    const accountId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId")
    useEffect(() => {

        console.log("In protected route");
        
        try {

            const userRef = collection(firestore, "users");
            const q = query(
                userRef,
                and(
                    where("userId", "==", user.uid),
                    where("accountId", "==", accountId),
                )
            );

            const userSnapshot = onSnapshot(
                q,
                (querySnapshot) => {
                    let userEntries = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.id = doc.id;
                        userEntries.push(data);
                    });
                    
                    if(userEntries.length > 0) {
                        setUserRole(userEntries[0]['userRole']);
                        localStorage.removeItem("authenticated_" + accountId + "_" + userId);
                        localStorage.setItem("authenticated_" + accountId + "_" + userId, userEntries[0]['isAuthorized']);
                        // const authorized = userEntries[0]['isAuthorized'];
                        // setIsAuthorized(authorized);

                        // As role could be changed, it should create firebase token again

                        authFunctions.getFirestoreTokenFromSessionToken().then((result) => {
                            // set state as logged in in local storage, which to be used for protected routes
                            if (result.data === "monday session expired") {
                               console.log("token expired");
                            } else {
                              const userId = localStorage.getItem("userId");
                              localStorage.removeItem("firestoreToken_"+ accountId + "_" + userId);
                              localStorage.setItem("firestoreToken_"+ accountId + "_" + userId , result.data);
                            }
                            
                          });
                    } else {
                        console.log("No user found")
                    }
                },
                (error) => {
                    console.log("inside userSnapshot", error.message);
                    toastHandler.error("error while fetching fetch user details");
                    sentryUtils.captureFirestoreException('fetchUser', error, 'ProtectedRoute');
                }
            );

            return () => {
                userSnapshot()
                console.log("componentWillUnmount in protected roue");
            };
        } catch (error) {
            console.log({ error: error, message: "error while fetching fetch user entries", });
            sentryUtils.captureGenericException('fetchUser', error, 'ProtectedRoute');
        }
    }, []);

       
    if (localStorage.getItem('authenticated_'+ accountId + "_" + userId) !== 'true') {
        return <Navigate to="/" state={{ from: location }} replace />
    }

    if (localStorage.getItem('is_view_only') === 'true') {
        return <Navigate to="/viewer-only" state={{ from: location }} replace />
    }
    if (userRole === constants.usersRole.SUPER_ADMIN && !constants.accessControl.adminAccess.includes(currentRoute)) {
        toastHandler.info("Access denied...");
        dispatch(sidebarMenuClicked('Timesheet'))
        return <Navigate to="/master/timesheet" state={{ from: location }} replace />
    }

    if (userRole === constants.usersRole.ADMIN && !constants.accessControl.adminAccess.includes(currentRoute)) {
        toastHandler.info("Access denied...");
        dispatch(sidebarMenuClicked('Timesheet'))
        return <Navigate to="/master/timesheet" state={{ from: location }} replace />
    }

    if (userRole === constants.usersRole.MEMBER && !constants.accessControl.commonAccess.includes(currentRoute)) {
        toastHandler.info("Access denied...");
        dispatch(sidebarMenuClicked('My Timesheet'))
        return <Navigate to="/master/user/timesheet" state={{ from: location }} replace />
    }

    return children

};

export default ProtectedRoute;