import _ from "lodash";
import React from "react";
import mondaySdk from "monday-sdk-js";
import toastHandler from "../firebaseService/toastHandler";
import MondayConfig from "../configs/MondayConfig";

export async function fetchStatusLabel(selectedProject, statusId, labelId) {
  const monday = mondaySdk();

  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);
  try{
  if (selectedProject) {
    let query =
      `query {
            boards (ids: ` +
      selectedProject +
      `) {
              columns(types: status, ids:"` +
      statusId +
      `") {
                id
                title
                settings_str
              }		
            }
          }`;

    const tokendata = await monday.get("sessionToken");
    const response = await monday
      .api(query, {
        options: { token: tokendata.data },
      })
      .catch((err) => {
        // setBusy(false);
        console.log("error in fetch column --->", err.message);
        toastHandler.error(err.message);
        // sentryUtils.captureMondayException('fetchColumn', err, 'selectStatusColumn');
      });
    const settingsStr = response.data.boards[0].columns[0].settings_str;
    const settingsJson = JSON.parse(settingsStr);
    const labelList = _.get(settingsJson, "labels") || {};

    let labelArray = Object.values(labelList).filter((item) => item != labelId);
    labelArray.push("");
    const labelArrayString = labelArray.map((label) => `"${label}"`).join(", ");
    return labelArrayString;
  }
  }catch(err){
    console.log("assigneeColumn--------->>>>err", err);
    return null;
  }
}
