export const fontFamily = `-apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetical Neue', sans-serif;`;

export const headingSizes = [
  "2rem",
  "1.5rem",
  "1.25rem",
  "1rem",
  ".875rem",
  ".75rem"
];

export const themeColors = {
  dark: "#1c8f9e",
  light: "#5cb7b7",
  black: "#2d2323"
};


export const tour_style = {
  backgroundColor: "#ffffff",
  color: "#1B2F78",
  borderRadius: '5px',
  boxShadow: '0px 1.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
  fontFamily: 'Poppins',
  fontSize: '16px',
  margin:'0px' //Important
}

export const form_tour_style = {
  backgroundColor: "white",
  color: "#181D32",
  borderRadius: '5px',
  boxShadow: '0px 1.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
  fontFamily: 'Poppins',
  fontSize: '16px',
  border: '0.5px solid #99A5D3', // Add border style here
}

export const guided_tour = {
  badge_bg_color:"#007AFF"
} 

export const calendly_style = {
  backgroundColor: "white",
  color: "#1B2F78",
  borderRadius: '3.25px',
  boxShadow: '0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
  fontFamily: 'Poppins',
  weight: '500',
  fontSize: '15px',
  border: '0.5px solid #99A5D3', // Add border style here
  
}

export const dropdown_style = {
  backgroundColor: "white",
  color: "#1B2F78",
  display: "flex",
  alignItems: "center", 
  // borderRadius: '3.25px',
  // boxShadow: '0px 5.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
  fontFamily: 'Poppins',
  weight: '500',
  fontSize: '14px',
  // border: '0.5px solid #99A5D3', // Add border style here
  
}

export const help_style = {
  backgroundColor: "#1B2F78",
  color: "white",
  borderRadius: '3.25px',
  boxShadow: '0px 1.1267390251159668px 8.435643196105957px 0px #BEBEBE38',
  fontFamily: 'Poppins',
  fontSize: '15px',
  
}
