import _ from 'lodash';
import React, { useState, useEffect, useRef } from "react";  //, useContext, useRef, Fragment
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Spin,Select, Popconfirm, Table, Button, Modal, Form, Input, Checkbox, Space, Badge,Tooltip, Popover, Switch } from 'antd';
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import moment from "moment";
import { TimePicker } from 'antd';
import { CalendarFilled, CloseCircleFilled, CloseOutlined, LeftOutlined, PlusCircleFilled, PlusOutlined, RightOutlined } from "@ant-design/icons";
import "firebase/compat/auth";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import { collection, query, where, getDocs, or, and, serverTimestamp,onSnapshot } from "firebase/firestore";
import toastHandler from "../../firebaseService/toastHandler";
import colors from "../../constants/color";
import { constants } from '../../constants';
import mondaySdk from "monday-sdk-js";
import "../UserSummary/antd.css";
import MondayConfig from "../../configs/MondayConfig";
import { Link } from 'react-router-dom';
import {
  FaList
} from "react-icons/fa";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";
import sentryUtils from "../../utils/sentryUtils";
import {fetchStatusLabel} from '../../mondayService/StatusLabel';

function BulkTimesheetEntry(props) {
  const user = firebase.auth().currentUser;
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION)
  const [weekArray, setWeekArray] = useState([])
  const [weekHolidayArray, setWeekHolidayArray] = useState([])
  const [futureDateArray, setFutureDateArray] = useState([])
  const [holidays, setHolidays] = useState([])
  const [bulkRecords, setBulkRecords] = useState([])
  const [count, setCount] = useState(2);
  const [dataSource, setDataSource] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [assigneeColData, setAssigneeColData] = useState({});
  const [approversData, setApproversData] = useState({});
  const [render, setRender] = useState(false);
  const [userLogData, setUserLogData] = useState([])
  const [logsDurationDate, setLogsDurationDate] = useState(moment())
  const [loading, setLoading] = useState(true);
  const [disabledNextBtn, setDisabledNextBtn] = useState(true);
  const [userTasksIds, setUserTasksIds] = useState([])
  const [userSubtasksIds, setUserSubtasksIds] = useState([])
  const [subitemAssigneeColData, setsubitemAssigneeColData] = useState({});
  const [subitemFeature, setSubitemFeature] = useState({});
  const [taskStatusColumnData, setTaskStatusColumnData] = useState({});
  const [taskStatusLabelData, setTaskStatusLabel] = useState({});

  // const [tableParams, setTableParams] = useState({ pagination: { current: 1, pageSize: 10, total: dataSource.length }, });
  // const [popupOverOpned, setPopupOverOpned] = useState(null)

  const getDayOfWeek = (index) => {
    let daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return daysOfWeek[index];
  };

  let popupOverOpned = null
  function forceUpdateComponent() {
    setRender(!render)
  }
  const weekFormat = 'Do MMM YYYY';
  const hrsFormat = 'HH:mm';
  const loggedInUser = useSelector(state => state.userSlice.user)
  let navigate = useNavigate();
  if(loggedInUser && !loggedInUser.hasTeam){
    navigate('/master/AllSetting')
  }

  function disabledDate(current) {
    // Disable dates that are in the future or later than 7 days from the current date
    // return current && current > moment().endOf('day').add(7, 'days');
    return current && (current.day() === 7 || current.isAfter(moment()));

  }
  const updateColHeading = (value = new Date()) => {
    if (value.isBefore(moment().endOf('isoWeek')) && !value.isBetween(moment().startOf('isoWeek'), moment().endOf('isoWeek'))) {
      setDisabledNextBtn(false)
    } else {
      setDisabledNextBtn(true)
    }
    let _weekArray = [];
    let weekHolidayArray = [false, false, false, false, false, false, false];
    let futureDateArray = [false, false, false, false, false, false, false];
    for (let i = 0; i < 7; i++) {
      let date = moment(value).startOf('isoWeek').add(i, 'days')
      let date_format = date.format("DD MMM")
      let holiday_format = date.format(constants.dateTime.DATE).toString()
      // console.log(holiday_format);
      if (holidays.includes(holiday_format)) {
        weekHolidayArray[i] = true;
      }
      futureDateArray[i] = date.isAfter(moment())
      _weekArray.push(date_format);
    }

    setLogsDurationDate(moment(value))
    setWeekArray(_weekArray)
    // console.log(weekHolidayArray)
    setWeekHolidayArray(weekHolidayArray)
    setFutureDateArray(futureDateArray)
    getLogData(value)
  }
  //Note: teams collection contains projects and tasks of users
  const getTeamList = async () => {
    if (user) {
      const assignee = {};
      const approvers = {};
      const subtaskAssignee = {};
      const subtaskFeature = {};
      const taskStatusColumn = {};
      const taskStatusLabel = {};
      const teamsRef = collection(firestore, "teams");
      const teamQuery = query(
        teamsRef,
        and(
          where("accountId", "==", localStorage.getItem("accountId")),
          where("isDeleted", "==", false),
          or(
            where("projectManager.userId", "==", user.uid),
            where("lead.userId", "==", user.uid),
            where("teamCreator.userId", "==", user.uid),
            where("teamMemberIds", "array-contains", user.uid)
          )
        )
      );

      const querySnapshot1 = await getDocs(teamQuery);
      const getProjectList = querySnapshot1.docs.map((doc) => {
        const data = doc.data();
        data.docId = doc.id;
        assignee[doc.id] = data.assigneeColumn;
        subtaskAssignee[doc.id] = data.subitemAssigneeColumn;
        subtaskFeature[doc.id] = data.subitemEnabled ? data.subitemEnabled : false;
        approvers[doc.id] = data.approvers;
        taskStatusColumn[doc.id] = data.taskStatusColumn;
        taskStatusLabel[doc.id] = data.taskStatusLabel;

        return data.project;
      })
      // console.log(getProjectList)
      console.log("assignee", assignee);
      setAssigneeColData(assignee);
      setApproversData(approvers);
      setsubitemAssigneeColData(subtaskAssignee);
      setSubitemFeature(subtaskFeature);
      setProjectList(getProjectList)
      setTaskStatusColumnData(taskStatusColumn);
      setTaskStatusLabel(taskStatusLabel);
    }
  }

  const getLogData = async (value) => {
    if (!value) {
      value = moment()
      updateColHeading(value)
    }
  
    if (user) {
      moment.updateLocale("en", {
        week: {
          dow: 1,
        },
      });
  
      let start = moment(value).startOf('isoWeek').toDate();
      let end = moment(value).endOf('isoWeek').toDate();
  
      const timeEntryRef = collection(firestore, "timeEntry");
      const logQuery = query(
        timeEntryRef,
        and(
          where("accountId", "==", localStorage.getItem("accountId")),
          where("deleted", "==", false),
          where('logDate', '>=', start),
          where('logDate', '<=', end),
          where('userId', '==', user.uid),
        )
      );
  
      const timeEntrySnapshot = onSnapshot(logQuery, (querySnapshot) => {
        const userLogData = [];
        const userTasksIds = [];
        const userSubtasksIds = [];
  
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.docId = doc.id;
          userLogData.push({
            docId: doc.id,
            project: data.project,
            task: data.task,
            subtask: data.subtask? data.subtask : null,
            logDate: data.logDate,
            logHours: data.logHours,
            billable: data.billable,
            description: data.description,
            approvalStatus: data.approvalStatus,
          });
  
          if (data.task && data.task.key) {
            userTasksIds.push(data.task.key);
          }
          if (data.subtask && data.subtask.key) {
            userSubtasksIds.push(data.subtask.key);
          }
        });
  
        setUserTasksIds(userTasksIds);
        setUserSubtasksIds(userSubtasksIds);
        setUserLogData(userLogData);
        createDataSource(userLogData);
        forceUpdateComponent();
        setLoading(false);
      });
  
      return timeEntrySnapshot; // Return function for cleanup
    }
  };

  // useEffect(() => {
  //   getLogData() //to get log list
  // }, [])

  useEffect(() => {
    getTeamList() //to get project list
  }, [])


  useEffect(() => {
    updateColHeading(moment())
  }, [holidays])

  useEffect(() =>{
    logEvent(analytics,"bulk_timesheet" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  // useEffect(() => {
  //   getHolidayList() //to get holiday list
  // }, [])

  const handleAddRecord = () => {
    const newData = {
      key: count + 1,
      project: null,
      task: null,
      sun: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      billable: true,
      description: null,
      disabled: false,
      logDate: null,
      total_hrs: '00:00',
    };
    let tableData = [...dataSource, newData]
    let requiredData = tableData.filter(function (v, i, self) { return i == self.indexOf(v); })
    setDataSource(requiredData);
    setCount(count + 1);
    // setTableParams({
    //   ...tableParams,
    //   pagination: {
    //     ...tableParams.pagination,
    //     total: a.length,
    //   },
    // });
    // toastHandler.info("Row added.")
  };

  function groupByTaskAndSubtask(collection, groupByKeyAttribute = false) {
    let i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      if (groupByKeyAttribute) {
        if(typeof collection[i]['task'] == 'object'){
          if(typeof collection[i]['subtask'] == 'object' && collection[i]['subtask'] != null && collection[i]['subtask'] != undefined) {
            val = collection[i]['task'].key + '_' + collection[i]['subtask'].key 
          } else {
            val = collection[i]['task'].key
          }
        }
      } else {
        val = (typeof collection[i]['task'] == 'object') ? collection[i]['task'].value : collection[i]['task'];
      }
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const createDataSource = (userLogData) => {
    let newData = null
    let finalDataSourceLength = 1
    let finalDataSource = []
    let groupByTask = groupByTaskAndSubtask(userLogData, true)
    if (groupByTask.length) {
      finalDataSourceLength = groupByTask.length

      let dayLog = [] // for day
      let hoursLog = [] // for logHours
      let billableData = [] // for billable
      let descr = [] // for description
      let apprvStatus = [] // for approval status

      let docIds = []
      dayLog.length = 7
      billableData.length = 7
      descr.length = 7
      apprvStatus.length = 7

      groupByTask.forEach((element, index) => {
        docIds = []
        dayLog = []
        hoursLog = []
        billableData = []
        descr = []
        apprvStatus = []
        console.log(element)
        element.forEach((v) => {
          let logDate = new Date(moment(v.logDate.seconds * 1000)).getDay()
          hoursLog[logDate] = v.logHours
          dayLog[logDate] = logDate
          billableData[logDate] = v.billable
          descr[logDate] = v.description
          docIds[logDate] = v.docId
          apprvStatus[logDate] = v.approvalStatus
        })

        let total_hrs = '00:00';
        let totalMins = 0;
        for (let i = 0; i < dayLog.length; i++) {
          if (hoursLog[i]) {
            let time = hoursLog[i].split(':')
            totalMins += ((parseInt(time[0]) * 60) + parseInt(time[1]))
          }
          let hours = Math.floor(totalMins / 60);
          let minutes = totalMins % 60;
          total_hrs = String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0')
        }
        let daysArray = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
        newData = {
              key: index + 1,
              project: element[0].project,
              task: element[0].task,
              subtask: element[0].subtask,
              total_hrs: total_hrs,
              disabled: true,
        }  
        daysArray.forEach((day, i) => {
          // console.log("createarray",day)
          newData[day] = hoursLog[i] ? hoursLog[i] : null;
          newData[`${day}dayLogDate`] = dayLog[i] ? dayLog[i] : null;
          newData[`${day}dayApprovalStatus`] = apprvStatus[i] ? apprvStatus[i] : null;
          newData[`${day}dayBillable`] = billableData[i] !== null ? billableData[i] : true;
          newData[`${day}dayDescription`] = descr[i] ? descr[i] : null;
          newData[`${day}dayDocId`] = docIds[i] ? docIds[i] : null;
        });
        finalDataSource.push(newData)
      })
      console.log('finalDataSource', finalDataSource)
      setDataSource(finalDataSource);
      setCount(count + finalDataSourceLength);
      // setTableParams({
      //   ...tableParams,
      //   pagination: {
      //     ...tableParams.pagination,
      //     total: dataSource.length,
      //   },
      // });
    } else {
      setDataSource([]);
      setCount(0);
    }
  };

  // const getHolidayList = () => {

  //   {
  //     let query = `query {
  //       boards (ids:[4648927638]) 
  //         {
  //           items {
  //             column_values {
  //               text
  //             }
  //           }
  //         }
  //       }`;

  //     fetch("https://api.monday.com/v2", {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: localStorage.getItem("accessToken"),
  //       },
  //       body: JSON.stringify({
  //         query: query,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((response) => {
  //         // console.log(response);
  //         const holiday_items = response.data.boards[0].items;
  //         // console.log(holiday_items);
  //         let holiday_array = [];
  //         holiday_items.forEach((item) => {
  //           holiday_array.push(item.column_values[0].text)
  //         });
  //         setHolidays(holiday_array);
  //         // console.log(holiday_array);
  //       }).catch((err) => {
  //         console.log(err.message);
  //         // toastHandler.error(err.message);
  //       });
  //   }

  // }

  const getProjectTasks = async (selectedProject = null, props) => {
    if (selectedProject) {
      let cursorV = null;
      let arr = [];
      console.log("board key " + selectedProject);
      let assigneeColumn = assigneeColData[selectedProject];
      let taskStatusColumn = taskStatusColumnData[selectedProject];
      let taskStatusLabel = taskStatusLabelData[selectedProject];
      let labelArray = null;
      if(taskStatusColumn != null && taskStatusLabel != null)  {
        labelArray = await fetchStatusLabel(selectedProject, taskStatusColumn.value, taskStatusLabel.value);
    }

      if (assigneeColumn !== undefined) {
        // For deleted columns, it is fetching all items on board, need to raise support ticket
        let query;
        if(labelArray != null){
          query = `query {
            items_page_by_column_values (limit: 100, board_id: ` + selectedProject + 
            `, columns: [
            {column_id: "` + assigneeColumn.key + `", column_values: ["` + user.uid.toString() + `"]}
            {column_id: "` + taskStatusColumn.key + `", column_values: [` +labelArray+`]}
            ] ) {
              cursor
              items {
                id
                name
              }
            }
          }
          `;

          
        }else{
           query = `query {
            items_page_by_column_values (limit: 100, board_id: ` + selectedProject + 
            `, columns: [
            {column_id: "` + assigneeColumn.key + `", column_values: ["` + user.uid.toString() + `"]}
            ] ) {
              cursor
              items {
                id
                name
              }
            }
          }
          `;
        }
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(
          query,
          {
            options: { token: tokendata.data },
          }
        ).catch((err) => {
          console.log(err);
          // toastHandler.error(err.error_message);
          sentryUtils.captureMondayException('fetchItems', err, 'BulkTimesheetEntry');
        });
        if (response && response.data) {

          console.log(response.data);
          // console.log(response.data.items_page_by_column_values?.items);
          cursorV = response.data.items_page_by_column_values?.cursor;
          arr = response.data.items_page_by_column_values?.items;
          // till cursor is present, query for this 
          while (cursorV !== null) {

            let q = `query {
              next_items_page (cursor: "` + cursorV + `", limit: 100) {
                cursor
                items {
                  id
                  name
                }
              }
            }`

            const res = await monday.api(
              q,
              {
                options: { token: tokendata.data },
              }
            ).catch((err) => {
              console.log(err.message);
              toastHandler.error(err.message);
              sentryUtils.captureMondayException('fetchNextItems', err, 'BulkTimesheetEntry');
            });
            console.log("tasks: ", res.data?.next_items_page?.items)
            cursorV = res.data?.next_items_page?.cursor
            arr = [...arr, ...res.data?.next_items_page?.items]

          }
          if (arr.length === 0) {
            toastHandler.warn("No tasks are assigned to you yet");
          }
          props.record.taskList = arr
          props.record.subtaskDisabled = false
        }
      } else {
        toastHandler.error("please contact admin to set assignee column for team");
      }
    }
  }

  const getProjectSubTasks = async (selectedTask = null, props) => {
    // console.log('props', props)
    // console.log('selectedTask', selectedTask.key)
    const selectedProject = props.record.project
    if (selectedProject && selectedTask) {
      props.record.subtask = null
      props.record.subtaskList = null
      props.record.subtaskPlaceholder = "Select Subtask"
      // console.log("task key " + selectedTask);
      let subitemAssigneeColumn = subitemAssigneeColData[selectedProject.key];
      if (!(subitemAssigneeColumn === undefined || subitemAssigneeColumn === null)) {
        // For deleted columns, it is fetching all items on board, need to raise support ticket
        let query = `query {
          items (ids: ${selectedTask.key}) {
            subitems {
              id
              name
              column_values(ids: ["${subitemAssigneeColumn.key}"]){
                id
                value
                text
              }
            }
          }
        }`
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(
          query,
          {
            options: { token: tokendata.data },
          }
        ).catch((err) => {
          console.log(err);
          // toastHandler.error(err.error_message);
          sentryUtils.captureMondayException('fetchSubtItems', err, 'BulkTimesheetEntry');
        });

        if (response && response.data) {
          // console.log(response.data);
          // console.log(response.data.items[0].subitems);
          let subtasklist = response.data.items[0].subitems
          // console.log("sub TaskList", response.data.items[0].subitems);
          const filteredSubTask = subtasklist.filter(entry => {
            if(entry.column_values[0]){
              const columnValue = JSON.parse(entry.column_values[0].value);
              return columnValue?.personsAndTeams.some(person => person.id == user.uid);
            }
          });
          setLoading(false)
          props.record.allSubtaskList = subtasklist
          props.record.subtaskList = filteredSubTask
          props.record.subtaskPlaceholder = "Select Subtask"
          if (!props.record.subtaskDisabled && subtasklist.length > 0 && filteredSubTask.length === 0) {
            props.record.subtaskError = 'error'
            props.record.subtaskPlaceholder = "No Subtask assigned"
          }
        }
      } else {
        // toastHandler.error("please contact admin to set assignee column for team");
        props.record.subtaskDisabled = true
        if (!subitemFeature[selectedProject.key]) {
          props.record.subtaskPlaceholder = "Subtask Feature Off"
        } else {
          props.record.subtaskPlaceholder = "Assignee column Not Set"
        }
      }
    }
  }

  const ProjectDropDown = (props) => {
    const [status, setStatus] = useState('error')
    const onFinish = (value) => {
      let selectedProject = projectList.filter((e) => { return e.value == value })
      let r = props.record
      r[props.dataIndex] = selectedProject[0]
      let br = dataSource
      if (r[props.dataIndex]) {
        br.push(r)
      }
      if (props.record.task) {
        //remove old task key
        let updatedTaskIds = userTasksIds.filter(function (key) { return key != props.record.task.key; })
        setUserTasksIds(updatedTaskIds)
      }
      //add new task key
      props.record.task = null
      props.record.taskList = null
      props.record.subtask = null
      props.record.subtaskList = null
      props.record.allSubtaskList = null
      props.record.subtaskPlaceholder = "Select Subtask"
      setBulkRecords(br)
      getProjectTasks(selectedProject[0].key, props)
      setStatus('')
      forceUpdateComponent()
    };
    return (
      <>
        <Select
          placeholder='Project'
          status={(!props.record.project) && props.record?.projectError}
          defaultValue={props.record.project}
          style={{ width: 200 }}
          onChange={onFinish}
          options={projectList}
          id="projectDropdown"          
          showSearch
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          disabled={props.record.disabled}
        />
      </>
    )
  }
  
  const TaskDropDown = (props) => {
    
    const [taskList, setTaskList] = useState(props.record.taskList);
    const [status, setStatus] = useState('error')
    
    const onFinish = () => {
      fetchTaskList();
      // setTaskList(props.record.taskList?.map((e) => { return { key: e.id, value: e.name, disabled: (userTasksIds.indexOf(e.id) != -1) } }))
    }
    const onFinish2 = (value) => {
      props.record.subtaskError = null
      let selectedTask = props.record.taskList?.map((e) => { return { key: e.id, value: e.name } })?.filter((e) => { return e.value == value })
      let check = userTasksIds
      if (userTasksIds.indexOf(selectedTask[0].key) === -1) {
        if (props.record.task) {
          //remove old task key
          check = userTasksIds.filter(function (key) { return key != props.record.task.key; })
        }
        //add new task key
        setUserTasksIds([...check, selectedTask[0].key])
        // console.log(selectedTask[0])
      }
      if (props.record.subtask) {
        //remove old subtask key
        let updatedUserSubtasksIds = userSubtasksIds.filter(function (key) { return key != props.record.subtask?.key; })
        setUserSubtasksIds(updatedUserSubtasksIds)
      }
      props.record.task = selectedTask[0]
      getProjectSubTasks(selectedTask[0], props)
      props.record.subtask = null
    }
    
      
    const fetchTaskList = async() => {
      // Fetch the task list and update state
      let subitemAssigneeColumn = subitemAssigneeColData[props.record.project?.key];
      // console.log("subitemAssigneeColumn", subitemAssigneeColumn);
      setTaskList(props.record.taskList?.map((e) => ({
          key: e.id,
          value: e.name,
          disabled: ((subitemAssigneeColumn !== undefined && subitemAssigneeColumn !== null) ? false : userTasksIds.indexOf(e.id) !== -1)
      })));
    };

    useEffect(() => {
        // Fetch the task list when the component mounts or when props.record.taskList changes
        fetchTaskList();
    }, [props.record.taskList]);
    return (
        <Select
          id="taskDropdown"
          placeholder='Task'
          status={(!props.record.task) && props.record?.taskError}
          defaultValue={props.record.task}
          style={{ width: 200 }}
          onClick={onFinish}
          onFocus={onFinish}
          onChange={onFinish2}
          onSelect={() => setStatus('')}
          // options={taskList?.map((e) => { return { key: e.id, value: e.name } })}
          options={taskList}
          showSearch
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          disabled={props.record.disabled}
          // loading={isLoading} 
          // notFoundContent={isLoading ? 'Loading...' : 'No options'} 
        />
    )
  }

  const SubtaskDropDown = (props) => {
    
    const [subtaskList, setSubtaskList] = useState(props.record.subtaskList);
    const onFinish = () => {
      fetchSubTaskList();
    }
    const onSubtaskFinish2 = (value) => {
      // console.log('subtask_new', props.record.subtask)
      const selectedSubtask = props.record.subtaskList?.map((e) => { return { key: e.id, value: e.name } })?.filter((e) => { return e.value == value })
      // console.log('subtaskList', subtaskList)
      // console.log("userSubtasksIds", userSubtasksIds)
          
      let check = userSubtasksIds
      if (userSubtasksIds.indexOf(selectedSubtask[0].key) === -1) {
        if (props.record.subtask) {
          //remove old subtask key
          check = userSubtasksIds.filter(function (key) { return key != props.record.subtask.key; })
        }
        props.record.subtask = selectedSubtask[0]
        //add new task key
        setUserSubtasksIds([...check, selectedSubtask[0].key])
      }
      console.log('subtask', props.record.task)
    }
    const fetchSubTaskList = async() => {
      // Fetch the subtask list and update state
      setSubtaskList(props.record.subtaskList?.map((e) => ({
          key: e.id,
          value: e.name,
          disabled: (userSubtasksIds.indexOf(e.id) !== -1)
      })));
    };

    useEffect(() => {
        // Fetch the subtask list when the component mounts or when props.record.subtaskList changes
        fetchSubTaskList();
    }, [props.record.subtaskList]);
    return (
      <Tooltip title={props.record.subtask!= null ? props.record.subtask.value : props.record.subtaskPlaceholder}>
        <Select
          id="subtaskDropdown"
          placeholder={props.record.subtaskPlaceholder}
          status={(!props.record.subtask) && props.record?.subtaskError}
          defaultValue={props.record.subtask}
          style={{ width: 200 }}
          onClick={onFinish}
          onFocus={onFinish}
          onChange={onSubtaskFinish2}
          // onSelect={() => setStatus('')}
          // options={taskList?.map((e) => { return { key: e.id, value: e.name } })}
          options={subtaskList}
          showSearch
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          disabled={props.record.subtaskDisabled || props.record.disabled}
          // loading={isLoading} 
          // notFoundContent={isLoading ? 'Loading...' : 'No options'} 
        />
      </Tooltip>
    )
  }

  // const BillableCheckBox = (props) => {
  //   const [checked, setChecked] = useState(props.record.billable);
  //   const onChange = (e) => {
  //     setChecked(e.target.checked);
  //     props.record.billable = e.target.checked
  //   };
  //   return (
  //     <Checkbox checked={checked} onChange={onChange}> </Checkbox>
  //   )
  // }

  const HrsTimePicker = (props) => {
    // console.log("hrs prop")
    const [showBadge, setShowBadge] = useState(false);
    const [description, setDescription] = useState((props.record[props.dataIndex + 'dayDescription'] === undefined) ? '' : props.record[props.dataIndex + 'dayDescription']);
    const [billable, setBillable] = useState((props.record[props.dataIndex + 'dayBillable'] === undefined) ? true : props.record[props.dataIndex + 'dayBillable']);

    let timePickerValue = props.record[props.dataIndex] ? moment(props.record[props.dataIndex], hrsFormat) : null
    let billableValue = props.record[props.dataIndex + 'dayBillable'] ? props.record[props.dataIndex + 'dayBillable'] : true
    let descriptionValue = props.record[props.dataIndex + 'dayDescription'] ? props.record[props.dataIndex + 'dayDescription'] : null

    const disabledCol = (props.record[props.dataIndex + 'dayApprovalStatus'] === 'Approved' || props.isFutureDate) ? true : false
    const [openPopup, setOpenPopup] = useState(false)

    props.record[props.dataIndex + 'dayBillable'] = billable
    props.record[props.dataIndex + 'dayDescription'] = description

    const onFinish = (value, td = moment(props.columnDate).format(constants.dateTime.DATE)) => {
      // console.log("inside on finish", props.record.subtask)
      if (!props.record.project) {
        props.record.projectError = 'error'
      }
      if (!props.record.task) {
        props.record.taskError = 'error'
      }
      if (!props.record.subtask && !props.record.subtaskDisabled && props.record.allSubtaskList  && props.record.allSubtaskList.length > 0 ) {
        props.record.subtaskError = 'error'
        if (props.record.subtaskList.length === 0) {
          props.record.subtaskPlaceholder = "No Subtask assigned"
        }
      }
      
      // console.log("error", props.record.allSubtaskList)
      let recordLog = props.record
      recordLog[props.dataIndex] = value ? `${moment(props.columnDate).format(constants.dateTime.DATE) + ' ' + moment(value).format(hrsFormat)}` : null
      let br = dataSource.filter(function (v, i, self) { return i == self.indexOf(v); })
      if (recordLog[props.dataIndex]) {
        br.push(recordLog)
      }
      props.record.total_hrs = value ? `${moment(value).format(hrsFormat)}` : null
      props.record[props.dataIndex] = value ? `${moment(value).format(hrsFormat)}` : null
      let columnDate = new Date(new Date(props.week + ' ' + moment(logsDurationDate).format('YYYY')))
      props.record[props.dataIndex + 'dayDate'] = value ? `${moment(columnDate).format(constants.dateTime.DATE) + ' ' + moment(value).format(hrsFormat)}` : null

      let daysArray = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

      let totalMins = 0;
      for (let i = 0; i < daysArray.length; i++) {
        if (props.record[daysArray[i]]) {
          let time = props.record[daysArray[i]].split(':')
          totalMins += ((parseInt(time[0]) * 60) + parseInt(time[1]))
        }
        let hours = Math.floor(totalMins / 60);
        let minutes = totalMins % 60;
        props.record.total_hrs = String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0')
      }
      forceUpdateComponent()
      // setBulkRecords(br)
    };

    const updateProps = () => {
      setOpenPopup(!openPopup)
      if (openPopup === true) {
        console.log('updateProps')
        onPopoverDataChange()
      }
    }

    const onPopoverDataChange = () => {
      // "2023-07-03 06:35"
      let columnDate = new Date(new Date(props.week + ' ' + moment(logsDurationDate).format('YYYY')))
      props.record[props.dataIndex + 'dayDate'] = `${moment(columnDate).format(constants.dateTime.DATE)}`
      let br = dataSource.filter(function (v, i, self) { return i == self.indexOf(v); })
      br.push(props.record)
      br = dataSource.filter(function (v, i, self) { return i == self.indexOf(v); })
      // console.log('openPopup', openPopup)
      setDataSource(br)
    }
    const FormEdit = (
      <Form layout="horizontal">
        {/* <div className="float-right">
          <Button type="text" onClick={() => setOpenPopup(!openPopup)}
          icon={<PlusCircleFilled />}
            style={{ cursor: 'pointer' }} />
        </div> */}
        <br />
        <div id="description">
          <label> Description </label>
          <Input.TextArea defaultValue={descriptionValue} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <br />
        <br />
        <span id="billableCheckbox">
          <label> Billable : &nbsp; </label>
          <Checkbox  checked={billable} onChange={(e) => setBillable(e.target.checked)}> </Checkbox>
        </span>
        <br />
        <div className="float-right">
          <Button id="addUpdateButton" type="primary" size="small" onClick={() => updateProps()}> {props.record[props.dataIndex + 'dayDocId'] ? 'update' : 'add' } </Button> 
        </div>
        <br />
      </Form>
    );

    const showPopOver = () => {
      setOpenPopup(!openPopup)
      popupOverOpned = !openPopup
    }
    return (
      <Tooltip placement="right" title="Description">
        <div id="badge">
        <Badge size="default"
          onMouseLeave={() => openPopup ? true : setShowBadge(false)}
          count={
            (showBadge && disabledCol === false) ?
              <Popover 
                content={FormEdit}
                placement="bottomRight"
                description="Open Popover with async logic"
                open={openPopup}
              >
                <span id="popupIcon" onClick={() => showPopOver()}
                  style={{ cursor: 'pointer' }}>
                  {(!openPopup) ? <PlusCircleFilled /> : <CloseCircleFilled />}
                </span>
              </Popover>
              : 0
          }
        >
      <div id="timePicker">
        <TimePicker popupClassName='noFooterTimePick'
          defaultValue={(timePickerValue)}
          placeholder="_:_"
          format={hrsFormat}
          onSelect={onFinish}
          showNow={false}
          showOk={false}
          style={{ background: props.isHoliday ? colors.HOLIDAY_BG : null }}
          disabledTime={disabledTime}
          minuteStep={30}
          disabled={disabledCol}
          allowClear={false}
          onMouseEnter={() => (popupOverOpned != true) && setShowBadge(true)
          }
        />
        </div>
        </Badge>
        </div>
      </Tooltip>
    )
  }

  function disabledTime() {
    return {
      disabledHours: () => [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      // disabledMinutes: () => [0]
    };
  }

  const defaultColumns = [
    {
      title: 'Project',
      dataIndex: 'project',
      align: 'left',
      width: '200px',
      render: (_, record) => (
        <div id="project">
          <ProjectDropDown dataIndex={'project'} record={record}/>
        </div>
      ),
    },
    {
      title: 'Task',
      dataIndex: 'task',
      width: '200px',
      render: (_, record) => (
        <div id="task">
          <TaskDropDown dataIndex={'task'} record={record} />
        </div>
      ),
      align: 'left',
    },
    {
      title: 'Subtask',
      dataIndex: 'subtask',
      width: '200px',
      render: (_, record) => (
        <div id="subtask">
          <SubtaskDropDown dataIndex={'subtask'} record={record} />
        </div>
      ),
      align: 'left',
    },

    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[0] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[0] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },

    //   title: () => {
    //     return (<> Mon <div>  {(weekArray[0] != 'Invalid date') ? weekArray[0] : ''}  </div> </>)
    //   },
    //   dataIndex: 'mon',
    //   align: 'center',
    //   width: '100px',
    //   render(_, record) {
    //     return {
    //       props: {
    //         // style: { background: weekHolidayArray[0] ? colors.HOLIDAY_BG : null},
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"mon"}
    //           isHoliday={weekHolidayArray[0]}
    //           isFutureDate= {futureDateArray[0]}
    //           record={record}
    //           week={weekArray[0]}
    //         />
    //       ),
    //     };
    //   },
    // },
    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[1] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[1] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },
    //   title: () => {
    //     return (<> Tue <div>  {(weekArray[1] != 'Invalid date') ? weekArray[1] : ''}  </div> </>)
    //   },
    //   dataIndex: 'tue',
    //   align: 'center',
    //   width: '100px',
    //   // render: (_, record) => (<HrsTimePicker dataIndex={'tue'} record={record} week={weekArray[1]} />)
    //   render(_, record) {
    //     return {
    //       props: {
    //         //  style: { background: weekHolidayArray[1] ? colors.HOLIDAY_BG : null },
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"tue"}
    //           isHoliday={weekHolidayArray[1]}
    //           isFutureDate= {futureDateArray[1]}
    //           record={record}
    //           week={weekArray[1]}

    //         />
    //       ),
    //     };
    //   },
    // },
    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[2] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[2] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },
    //   title: () => {
    //     return (<> Wed <div>  {(weekArray[2] != 'Invalid date') ? weekArray[2] : ''}  </div> </>)
    //   },
    //   dataIndex: 'wed',
    //   align: 'center',
    //   width: '100px',
    //   // render: (_, record) => (<HrsTimePicker dataIndex={'wed'} record={record} week={weekArray[2]} />)
    //   render(_, record) {
    //     return {
    //       props: {
    //         //  style: { background: weekHolidayArray[2] ? colors.HOLIDAY_BG : null },
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"wed"}
    //           isHoliday={weekHolidayArray[2]}
    //           isFutureDate= {futureDateArray[2]}
    //           record={record}
    //           week={weekArray[2]}

    //         />
    //       ),
    //     };
    //   },
    // },
    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[3] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[3] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },
    //   title: () => {
    //     return (<> Thu <div>  {(weekArray[3] != 'Invalid date') ? weekArray[3] : ''}  </div> </>)
    //   },
    //   dataIndex: 'thu',
    //   align: 'center',
    //   width: '100px',
    //   // render: (_, record) => (<HrsTimePicker dataIndex={'thu'} record={record} week={weekArray[3]} />)
    //   render(_, record) {
    //     return {
    //       props: {
    //         //  style: { background: weekHolidayArray[3] ? colors.HOLIDAY_BG : null },
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"thu"}
    //           isHoliday={weekHolidayArray[3]}
    //           isFutureDate= {futureDateArray[3]}
    //           record={record}
    //           week={weekArray[3]}

    //         />
    //       ),
    //     };
    //   },
    // },
    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[4] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[4] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },
    //   title: () => {
    //     return (<> Fri <div>  {(weekArray[4] != 'Invalid date') ? weekArray[4] : ''}  </div> </>)
    //   },
    //   dataIndex: 'fri',
    //   align: 'center',
    //   width: '100px',
    //   // render: (_, record) => (<HrsTimePicker dataIndex={'fri'} record={record} week={weekArray[4]} />)
    //   render(_, record) {
    //     return {
    //       props: {
    //         //  style: { background: weekHolidayArray[4] ? colors.HOLIDAY_BG : null },
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"fri"}
    //           isHoliday={weekHolidayArray[4]}
    //           isFutureDate= {futureDateArray[4]}
    //           record={record}
    //           week={weekArray[4]}

    //         />
    //       ),
    //     };
    //   },
    // },
    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[5] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[5] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },
    //   title: () => {
    //     return (<> Sat <div>  {(weekArray[5] != 'Invalid date') ? weekArray[5] : ''}  </div> </>)
    //   },
    //   dataIndex: 'sat',
    //   align: 'center',
    //   width: '100px',
    //   // render: (_, record) => (<HrsTimePicker dataIndex={'sat'} record={record} week={weekArray[5]} />)
    //   render(_, record) {
    //     return {
    //       props: {
    //         //  style: { background: weekHolidayArray[5] ? colors.HOLIDAY_BG : null },
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"sat"}
    //           isHoliday={weekHolidayArray[5]}
    //           isFutureDate= {futureDateArray[5]}
    //           record={record}
    //           week={weekArray[5]}

    //         />
    //       ),
    //     };
    //   },
    // },
    // {
    //   onHeaderCell: () => {
    //     return {
    //       style: {
    //         background: weekHolidayArray[6] ? colors.HOLIDAY_BG : null,
    //         color: weekHolidayArray[6] ? colors.HOLIDAY_TEXT : null,
    //       },
    //     };
    //   },
    //   title: () => {
    //     return (<> Sun <div>  {(weekArray[6] != 'Invalid date') ? weekArray[6] : ''}  </div> </>)
    //   },
    //   dataIndex: 'sun',
    //   align: 'center',
    //   width: '100px',
    //   // render: (_, record) => (<HrsTimePicker dataIndex={'sun'} record={record} week={weekArray[6]} />)
    //   render(_, record) {
    //     return {
    //       props: {
    //         //  style: { background: weekHolidayArray[6] ? colors.HOLIDAY_BG : null },
    //       },
    //       children: (
    //         <HrsTimePicker
    //           dataIndex={"sun"}
    //           isHoliday={weekHolidayArray[6]}
    //           isFutureDate= {futureDateArray[6]}
    //           record={record}
    //           week={weekArray[6]}

    //         />
    //       ),
    //     };
    //   },
    // },
    // Dynamically generated date columns (Mon, Tue, Wed, Thu, Fri, Sat, Sun)
    ...weekArray.map((weekDate, index) => ({
      onHeaderCell: () => ({
        style: {
          background: weekHolidayArray[index] ? colors.HOLIDAY_BG : null,
          color: weekHolidayArray[index] ? colors.HOLIDAY_TEXT : null,
        },
      }),
      
      title: () => (
        <>
          {getDayOfWeek(index)} 
          <div>{weekDate !== 'Invalid date' ? weekDate : ''}</div>
        </>
      ),
      dataIndex: getDayOfWeek(index).toLowerCase(), 
      align: 'center',
      width: '100px',
      render(_, record) {
        return {
          props: {
            //  style: { background: weekHolidayArray[5] ? colors.HOLIDAY_BG : null },
          },
          children: (
            <HrsTimePicker
              dataIndex={getDayOfWeek(index).toLowerCase()}
              isHoliday={weekHolidayArray[index]}
              isFutureDate={futureDateArray[index]}
              record={record}
              week={weekDate}
            />
          ),
        };
      },
    })),
  
    {      
      title: () => {
        return (<> Total <div>  Hrs.  </div> </>)
      },
      dataIndex: 'total_hrs',
      align: 'center',
      width: '75px',
      render: (_, record) => (<b> {record.total_hrs}</b>)
    },
    // {
    //   title: 'Billable',
    //   dataIndex: 'billable',
    //   align: 'center',
    //   render: (_, record) => (<BillableCheckBox record={record} />)
    // },
    {
      title: 'Action',
      dataIndex: 'operation',
      align: 'center',
      width: '75px',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <div >
            <Popconfirm title={"Sure to delete?"} onConfirm={() => handleDelete(record)} disabled={record.disabled}>
              <Button type="text" disabled={record.disabled} icon={<CloseOutlined />} />
            </Popconfirm>
          </div>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    if (record.task) {
      setUserTasksIds(userTasksIds.filter(function (key) { return key != record.task.key; }))
    }
    // const newData = dataSource.filter((item) => item.key !== record.key);
    const newData = dataSource.filter((item) => item.key != record.key).filter(function (v, i, self) { return i == self.indexOf(v); });
    console.log(newData)
    setDataSource(newData);

    // setTableParams({
    //   ...tableParams,
    //   pagination: {
    //     ...tableParams.pagination,
    //     total: newData.length,
    //   },
    // });
    // toastHandler.info("Row removed.")
  };

  // Function to check if two objects have the same project, task, and subtask keys
  function hasSameKeys(obj1, obj2) {
    if(obj1.project == null || obj2.project == null || obj1.task == null || obj2.task == null)
      return false
    return obj1.key !== obj2.key && 
          obj1.project.key === obj2.project.key &&
          obj1.task.key === obj2.task.key &&
          (obj1.subtask == null || obj1.subtask == undefined) &&
          (obj2.subtask == null || obj2.subtask == undefined);
  }
  // Check if the array has two objects with the same project, task, and subtask keys
  function hasDuplicateKeys(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (hasSameKeys(arr[i], arr[j])) {
          return arr[i].task.value; // Found duplicates
        }
      }
    }
    return "No duplicate"; // No duplicates found
  }   

  const handleSave = async () => {
    console.log('datasource', dataSource)
    const isDuplicate = hasDuplicateKeys(dataSource)
    console.log('isDuplicate', isDuplicate)
    if(isDuplicate ===  "No duplicate"){

    
      let recordsToAdd = dataSource
        .filter(function (v, i, self) { return i == self.indexOf(v); })
      console.log('recordsToAdd', recordsToAdd)
      let daysArray = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
      let finalArrayToCreate = [];
      let finalArrayToUpdate = [];
      let finalArrayToDelete = [];
      recordsToAdd.forEach(element => {
        console.log(element)
        for (let index = 0; index < 7; index++) {
          
          //create time rentry object
          if(element.project!=null && element.task!=null  && (element.subtask!=null || element.subtaskDisabled || element.allSubtaskList == null || element.allSubtaskList === undefined ||  ( element.allSubtaskList && element.allSubtaskList.length === 0)))
          {
            let timeEntryObj = {
              accountId: localStorage.getItem("accountId"),
              approvalStatus: "Pending",
              billable: element[daysArray[index] + "dayBillable"],
              description: element[daysArray[index] + "dayDescription"],
              createdAt: serverTimestamp(),
              deleted: false,
              invoiceStatus: "Pending",
              project: element.project,
              approvers: approversData[element.project.key],
              task: element.task,
              subtask: element.subtask,
              updatedAt: new Date(),
              invoiceStatus: "Pending",
              deleted: false,
              logDate: new Date(
                // moment(element[daysArray[index] + "dayDate"]).format()
                moment(element[daysArray[index] + "dayDate"], "DD MMM YYYY").toDate()//format()
              ),
              logHours: element[daysArray[index]] ? element[daysArray[index]] : null,
              userId: user.uid,
              userName: user.displayName,
            };
            if (element[daysArray[index] + 'dayDocId']) {
              timeEntryObj.docId = element[daysArray[index] + 'dayDocId']
            }

            if (
              element[daysArray[index]]
              && element[daysArray[index]] != '00:00'
              && element.project
              && element.task
              && element[daysArray[index] + 'dayDate']
            ) {
              //new and updated time entries

              if (element[daysArray[index] + 'dayDocId']) {
                //updated records
                timeEntryObj.updatedAt = new Date()
                delete timeEntryObj.createdAt
                finalArrayToUpdate.push(timeEntryObj)
              } else {
                //new records
                timeEntryObj.reviewerComments = []
                finalArrayToCreate.push(timeEntryObj)
              }

            } else if ((element[daysArray[index]] === null || element[daysArray[index]] === '00:00') && element[daysArray[index] + 'dayDocId']) {
              //deleted and updated time entries
              // console.log("in time else")
              timeEntryObj.deleted = true
              delete timeEntryObj.logDate
              delete timeEntryObj.logHours
              delete timeEntryObj.createdAt
              finalArrayToDelete.push(timeEntryObj)
            }
          }
        }
      });

      if (finalArrayToCreate.length || finalArrayToUpdate.length || finalArrayToDelete.length) {
        try {
          let db = firebase.firestore();
          let batch = db.batch()

          // to update existing record
          finalArrayToDelete.forEach((doc) => {
            console.log(doc.docId)
            let docRef = db.collection("timeEntry").doc(doc.docId);
            delete doc.docId
            batch.update(docRef, doc);
          });

          // to update existing record
          finalArrayToUpdate.forEach((doc) => {
            console.log(doc.docId)
            let docRef = db.collection("timeEntry").doc(doc.docId);
            delete doc.docId
            batch.update(docRef, doc);
          });

          // to create new record
          finalArrayToCreate.forEach((doc) => {
            let docRef = db.collection("timeEntry").doc(); //automatically generate unique id
            batch.set(docRef, doc);
          });

          batch.commit()
          toastHandler.info("Time entries saved.")
          logEvent(analytics, 'time_entry_resubmitted', { "account_id": localStorage.getItem("accountId") });
        } catch (error) {
          toastHandler.error(error);
          console.log({ error: error, message: "error while fetching access token" });
          sentryUtils.captureFirestoreException('bulkUpdate', error, 'BulkTimesheetEntry');
        }
        setLoading(true)
        getLogData(logsDurationDate) // try to add listner
      } else {
        toastHandler.info("No records to save.")
      }

      // console.log('records to delete', finalArrayToDelete)
      // console.log('records to create', finalArrayToCreate)
      // console.log('records to update', finalArrayToUpdate)
    } else {
      toastHandler.error({"message" : "please remove duplicate task '"+ isDuplicate + "'"})
    }
  };

  const customWeekStartEndFormat = (value) => {

    let startDay = moment(value).startOf("isoWeek").format('D')
    let startWeek = moment(value).startOf("isoWeek").format('MMM')
    let startYear = moment(value).startOf("isoWeek").format('YYYY')

    let endDay = moment(value).endOf("isoWeek").format('D')
    let endWeek = moment(value).endOf("isoWeek").format('MMM')
    let endYear = moment(value).endOf("isoWeek").format('YYYY')

    let day = `${startDay} - ${endDay}`
    let week = `${startWeek} - ${endWeek}`
    let year = `${startYear} - ${endYear}`

    if (startWeek != endWeek) {
      day = `${startDay} ${startWeek} - ${endDay} ${endWeek}`
      week = ''
    } else {
      week = startWeek
    }

    if (startYear != endYear) {
      day = `${startDay} ${startWeek} - ${endDay} ${endWeek} ${endYear}`
      week = ''
      year = ''
    } else {
      year = startYear
    }
    return `${day} ${week} ${year}`
  }

  // const handleTableChange = (pagination, filters, sorter,) => {
  //   setTableParams({
  //     pagination,
  //     filters,
  //     ...sorter
  //   });
  // };

  const changeDuration = (arrowType) => {
    let dt = moment().startOf('isoWeek')
    if (arrowType === 'next') {
      //to select next week
      dt = moment(moment(logsDurationDate).add(1, 'weeks'))
    }
    if (arrowType === 'prev') {
      //to select previous week
      dt = moment(moment(logsDurationDate).subtract(1, 'weeks'))
    }
    setLogsDurationDate(dt)
    updateColHeading(dt)
  }
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [cardHeight, setCardHeight] = useState(0)
  const [ebannerHeight, setEBannerHeight] = useState(0)
  const [rbannerHeight, setRBannerHeight] = useState(0)
  const [tableHeight, setTableHeight] = useState(" ")
  const cardRef = useRef(null);
  const enquiryHeight = useSelector(state => state.tableStyleSlice.enquiryHeight);
  const resubmissionHeight = useSelector(state => state.tableStyleSlice.resubmissionHeight);
  

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      },);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(()=>{
    if (resubmissionHeight) {
      const bannerHeight = resubmissionHeight.offsetHeight;
      setEBannerHeight(bannerHeight)
    }
    if (enquiryHeight) {
      const bannerHeight = enquiryHeight.offsetHeight;
      setRBannerHeight(bannerHeight)
    }
    if (cardRef.current) {
      const height = cardRef.current.offsetHeight;
      setCardHeight(height)
    }
    setTableHeight(`calc(100vh - 90px - ${rbannerHeight}px - ${ebannerHeight}px - ${cardHeight}px)`
    )
  },)

  return (
    <div class="card mb-4">
      <div class="card-header" ref={cardRef}>
        <div className="float-left custom-datepicker">
          <Button type="text" icon={<LeftOutlined />} onClick={() => changeDuration('prev')} title="prev week" />
          <Button type="text" disabled={disabledNextBtn} icon={<RightOutlined />} onClick={() => changeDuration('next')} title="next week" />
          <DatePicker
            picker="week"
            weekStart={1}
            disabledDate={disabledDate}
            // style={{ borderColor: constants.colors.THEME_COLOR, borderRadius: '10px' }}            
            onChange={updateColHeading}
            format={customWeekStartEndFormat}
            value={logsDurationDate}
            size={"large"}
            allowClear={false}
            // suffixIcon={<CalendarFilled style={{ color: constants.colors.THEME_COLOR, 'fontSize': '20px' }} />}
            showToday={true}
          />
          <Button size="small" className="ant-btn-sm shadowed-button" type="link" onClick={() => changeDuration()}> This week</Button>
        </div>
        <div className="float-right">
          <Space direction="horizantal">
            <Link  to="/master/user/timesheet">
              <Button id="single_time_entry" style={{ color: '#1B2F78' }} icon={<FaList />} >
                  &nbsp; Single Time Entry 
              </Button>
            </Link>
            <div className="custom_button">
            <Button
              id="addRow"
              type="default"
              icon={<PlusOutlined />}
              onClick={handleAddRecord}
              title="Add new row"
              block
            >
              {" "} Add row{" "}
            </Button>  
            </div>              
            <Button id="saveButton" type="primary" onClick={handleSave} title="Save all records">
              {" "}  Save {" "}
            </Button>
          </Space>
        </div>
      </div>
      <div className="week-table">
        <Table
          loading={loading}
          // pagination={tableParams.pagination}
          // onChange={handleTableChange}
          dataSource={dataSource}
        scroll={{ x: 1525, y:tableHeight }}
        size={'small'}
        pagination={false}
        columns={defaultColumns}
        summary={records => {
          let sunTotal = '00:00';
          let monTotal = '00:00';
          let tueTotal = '00:00';
          let wedTotal = '00:00';
          let thuTotal = '00:00';
          let friTotal = '00:00';
          let satTotal = '00:00';

          let _totalSunMins = 0
          let _totalMonMins = 0
          let _totalTueMins = 0
          let _totalWedMins = 0
          let _totalThuMins = 0
          let _totalFriMins = 0
          let _totalSatMins = 0
          records.forEach(({ sun, mon, tue, wed, thu, fri, sat }) => {
            if (sun) {
              const _sunTime = sun.split(':')
              _totalSunMins += ((parseInt(_sunTime[0]) * 60) + parseInt(_sunTime[1]))
              const _sunHours = Math.floor(_totalSunMins / 60);
              const _sunMinutes = _totalSunMins % 60;
              sunTotal = String(_sunHours).padStart(2, '0') + ':' + String(_sunMinutes).padStart(2, '0')
            }
            if (mon) {
              const _monTime = mon.split(':')
              _totalMonMins += ((parseInt(_monTime[0]) * 60) + parseInt(_monTime[1]))
              const _monHours = Math.floor(_totalMonMins / 60);
              const _monMinutes = _totalMonMins % 60;
              monTotal = String(_monHours).padStart(2, '0') + ':' + String(_monMinutes).padStart(2, '0')
            }
            if (tue) {
              const _tueTime = tue.split(':')
              _totalTueMins += ((parseInt(_tueTime[0]) * 60) + parseInt(_tueTime[1]))
              const _tueHours = Math.floor(_totalTueMins / 60);
              const _tueMinutes = _totalTueMins % 60;
              tueTotal = String(_tueHours).padStart(2, '0') + ':' + String(_tueMinutes).padStart(2, '0')
            }
            if (wed) {
              const _wedTime = wed.split(':')
              _totalWedMins += ((parseInt(_wedTime[0]) * 60) + parseInt(_wedTime[1]))
              const _wedHours = Math.floor(_totalWedMins / 60);
              const _wedMinutes = _totalWedMins % 60;
              wedTotal = String(_wedHours).padStart(2, '0') + ':' + String(_wedMinutes).padStart(2, '0')
            }
            if (thu) {
              const _thuTime = thu.split(':')
              _totalThuMins += ((parseInt(_thuTime[0]) * 60) + parseInt(_thuTime[1]))
              const _thuHours = Math.floor(_totalThuMins / 60);
              const _thuMinutes = _totalThuMins % 60;
              thuTotal = String(_thuHours).padStart(2, '0') + ':' + String(_thuMinutes).padStart(2, '0')
            }
            if (fri) {
              const _friTime = fri.split(':')
              _totalFriMins += ((parseInt(_friTime[0]) * 60) + parseInt(_friTime[1]))
              const _friHours = Math.floor(_totalFriMins / 60);
              const _friMinutes = _totalFriMins % 60;
              friTotal = String(_friHours).padStart(2, '0') + ':' + String(_friMinutes).padStart(2, '0')
            }
            if (sat) {
              const _satTime = sat.split(':')
              _totalSatMins += ((parseInt(_satTime[0]) * 60) + parseInt(_satTime[1]))
              const _satHours = Math.floor(_totalSatMins / 60);
              const _satMinutes = _totalSatMins % 60;
              satTotal = String(_satHours).padStart(2, '0') + ':' + String(_satMinutes).padStart(2, '0')
            }

          });

          return (
            <>
              <Table.Summary.Row>                  
                {(dataSource.length > 0) && (
                  <>
                    <Table.Summary.Cell align="center">
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{monTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{tueTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{wedTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{thuTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{friTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{satTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center">
                      {" "}
                      <b>{sunTotal}</b>{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="center"> </Table.Summary.Cell>
                    <Table.Summary.Cell align="center"> </Table.Summary.Cell>
                  </>
                )
                }
              </Table.Summary.Row>
            </>
          );
          }}
        >
        </Table>
      </div>
    </div>
  )
}
export default BulkTimesheetEntry
