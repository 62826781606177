import _ from 'lodash';
import React from "react";
import { useDispatch, useSelector } from "react-redux"
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import {
  FaUserClock,
  FaUserFriends,
  FaCheck,
  FaRedoAlt,
  FaList,
  FaCalendarAlt,
  FaPoll,
  FaPollH,
  FaCog,
  FaTasks,
  FaLink
} from "react-icons/fa";
import { TbChartInfographic } from "react-icons/tb";
import Logo from "../../assets/images/Logo.png";
import "./Sidebar.css";
import { useState } from "react";

import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { collection, query, where, and, onSnapshot, or } from "firebase/firestore";

import { useEffect } from "react";
import toastHandler from "../../firebaseService/toastHandler";
import { sidebarMenuClicked, userUpdatedInfo } from "../../store/UserData";
import { constants } from "../../constants";
import sentryUtils from "../../utils/sentryUtils";

import authFunctions from "../../firebaseService/authFunctions";
import { version } from '../../version';

const getInitials = (userName) => {
  const nameArray = userName.trim().split(' ');
  if (nameArray.length === 1) {
    return nameArray[0][0].toUpperCase();
  }
  const initials = nameArray.map(name => name[0].toUpperCase());
  return initials.join('');
}
export const Sidebar = (props) => {
  const {subscriptionStat} = props
  const user = firebase.auth().currentUser;
  let loggedInUser = useSelector(state => state.userSlice.user)
  const role = _.get(loggedInUser, 'userRole') || null;
  const[loggedUserRole, setLoggedUserRole] = useState(role)
  const [isAuthorized, setIsAuthorized] = useState(true);
  const navigate = useNavigate();
  // const [sidebarToggled, setSidebarToggled] = useState(false);

  const [userRole, setUserRole] = useState([])
  const [userName, setUserName] = useState([])
  const [hasTeam, setHasTeam] = useState(true)

  const getAccessTokenFromFirestore = async () => {
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("sessionToken_" + accId + "_" + userId);
    authFunctions.getFirestoreTokenFromSessionToken().then((result) => {
      // set state as logged in in local storage, which to be used for protected routes
      if (result.data === "monday session expired") {
        console.log("token expired");
        // getSessionToken();
      } else {
        const userId = localStorage.getItem("userId");
        localStorage.removeItem("firestoreToken_" + accId + "_" + userId);
        localStorage.setItem("firestoreToken_" + accId + "_" + userId, result.data);
        userLoginwithToken();
      }

    });
  };

  const userLoginwithToken = async () => {
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");

    const firestoreToken = localStorage.getItem("firestoreToken_" + accId + "_" + userId);
    const loggedInCurrentUser = await firebase
      .auth()
      .signInWithCustomToken(firestoreToken)
      .then((currentUser) => {
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        if (errorCode === "auth/invalid-custom-token") {
          localStorage.removeItem("authenticated_" + accId + "_" + userId);
        } else {
          console.error(error);
          sentryUtils.captureFirestoreException('userLoginwithToken', error, 'auth');
        }
      });
  };
  useEffect(() => {
    if(!_.isEmpty(loggedInUser)){
    setLoggedUserRole(loggedInUser.userRole);
    }
  }, [loggedInUser])

  useEffect(() => {
    if (!isAuthorized) {
      console.log("isAuthorized is false");
      navigate("/");
    }
  }, [isAuthorized]);

  useEffect(() => {

    const accountId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    console.log("sidebar loggedinuser ", loggedInUser)
    try {
      const userRef = collection(firestore, "users");

      const q = query(
        userRef,
        and(
          where("userId", "==", userId),
          where("accountId", "==", accountId),
        )
      );
      const userSnapshot = onSnapshot(
        q,
        (querySnapshot) => {
          let userEntries = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            userEntries.push(data);
          });
          
          if (userEntries.length > 0 && userEntries[0].isAuthorized) {
            // setUserRole(userEntries[0]['userRole']);
            setUserName(userEntries[0]['userName']);
            updateTeamCount(userEntries[0]).then(response => {
              console.log("hasTeam", hasTeam)
              userEntries[0]['hasTeam'] = hasTeam
              dispatch(userUpdatedInfo(userEntries[0]))
            })
            if(loggedUserRole != userEntries[0]['userRole']){
              console.log("loggedUserRole", loggedUserRole)
              setUserRole(userEntries[0]['userRole']);
              getAccessTokenFromFirestore();
            }
          } else {
            console.log("No user found")
            localStorage.removeItem(
              "firestoreToken_" + accountId + "_" + userId
            );
            localStorage.removeItem(
              "authenticated_" + accountId + "_" + userId
            );
            setIsAuthorized(false);
            
          }
          // console.log("inside userSnapshot");
        },
        (error) => {
          console.log("inside userSnapshot", error.message);
          toastHandler.error("error while fetching fetch user details");
        }
      );

      return () => {
        console.log("componentWillUnmount");
        userSnapshot();
      };
    } catch (error) {
      console.log({
        error: error,
        message: "error while fetching fetch user entries",
      });
      toastHandler.error("error while fetching fetch user details");
      sentryUtils.captureFirestoreException('fetchUser', error, 'Sidebar');
    }
  }, [hasTeam, loggedUserRole]);

  // useEffect(()=>{
  //   setCurrUser(loggedInUser);
  // }, [loggedInUser]);
  const updateTeamCount = async (loggedInUser) => {
    console.log("Inside update team count")
    console.log("logged in use", loggedInUser)

    // if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userId !== undefined) {

    const teamsRef = collection(firestore, "teams");
    let q = query(
      teamsRef,
      and(
        where("accountId", "==", localStorage.getItem("accountId")),
        where("isDeleted", "==", false),
        or(
          where("projectManager.userId", "==", loggedInUser.userId),
          where("teamCreator.userId", "==", loggedInUser.userId),
          where("lead.userId", "==", loggedInUser.userId),
          where("teamMemberIds", "array-contains", loggedInUser.userId)
        )
      )
    );
    if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
      q = query(
        teamsRef,
        and(
          where("accountId", "==", localStorage.getItem("accountId")),
          where("isDeleted", "==", false),
        )
      );
    }
    const teamsSnapshot = onSnapshot(
      q,
      (querySnapshot) => {
        console.log('querySnapshot', querySnapshot.size)
        if (querySnapshot.size === 0) {
          setHasTeam(false)
        }
      })

  };

  const dispatch = useDispatch();
  const sidebarMenuClick = (title) => {
    dispatch(sidebarMenuClicked(title))
  }

  // const toggleButtonCss = {
  //   position: 'absolute',
  //   top: '10px',
  //   left: '203px',
  //   'z-index': '1',
  //   backgroundColor: '#717d94',
  // }
  const smallSidebar = props.navStyle.includes('toggled');
  const iconSize = props.navStyle.includes('toggled') ? 20 : 14;
  const currentRoute = useLocation().pathname.toLowerCase();
  const isAdminMenuSelected = (currentRoute.includes("reports") || currentRoute.includes("summary") || currentRoute.includes("user-summary") || currentRoute.includes("settings"))
  return (
    <>
      {/* <!-- Sidebar --> */}

      <ul
        // className={props.navStyle}
        className={`${props.navStyle} ${props.navStyle.includes('toggled') ? 'sidebar-toggled' : ''}`}
        id="accordionSidebar"
        style={{ background: "#181C33", borderColor: '#181C33', 'height': 'auto', 'borderRadius': '0px', padding: "0px 10px" }}

      // style={{ background: "#181C33", borderColor: '#181C33', 'height': '100vh', 'borderRadius': '0px', 'overflow-y': 'scroll' }}
      >
        <div className="sidebar-brand-icon d-flex flex-column">
          <span className="text-center">
            <img
              src={Logo}
              alt="timerely"
              style={{
                height: "100%",
                width: "90%",
              }}
            />
          </span>
        </div>

        <hr className="sidebar-divider d-none d-md-block" />
        <li
          id='myTimesheet'
          className="nav-item"
          onClick={() => sidebarMenuClick('My Timesheet')}
        >
          <NavLink className="nav-link" to="bulktimesheetentry">
            <FaUserClock size={iconSize} />&nbsp;&nbsp;
            {smallSidebar ? null : <span>My Timesheet</span>}
          </NavLink>
        </li>

        <li
          className="nav-item"
          onClick={() => sidebarMenuClick('Approvals')}
        >
          <NavLink className="nav-link" to="approvalrequests">
            <FaTasks size={iconSize} />&nbsp;&nbsp;
            {smallSidebar ? null : <span>Approvals</span>}
          </NavLink>
        </li>

        <li
          className="nav-item"
          onClick={() => sidebarMenuClick('Report')}
        >
          <NavLink className="nav-link" to="report">
            <TbChartInfographic size={iconSize} />&nbsp;&nbsp;
            {smallSidebar ? null : <span>Report</span>}
          </NavLink>
        </li>

        <li
          id='setting'
          className="nav-item"
          onClick={() => sidebarMenuClick('allsetting')}
        >
          <NavLink className="nav-link" to="allsetting">
            <FaCog size={iconSize} />&nbsp;&nbsp;
            {smallSidebar ? null : <span>Setting</span>}
          </NavLink>
        </li>

        {/* {(userRole === constants.usersRole.ADMIN || userRole === constants.usersRole.SUPER_ADMIN) && (
          <>
            <li
              className="nav-item"
              onClick={() => sidebarMenuClick('Timesheet')}
            >
              <NavLink className="nav-link" to="/master/timesheet">
                <FaCalendarAlt />&nbsp;&nbsp;
                <span>Timesheet</span>
              </NavLink>
            </li>
          </>
        )} */}



        {/* <hr className="sidebar-divider d-none d-md-block" style={{ margin: '0 1rem 0rem' }} /> */}
        {/* <li className={'nav-item'} >
          <a href="https://apprely.com/timerely-guide/" target="_blank" className="nav-link">
            <FaLink />&nbsp;&nbsp;<span>User Guide</span>
          </a>
        </li> */}
        <hr className="sidebar-divider d-none d-md-block" />

        <li className={'nav-item custom-nav'}>
          <div className="nav-link" style={{
            display: 'flex', flexDirection: 'column', borderRadius: "10px"
          }}>
            {smallSidebar ? (<div className={'circle'}>{getInitials(userName)}</div>) : (<><span class="user-role">{userRole}</span>
              <span class="user-name" style={{ paddingBottom: "2.5px" }}>{userName}</span></>)}

            {/* <hr className="sidebar-divider d-none d-md-block" />    */}
            <span class="version">version {version}</span>
          </div>
        </li>


        <hr className="sidebar-divider d-none d-md-block" style={{ marginTop: '1rem' }} />
        {/* <!-- Sidebar - Brand --> */}
        <div className="text-center d-none d-md-inline">
          <button onClick={props.sidebarHandler} className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
      </ul >
    </>
  );
};
