import React from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InfoCircleOutlined } from '@ant-design/icons';

const commonToastProperties = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

const infoToastProperties = {
  duration: 5,
  icon: <InfoCircleOutlined style={{ color: '#2143BC', fontSize: '20px'}} />,   
};

const toastHandler = {
  info: (message) => {

    toast.info(message, {
      ...commonToastProperties,
      ...infoToastProperties,
    });
  },

  warn: (message) => {

    toast.warn(message, {
      ...commonToastProperties
    });
  },

  success: (message) => {

    toast.success(message, {
      ...commonToastProperties,
      theme: "light",
    });
  },

  loading: (message, toastId) => {
    toast.loading(message, {
      ...commonToastProperties,
      toastId: toastId,
    });
  },

  update: (response, toastId, type) => {
    let theme = 'light'
    if (type === 'error') {
      theme = 'dark'
    }
    toast.update(toastId, {
      ...commonToastProperties,
      render: response,
      type: type,
      isLoading: false,
      theme: theme,
    })
  },

  error: (error) => {
    // Getting the Error details.
    var message = error.message;
    toast.error(message, {
      ...commonToastProperties,
      theme: "dark",
      autoClose: 4000,
    });
  },

}

export default toastHandler;
