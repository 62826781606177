import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { Alert, Button } from "antd";
import "../../App.css";
import { setResubmissionHeight } from "../../store/TableStyleData";
import { collection, query, where, and, onSnapshot } from "firebase/firestore";
// import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import firestore from "../../firebase";
import mondaySdk from "monday-sdk-js";
import MondayConfig from "../../configs/MondayConfig";
import usersRole from "../../constants/usersRole";

export const BillingBanner = ({maxUserLimit}) => {
  const [showAlert, setShowAlert] = useState(false);
  // const dispatch = useDispatch();
  const bannerHeightRef = useRef(0);
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);

  // const location = useLocation()
  let [totalActiveUsers, setTotalActiveUsers] = useState(0);
  let loggedInUser = useSelector((state) => state.userSlice.user);
  const role = _.get(loggedInUser, "userRole") || null;

  const openMarketplace = () => {
    monday.execute("openPlanSelection");
  };

  useEffect(() => {
    const getAccountPlanInfo = async (code) => {
      const accountId = localStorage.getItem("accountId");
      if (accountId) {
        try {
          const userRef = collection(firestore, "users");
          const userQuery = query(
            userRef,
            and(
              where("accountId", "==", accountId),
              where("isAuthorized", "==", true) 
            )
          );
          const userSnapshot = onSnapshot(userQuery, (querySnapshot) => {
            if (querySnapshot.size === 0) {
              // console.log("querySnapshot size 0");
            } else {
              // console.log("total users",querySnapshot.size)
              totalActiveUsers = querySnapshot.size;
              setTotalActiveUsers(querySnapshot.size);
            }
          });

        } catch (error) {
          console.log("Error while fetching user entries", error);
          return { error };
        }
      }
    };
    getAccountPlanInfo();
  }, []);
  useEffect(() => {
    if (
      totalActiveUsers >= maxUserLimit &&
      maxUserLimit > 0 &&
      (role === usersRole.ADMIN || role === usersRole.SUPER_ADMIN)
    ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [totalActiveUsers, maxUserLimit, role]);

  const TextComponent = () => {
    return (
      <div className="link_resubmission">
        {"You have exhausted your current plan. Please buy more seats."}
        &nbsp;
        <Button type="link" className="primary" onClick={openMarketplace}>
          {"Upgrade Plan"}
        </Button>
      </div>
    );
  };

  return (
    <div className="banner_resubmission" ref={bannerHeightRef}>
      {showAlert && (
        <Alert
          description={<TextComponent />}
          type="warning"
          banner="true"
          showIcon
        />
      )}
    </div>
  );
};
