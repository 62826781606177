import { createSlice } from '@reduxjs/toolkit';


const tableStyleSlice = createSlice({
  name: 'tableStyle',
  initialState: {
    enquiryHeight: 0,
    resubmissionHeight: 0,
    enquiryClose:false
  },
  reducers: {
    setEnquiryHeight: (state, action) => {
      state.enquiryHeight = action.payload;
    },
    setResubmissionHeight:(state, action) => {
      state.resubmissionHeight = action.payload;
    },
    setEnquiryClose:(state, action) => {
      state.enquiryClose = action.payload;
    },
  },
});

export const { setEnquiryHeight, setResubmissionHeight, setEnquiryClose } = tableStyleSlice.actions;

export default tableStyleSlice.reducer;
