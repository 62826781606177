import React, { useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import moment from "moment";
import { Card, Row, Col, Button, Spin, Alert } from "antd";
import MondayConfig from "../../configs/MondayConfig";
import "firebase/compat/auth";
import subscriptions from "../../firebaseService/subscriptionFunction";
import "./subscription.css";

const getRemainingDays = (renewalDate) => {
  const currentDate = moment().startOf("day");
  const endDate = moment(renewalDate).startOf("day");
  const remainingDays = endDate.diff(currentDate, "days");
  return remainingDays;
};
const Subscription = () => {
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [remainingDays, setRemainingDays] = useState(0);
  const [loading, setLoading] = useState(true);
  const openMarketplace = () => {
    monday.execute("openPlanSelection");
  };

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const res = await subscriptions.getSubscriptionDetails();
        setSubscriptionDetails(res);
      } catch (error) {
        console.error("Failed to fetch subscription details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, []);

  useEffect(() => {
    if (subscriptionDetails?.isTrial) {
      let days = getRemainingDays(subscriptionDetails?.renewalDate);
      setRemainingDays(days);
    }
  }, [subscriptionDetails]);

  return (
    <Card className="current-plan-card" title="Current Plan">
      {loading ? (
        <div className="spinner-container">
          <Spin size="small" />
        </div>
      ) : (
        <>
          {!subscriptionDetails?.isTrial && ( <Row className="content-row">
            <Col span={8} className="div-row">
              <span className="label">Total Seats</span>
              <span className="value">
                Upto {subscriptionDetails?.totalSeats} actual users
              </span>
            </Col>

            <Col span={10} className="div-row">
              {subscriptionDetails?.remainingSeats != 0 && (
                <>
                  <span className="label">Remaining Seats</span>
                  <span className="value">
                    {subscriptionDetails?.remainingSeats}
                  </span>
                </>
              )}

              {subscriptionDetails?.remainingSeats == 0 && (
                <>
                  <Alert
                    description="No remaining seats"
                    type="warning"
                    showIcon
                  />
                </>
              )}
            </Col>
            <Col span={8} className="div-row"></Col>
          </Row>)}
          <Row>
            <Col span={8} className="div-row">
              {subscriptionDetails?.isTrial ? (
                <>
                  <span className="label">Trial Subscription</span>
                  <span className="value">
                    Trial ends
                    {remainingDays == 0
                      ? " today"
                      : ` in ${remainingDays} day${
                          remainingDays > 1 ? "s" : ""
                        }`}
                  </span>
                </>
              ) : (
                <>
                  <span className="label">Billing Cycle</span>
                  <span className="value">
                    {subscriptionDetails?.billingCycle}
                  </span>
                </>
              )}
            </Col>
            <Col span={8} className="div-row">
              <span className="label">
                {subscriptionDetails?.isTrial ? "Expiry Date" : "Renewal Date"}
              </span>
              <span className="value">
                {moment(subscriptionDetails?.renewalDate).format("DD MMM YYYY")}
              </span>
            </Col>
            <Col span={8} className="div-row button-row">
              <Button
                id="createTeam"
                type="primary"
                onClick={() => openMarketplace()}
              >
                Upgrade Plan &nbsp;
                <i class="fas fa-chevron-right fa-sm"></i>
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default Subscription;
