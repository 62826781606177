import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Input, Row } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

const InputComponent = (props) => {
    const { placeholder, data, form, busy, field, cb, error, value } = props;
    const { name } = field;
    return (
        <div>
            <Row>
                <Input
                    name="teamName"
                    style={{ width: '100%' }}
                    value={value}
                    onChange={(e) => { form.setFieldValue(name, e.target.value) }}
                    // value={form.getFieldValue('teamName')}
                    // prefix={<TeamOutlined className="site-form-item-icon" />}
                    placeholder={placeholder}
                />
            </Row>
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div>
    )
};

export default InputComponent;
