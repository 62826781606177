const colors = {
    // HOLIDAY_BG : "#f9b7c024", 
    // HOLIDAY_BG : "#F8CBAF",
    HOLIDAY_BG: "#fdf0e8",
    HOLIDAY_TEXT : "#003300",
    CARD_BORDER : "#E9E5E5",
    THEME_COLOR : '#181C33',      
}

export default colors;

