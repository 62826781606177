import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { Button, Spin } from "antd";
import toastHandler from "../../firebaseService/toastHandler";
import BoardInfo from "../BoardInfo";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import fetchMondayAccount from '../../utils/commonUtils'
import settingFunctions from "../../firebaseService/settingFunctions";
import {
  FaUsersCog,
  FaProjectDiagram,
  FaObjectGroup,
  FaNewspaper,
  FaEdit,
} from "react-icons/fa";
import sentryUtils from '../../utils/sentryUtils';

const Settings = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(true);
  const [type, setType] = useState('');
  const [projectSettings, setProjectSettings] = useState({});
  const [usersSettings, setUsersSetting] = useState({});
  const [busy, setBusy] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };

  const getAccountInfo = async () => {
    setBusy(true);
    let result = await fetchMondayAccount()
    let projectInfo = _.get(result, 'projectSummarySettings') || {};
    setProjectSettings(projectInfo);
    let userInfo = _.get(result, 'usersSummarySettings') || {};
    setUsersSetting(userInfo)
    setBusy(false);
  }

  const openSettingForm = (type) => {
    setOpen(true)
    if (type === 'projects') {
      setType('projects')
      setTitle('Project summary settings')
    }
    if (type === 'users') {
      setType('users')
      setTitle('Users summary settings')
    }
  }

  const saveSetting = async (values) => {
    try {

      let db = firebase.firestore();
      let msg = 'Setting saved';
      if (type === 'projects') {
        msg = 'Projects settings updated successfully.'
      }
      if (type === 'users') {
        msg = 'Users settings updated successfully.'
      }

      db.collection("mondayAccounts").where("accountId", "==", localStorage.getItem("accountId")).limit(1).get().then(query => {
        const account = query.docs[0];
        if (account) {
          let tmp = account.data();
          if (type === 'projects') {
            tmp.projectSummarySettings = { workspace: values.workspace, board: values.project }
            // console.log(values.project.key)
            let data = {boardId : values.project.key}
            console.log('data',data)
            settingFunctions.updateProjectSetting(data)
            setProjectSettings(tmp.projectSummarySettings)
          }
          if (type === 'users') {
            tmp.usersSummarySettings = { workspace: values.workspace, board: values.project }
            // console.log(values.project.key)
            let data = {boardId : values.project.key}
            console.log('data',data)
            settingFunctions.updateUserSetting(data)
            setUsersSetting(tmp.usersSummarySettings)
          }
          account.ref.update(tmp);
          toastHandler.success(msg);
        } else {
          toastHandler.info('Account not available.')
        }
        setOpen(false)
      }).catch((error) => { 
        toastHandler.error(error);
        sentryUtils.captureFirestoreException('saveSetting', error, 'settings');
      });

    } catch (error) {
      toastHandler.error('Error while updating settings.')
      sentryUtils.captureGenericException('saveSetting', error, 'settings');
    }
  }

  useEffect(() => {
    getAccountInfo();
  }, [])

  return (
    <div class="card mb-4">
      <div class="card-header py-3">
      </div>
      {busy ? <Spin></Spin> : <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                <FaProjectDiagram /> &nbsp;
                Projects Summary Settings
                <div className="float-right">
                  <Button type="dashed" icon={<FaEdit />} onClick={() => (openSettingForm('projects'))} />
                </div>
              </h5>
            </div>
            <div class="card-body">
              <strong>
                <FaObjectGroup /> &nbsp;
                Workspace</strong>
              <p class="text-muted">{projectSettings?.workspace?.label || '-'}</p>
              <hr />
              <strong><FaNewspaper />&nbsp; Board</strong>
              <p class="text-muted">{projectSettings?.board?.label || '-'}</p>
              <hr />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                <FaUsersCog /> &nbsp;
                Users Summary Settings
                <div className="float-right">
                  <Button type="dashed" icon={<FaEdit />} onClick={() => (openSettingForm('users'))} />
                </div>
              </h5>
            </div>
            <div class="card-body">
              <strong>
                <FaObjectGroup /> &nbsp;
                Workspace</strong>
              <p class="text-muted">{usersSettings?.workspace?.label || '-'}</p>
              <hr />
              <strong><FaNewspaper />&nbsp; Board</strong>
              <p class="text-muted">{usersSettings?.board?.label || '-'}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>}

      {open ? <BoardInfo
        open={open}
        projectSettings={projectSettings}
        title={title}
        type={type}
        handleCancel={handleCancel}
        saveSetting={saveSetting}
        usersSettings={usersSettings}
      /> : null}
      <br />
    </div>
  );
}
export default Settings;