const width = {
    dateColumnWidth: 120,
    userColumnWidth: 200,
    teamColumnWidth: 200,
    projectColumnWidth: 180,
    taskColumnWidth: 180,
    descriptionClomnWidth: 110,
    hoursColumnWidth: 110,
    statusColumnWidth: 130,
    billableColumnWidth: 90,
    actionColumnWidth: 120,
    commentColumnWidth: 130,
    reasonColumnWidth: 200,
    teamNameColumnWidth: 100,
    smallColumnWidth: 70,
}

export default width;

