import React from 'react';

const ThankYou = () => {
    return (
        <>
            <div class="container" style={{width:'100%' , maxWidth:'100%', height:'100vh' }}>
                <div class="container-fluid" >
                    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                        <div class="text-center">
                            <p class="lead mb-3"> <b> Thank you for your enquiry! </b></p>
                            <p class="mb-0">We will get back to you soon</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
}

export default ThankYou;
