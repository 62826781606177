import { getFunctions, httpsCallable } from "firebase/functions";
import toastHandler from "./toastHandler";
import {functions} from "../firebase";
import envUtils from "../utils/envUtils";
import sentryUtils from "../utils/sentryUtils";

const settingFunctions = {
  updateProjectSetting: async (data) => {
    let toastId = 'processingProjectSettings'
    toastHandler.loading("Updating columns on selected board...", toastId)
    console.log('Processing updateProjectSetting Called')

    let resData = httpsCallable(functions, "api/checkColumnsForProjectSummary");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/checkColumnsForProjectSummary");
    }
    resData(data)
      .then((result) => {
        console.log('updateProjectSetting Called')
        // Read result of the Cloud Function.
        const data = result.data;
        if(!data.error) {
          toastHandler.update(data, toastId, 'success')
        } else {
          const error = data.error;
          toastHandler.update(error, toastId, 'error')
        }
      })
      .catch((error) => {
        toastHandler.update(error, toastId, 'error')
        sentryUtils.captureAPIException('updateProjectSetting', error, "settingFunctions")
      });
  },
  updateUserSetting: async (data) => {
    let toastId = 'processingUserSettings'
    toastHandler.loading("Updating columns on selected board...", toastId)

    console.log('Processing updateUserSetting Called')
    let resData = httpsCallable(functions, "api/checkColumnsForUserSummary");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/checkColumnsForUserSummary");
    }
    resData(data)
      .then((result) => {
        // Read result of the Cloud Function.
        console.log('updateUserSetting is Called')
        const data = result.data;
        if(!data.error) {
          toastHandler.update(data, toastId, 'success')
        } else {
          const error = data.error;
          toastHandler.update(error, toastId, 'error')
        }
      })
      .catch((error) => {
        toastHandler.update(error, toastId, 'error')
        sentryUtils.captureAPIException('updateUserSetting', error, "settingFunctions")
      });
  },
};

export default settingFunctions;