import { getFunctions, httpsCallable } from "firebase/functions";
import toastHandler from "./toastHandler";
import {functions} from "../firebase";
import envUtils from "../utils/envUtils";
import sentryUtils from "../utils/sentryUtils";

const reports = {
  customReportsData: async (data) => {
    console.log(data);
    let resData = httpsCallable(functions, "api/getCustomReportData");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getCustomReportData");
    }
    const timeEntries = await resData(data).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('customReportsData', error, "reportFunctions")
    });

    return timeEntries;
  },

  updateSummaryDoc: async (year) => {
    let resData = httpsCallable(functions, "api/updateProjectSummaryDoc");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateProjectSummaryDoc");
    }
    const timeEntries = await resData({
      year: year,
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateSummaryDoc', error, "reportFunctions")
    });

    return timeEntries;
  },

  updateMondaySummaryBoard: async (year) => {
    let resData = httpsCallable(functions, "api/updateMondaySummaryBoard");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateMondaySummaryBoard");
    }
    const timeEntries = await resData({
      year: year,
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateMondaySummaryBoard', error, "reportFunctions")
    });

    return timeEntries;
  },
  updateMondayUserSummaryBoard: async (month_start, month_end) => {
    let resData = httpsCallable(functions, "api/updateMondayUsersSummaryBoard");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateMondayUsersSummaryBoard");
    }
    const timeEntries = await resData({
      // start: month_start,
      month: month_end
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateMondayUserSummaryBoard', error, "reportFunctions")
    });

    return timeEntries;
  },
  updateUserSummaryDoc: async (month_start, month_end) => {
    let resData = httpsCallable(functions, "api/updateUsersSummaryDoc");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateUsersSummaryDoc");
    }
    const timeEntries = await resData({
      start: month_start,
      end: month_end
    }).catch((error) => {
      toastHandler.error(error);
      sentryUtils.captureAPIException('updateUserSummaryDoc', error, "reportFunctions")
    });

    return timeEntries;
  },

};

export default reports;
