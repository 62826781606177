import React, { useEffect, useState } from "react";
import "./App.css";
import "monday-ui-react-core/dist/main.css";
import Navigator from "./navigator/index.js";
import store from './store/reducer'
import { Provider } from 'react-redux'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import envUtils from "./utils/envUtils.js";
import * as Sentry from "@sentry/react";

export default function App() {
  let isProd = false
  if (envUtils.isProfileMatchesWithValue("prod")) {
    isProd = true
  }
  const environment = isProd ? 'production' : 'testing';

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_URL,
    environment,
    integrations: [
      // Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  return (
    <>
      <Provider store={store}>
        <React.StrictMode>
          <Navigator />
        </React.StrictMode>
      </Provider>
      <ToastContainer/>
    </>
  );
  
}
