import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from "react-redux"

export const ViewerOnly = () => {
    const user = useSelector(state => state.user)

    return (
        <>
            <div class="container">
                <div class="container-fluid" >
                    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                        <div class="text-center">
                            <p class="lead mb-5"> <b> Access Denied! </b></p>
                            <p class="mb-0">As you have a viewer role on monday.com , you are unable to use the "TimeRely" app.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
