import { getFunctions, httpsCallable } from "firebase/functions";
import envUtils from "../utils/envUtils";
import toastHandler from "./toastHandler";
import {functions} from "../firebase";
import sentryUtils from "../utils/sentryUtils";

const authFunctions = {
  getAccessTokenFromFirestore: async () => {
    console.log("Inside auth services");
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    // console.log(localStorage.getItem("sessionToken"));
    let resData = httpsCallable(functions, "api/getAccessTokenFromFirestore");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getAccessTokenFromFirestore");
    }
    const accessToken = await resData({
      sessionToken: localStorage.getItem("sessionToken_"+ accId + "_" + userId ),
      mondayApp: envUtils.getCurrentMondayApp(),
    }).catch((error) => {
      console.error(error);
      toastHandler.error(error);
      sentryUtils.captureAPIException('getAccessTokenFromFirestore', error, "authFunctions")
    });
    return accessToken;
  },

  getFirestoreTokenFromSessionToken: async () => {
    console.log("Inside getFirestoreTokenFromSessionToken");
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    let resData = httpsCallable(functions, "api/getFirebaseToken");
    
    // when connected to emulator add "mondaybaseurl/" at all functions url & use basic getFunctions()
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getFirebaseToken");
    }
    const firestoreToken = await resData({
      sessionToken: localStorage.getItem("sessionToken_"+ accId + "_" + userId),
      mondayApp: envUtils.getCurrentMondayApp(),
    }).catch((error) => {
      console.error(error);
      toastHandler.error(error);
      sentryUtils.captureAPIException('getFirestoreTokenFromSessionToken', error, "authFunctions")
    });
    // console.log(firestoreToken);
    return firestoreToken;
  },

  getAccessTokenFromMonday: async(code) =>{
    console.log("Inside getAccessTokenFromCode ");
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    let resData = httpsCallable(functions, "api/getAccessTokenFromMonday");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/getAccessTokenFromMonday");
    }
    let redirect_uri = "http://localhost:8301/oauth/callback";
    if(envUtils.getCurrentMondayApp() === "Demo"){
      redirect_uri = "https://timesheet-398213.web.app/oauth/callback";
    }
    if(envUtils.getCurrentMondayApp() === "MondayProd"){
      redirect_uri = "https://time.apprely.com/oauth/callback"
    }
    
    const accessToken = await resData({
      code: code,
      sessionToken: localStorage.getItem("sessionToken_" + accId + "_" + userId),
      mondayApp: envUtils.getCurrentMondayApp(),
      redirect_uri : redirect_uri,
    }).catch((error) => {
      console.error(error);
      toastHandler.error(error);
      sentryUtils.captureAPIException('getAccessTokenFromMonday', error, "authFunctions")
    });
    return accessToken;
  },

};



export default authFunctions;