import envUtils from "../utils/envUtils";

let isProd = false
if (envUtils.isProfileMatchesWithValue("prod")) {
  isProd = true
}
const APIKEY = isProd ? process.env.REACT_APP_API_KEYS : process.env.REACT_APP_TEST_API_KEYS;
const AUTHDOMAIN = isProd ? process.env.REACT_APP_AUTHDOMAIN : process.env.REACT_APP_TEST_AUTHDOMAIN;
const PROJECT_ID = isProd ? process.env.REACT_APP_PROJECT_ID : process.env.REACT_APP_TEST_PROJECT_ID;
const STORAGE_BUCKET = isProd ? process.env.REACT_APP_STORAGE_BUCKET : process.env.REACT_APP_TEST_STORAGE_BUCKET;
const MESSAGING_SENDER_ID = isProd ? process.env.REACT_APP_MESSAGING_SENDER_ID : process.env.REACT_APP_TEST_MESSAGING_SENDER_ID;
const APP_ID = isProd ? process.env.REACT_APP_APP_ID : process.env.REACT_APP_TEST_APP_ID;
const MEASUREMENT_ID = isProd ? process.env.REACT_APP_MEASUREMENT_ID : process.env.REACT_APP_TEST_MEASUREMENT_ID;

console.log("store",APIKEY,AUTHDOMAIN,PROJECT_ID,STORAGE_BUCKET,MEASUREMENT_ID)

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const FirebaseConfig = {
  apiKey: APIKEY,
  authDomain: AUTHDOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};

export default FirebaseConfig;
