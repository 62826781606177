import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import envUtils from "./utils/envUtils";
import { initializeApp ,getApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import FirebaseConfig from "./configs/FirebaseConfig";
import { getAnalytics} from "firebase/analytics";

const firebaseApp = firebase.initializeApp(FirebaseConfig);
// let firestore = firebaseApp.firestore();
// Initialize Firestore
const firestore = getFirestore(firebaseApp);

let functions = getFunctions(firebaseApp, "https://us-central1-timesheet-398213.cloudfunctions.net/mondaybaseurl");
let isProd = false
if (envUtils.isProfileMatchesWithValue("prod")) {
  isProd = true
  functions = getFunctions(firebaseApp, "https://time.apprely.com");
}

let analytics;

try {
//  analytics = getAnalytics(firebaseApp,{ enabled: true });
  analytics = getAnalytics(firebaseApp);
  // !isProd && analytics.setDebugMode(true);
  // isDebug && analytics.setDebugMode(true);

}catch (error) {
  console.error("Error initializing Firebase Analytics:", error);
}

if (envUtils.isProfileMatchesWithValue("emulator")) {
  // firestore.useEmulator("localhost", 8080);
  firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  const functionss = getFunctions(getApp());
  connectFunctionsEmulator(functionss, "localhost", 5001);
  const auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099");
}

export {firebaseApp, firestore,functions,analytics}; //
export default firebaseApp.firestore();

