import { getFunctions, httpsCallable, httpsCallableFromURL } from "firebase/functions";
import toastHandler from "./toastHandler";
import { functions } from "../firebase";
import envUtils from "../utils/envUtils";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { analytics } from "../firebase";
import { logEvent } from "@firebase/analytics";
import sentryUtils from "../utils/sentryUtils";

const teamFunctions = {
  createTeam: async (createTeam,teamName) => {
    let toastId = 'createTeamToast'
    toastHandler.loading("Creating team...", toastId)

    let resData = httpsCallable(functions,  "api/createTeam");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(),  "mondaybaseurl/api/createTeam");
    }
    resData(createTeam)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        console.log("Team name in function",teamName)
        if(!data.error) {
          toastHandler.update(data, toastId, 'success')
          logEvent(analytics, 'create_team', { "account_id": localStorage.getItem("accountId") });
        } else {
          const error = data.error;
          toastHandler.update(error, toastId, 'error')
          sentryUtils.captureAPIMessageException('createTeam', error, "teamFunctions")
        }
      })
      .catch((error) => {
        toastHandler.update(error, toastId, 'error')
        sentryUtils.captureAPIException('createTeam', error, "teamFunctions")
      });
  },

  updateTeam: async (updateTeam,teamName) => {
    let toastId = 'updateTeamToast'
    toastHandler.loading("Updating team ...", toastId)
    let resData = httpsCallable(functions, "api/updateTeam");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(),  "mondaybaseurl/api/updateTeam");
    }
    resData(updateTeam)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        if(!data.error) {
          toastHandler.update(data, toastId, 'success')
          logEvent(analytics, 'update_team', { "account_id": localStorage.getItem("accountId") });
        } else {
          const error = data.error;
          toastHandler.update(error, toastId, 'error')
          sentryUtils.captureAPIMessageException('updateTeam', error, "teamFunctions")
        }
      })
      .catch((error) => {
        console.log(error)
        toastHandler.update(error, toastId, 'error')
        sentryUtils.captureAPIException('updateTeam', error, "teamFunctions")
      });
  },

  deleteTeam: async (deleteTeam,teamName) => {
    let toastId = 'deleteTeamToast'
    toastHandler.loading("Deleting team...", toastId)
    
    let resData = httpsCallable(functions, "api/deleteTeam");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(),  "mondaybaseurl/api/deleteTeam");
    }
    resData(deleteTeam)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        console.log(data);
        if(!data.error) {
          toastHandler.update(data, toastId, 'success')
          logEvent(analytics, 'delete_team', { "account_id": localStorage.getItem("accountId") });
        } else {
          const error = data.error;
          toastHandler.update(error, toastId, 'error')
          sentryUtils.captureAPIMessageException('deleteTeam', error, "teamFunctions")
        }
      })
      .catch((error) => {
        console.log(error)
        toastHandler.update(error, toastId, 'error')
        sentryUtils.captureAPIException('delete_team', error, "teamFunctions")
      });
  },

// This function is not used, so not updated for custom domain
  // getMyProjectsForApprovalList: async () => {
  //   const functions = getFunctions();
  //   const resData = httpsCallable(functions, "getMyProjectsList");
  //   var projectIds = []
  //   projectIds = resData({}).then((result) => {
  //     // Read result of the Cloud Function.
  //     const data = result.data;
  //     return data;
  //   })
  //     .catch((error) => {
  //       toastHandler.error(error);
  //     });
  //   return projectIds;
  // },
};

export default teamFunctions;