import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Row, message } from 'antd';
import mondaySdk from "monday-sdk-js";
import toastHandler from '../../../firebaseService/toastHandler';
import MondayConfig from '../../../configs/MondayConfig';
import sentryUtils from '../../../utils/sentryUtils';

const SelectDoneStatusLabel = (props) => {
    const { placeholder, data, form, field, cb, error, value, disabled, selectedProject, statusId, setFieldValue } = props;
    const { name } = field;
    const monday = mondaySdk();
    monday.setApiVersion(MondayConfig.MONDAY_API_VERSION)
    const [statusValues, setStatusValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [busy, setBusy] = useState(false);
    const [isFirst, setIsFirst] = useState(true)

    const fetchColumn = async () => {
        console.log('fetchColumn called')
        if (
            selectedProject !== null &&
            selectedProject !== '' &&
            selectedProject.key !== undefined
        ) {
            setBusy(true);

            let query = `query {
                boards (ids: ` + selectedProject.key + `) {
                  columns(types: status, ids:"`+statusId+`") {
                    id
                    title
                    settings_str
                  }		
                }
              }`;


            const tokendata = await monday.get('sessionToken');
            const response = await monday.api(query, {
                options: { token: tokendata.data },
            }).catch((err) => {
                setBusy(false);
                console.log('error in fetch column --->', err.message);
                toastHandler.error(err.message);
                sentryUtils.captureMondayException('fetchColumn', error, 'selectStatusLabelColumn');
            });
            const settingsStr = response?.data?.boards[0]?.columns[0]?.settings_str;
            const settingsJson = settingsStr ? JSON.parse(settingsStr) : {};
            if(!_.isEmpty(settingsJson)) {
            const labels = Object.entries(settingsJson.labels).map(([key, value]) => ({
                id: key,
                title: value
              }));            
            const columns = [];
            labels.forEach((item) => {
            columns.push({
                key: item.title,
                label: item.title,
                value: item.title,
            });
            });
        
            setStatusValues(columns);
            setBusy(false);
        }else{
            toastHandler.error("Add labels for status  column to your monday board");
        }
        }
    };

    useEffect(() => {
        if (!_.isEmpty(selectedProject) && statusId != 0) {
            fetchColumn();
            setIsFirst(true)
        }
    }, [selectedProject, statusId]);

    useEffect(() => {
        if(name === 'statusColumnLabelName' && statusValues.length === 0){
            if(!isFirst){
                message.error("Add labels for status column to your monday board");
                // toastHandler.error("Add labels for status  column to your monday board");
            } else {
                setIsFirst(false)
            }
        }
        if (statusValues.length > 0 && value) {
            const data = statusValues.filter((item) => item.value === value);
            if(data.length > 0){
                cb(data);
                setSelectedValue(data[0]);
            } else {
                setSelectedValue(null);
                if(value){
                    message.error("Your previouslly set status's label is deleted from monday board");
                }
            }
            
        } else {
            setSelectedValue(null);
        }
    }, [statusValues, value])

    return (
        <div>
            <Row>
                <Select
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    name={name}
                    allowClear
                    onClear={() => setFieldValue("statusColumnLabelName",null)}
                    value={selectedValue}
                    showSearch
                    optionFilterProp='label'
                    onChange={(e) => {
                        const selectedValue = statusValues.filter((item) => item.value === e);
                        cb(selectedValue)
                    }}
                    loading={busy}
                >
                    {statusValues?.map((item) => (
                        <Select.Option key={item.key} value={item.value} label={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div >
    )
};

export default SelectDoneStatusLabel;
