import { getFunctions, httpsCallable } from "firebase/functions";
import toastHandler from "./toastHandler";
import {functions} from "../firebase";
import envUtils from "../utils/envUtils";
import * as Sentry from "@sentry/react";
import { analytics } from "../firebase";
import { logEvent } from "@firebase/analytics";
import sentryUtils from "../utils/sentryUtils";

const users = {
  updateUserRole: async (updatedData) => {
    let toastId = 'processingUpdateUserToast'
    toastHandler.loading("Processing...", toastId)
    
    let resData = httpsCallable(functions, "api/updateUserRole");
    if (envUtils.isProfileMatchesWithValue("emulator") || envUtils.isProfileMatchesWithValue("test")) {
      resData = httpsCallable(getFunctions(), "mondaybaseurl/api/updateUserRole");
    }
    resData(updatedData)
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        if(!data.error) {
          toastHandler.update(data, toastId, 'success')
          logEvent(analytics, 'update_team', { "account_id": localStorage.getItem("accountId") });
        } else {
          const error = data.error;
          toastHandler.update(error, toastId, 'error')
          sentryUtils.captureAPIMessageException('updateTeam', error, "userFunctions")
        }
      })
      .catch((error) => {
        toastHandler.update(error, toastId, 'error')
        sentryUtils.captureAPIException('updateTeam', error, "userFunctions")
      });
  },
};

export default users;