import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Row } from 'antd';
import init from 'monday-sdk-js/src/client';
import toastHandler from '../../../firebaseService/toastHandler';
import sentryUtils from '../../../utils/sentryUtils';

const Selectworkspace = (props) => {
    const { placeholder, data, form, field, cb, error, value, disabled } = props;
    const { name } = field;
    const monday = init();
    const [defaultWorkspace, setDefaultWorkspace] = useState(null);
    const [workSpaceData, setWorkSpaceData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [busy, setWorkSpaceBusy] = useState(false);
    const fetchWorkspaces = async () => {
        setWorkSpaceBusy(true);
        let query = `query {
      me {
        name
        id
      }
      workspaces(limit: 500) {
        id
        name
      }
    }`;
        const tokendata = await monday.get('sessionToken');
        const response = await monday.api(query, {
            options: { token: tokendata.data },
        }).catch((err) => {
            setWorkSpaceBusy(false);
            console.log(err.message);
            toastHandler.error(err.message);
            sentryUtils.captureMondayException('fetchWorkspaces', error, 'selectWorkspace');
        });

        let workSpaceList = response.data.workspaces || [];
        let workspace = [];

        let mainWorkspaceObj = {
            key: null,
            label: defaultWorkspace,
            value: defaultWorkspace,
        }

        workspace.push(mainWorkspaceObj);

        workSpaceList.forEach((item, index) => {
            workspace.push({
                key: item.id,
                label: item.name,
                value: item.id,
            });
        });
        workspace = _.orderBy(workspace, ['label'], ['asc'])
        setWorkSpaceData(workspace);
        setWorkSpaceBusy(false);
    };

    const fetchDefaultWorkspace = async () => {
        setWorkSpaceBusy(true);
        const tokendata = await monday.get('sessionToken');
        let board_query = `query {
            boards(workspace_ids: [null], limit:1 ) {
                workspace{
                    id
                    name
                }
            }
        }`;
        const response = await monday.api(board_query, {
            options: { token: tokendata.data },
        }).catch((err) => {
            setWorkSpaceBusy(false);
            console.log(err.message);
            sentryUtils.captureMondayException('fetchDefaultWorkspace', error, 'board with null id');
        });

        try{
            const defaultWS = response.data?.boards[0]?.workspace?.name
            console.log("default workspace", defaultWS)
            setDefaultWorkspace(defaultWS)
        }catch{
            sentryUtils.captureMondayException('fetchDefaultWorkspace', error, 'could not get default workspace');
        }
        
    };

    useEffect(()=>{
        fetchDefaultWorkspace()
    }, []);

    useEffect(() => {
        if(defaultWorkspace != null && defaultWorkspace !== undefined)
            fetchWorkspaces();
    }, [defaultWorkspace]);

    useEffect(() => {
        if (workSpaceData.length > 0 && value) {
            const data = workSpaceData.filter((item) => item.value.toString() === value.toString());
            cb(data);
            setSelectedValue(data);
        }
        if (disabled && value==null) {
            const main_workspace = [{
                key: null,
                label: defaultWorkspace,
                value: defaultWorkspace,
            }]
            cb(main_workspace);
            setSelectedValue(main_workspace);
        }
    }, [workSpaceData, value])

    return (
        <div>
            <Row>
                <Select
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    name={name}
                    value={selectedValue}
                    showSearch
                    optionFilterProp='label'
                    onChange={(e, val) => {
                        const selectedValue = workSpaceData.filter((item) => item.value === val.value);
                        cb(selectedValue)
                    }}
                    loading={busy}
                >
                    {workSpaceData.map((item) => (
                        <Select.Option key={item.key} value={item.value} label={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div >
    )
};

export default Selectworkspace;
