let commonAccess = [
    '/master/user/timesheet',
    '/master/resubmission',
    '/master/approvalrequests',
    '/master/bulktimesheetentry',
    '/master/teams',
    '/master/report',
    '/master/allsetting',
];
const accessControl = {
    
    commonAccess: commonAccess,

    adminAccess: [
        ...commonAccess,
        '/master/timesheet',
        '/master/reports',
        '/master/summary',
        '/master/user-summary',
        '/master/settings',
    ],
}

export default accessControl;
