import React from 'react';
import { createBrowserRouter, RouterProvider, useParams } from 'react-router-dom';
import Auth from '../components/auth/auth';

import BulkTimesheetEntry from '../components/TimeSheetTableView/BulkTimesheetEntry'
import UserTimeSheet from '../components/TimeSheetTableView/UserTimeSheet'
import TimeSheetTableView from '../components/TimeSheetTableView/TimeSheetTableView'
import RejectedList from '../components/Rejected/RejectedList'
import Teams from '../components/Teams/TeamList'
import ApprovalList from '../components/Approval/ApprovalList'
import CustomReport from '../components/reports/customReport';
import { PageNotFound } from '../components/Layout/PageNotFound'
import { ViewerOnly } from '../components/Layout/ViewerOnly'
import ProtectedRoute from "./ProtectedRoute";
import MondayOauthLoginVerifier from '../components/auth/MondayOauthLoginVerifier'
import { Master } from '../components/Layout/Master';
import Summary from '../components/Summary/Summary';
import UserSummary from '../components/UserSummary';
import Settings from '../components/Settings';
import AllSetting from '../components/AllSetting';
import Report from '../components/AllReports';
import { AccessDenied} from '../components/auth/AccessDenied';
import {UsersLimitReached} from '../components/auth/UsersLimitReached';
import ThankYou from '../components/auth/ThankYou';
import Subscription from '../components/auth/Subscription';

// Wrapper component to extract and pass the route parameter to Subscription
const SubscriptionWrapper = ({ theme }) => {
  return <Subscription theme={theme} />;
};

const Router = () => {
  const router = createBrowserRouter([
    {
      path: '',
      element: <Auth />,
    },
    {
      path: 'viewer-only',
      element: <ViewerOnly />,
    },
    {
      path: 'oauth/callback',
      element: <MondayOauthLoginVerifier />,
    },
    // {
    //   path: 'oauth/callback/AccessDenied',
    //   element: <AccessDenied/>,
    // },
    {
      path:'UsersLimitReached/:status',
      // path:'oauth/callback/UsersLimitReached',
      element: <SubscriptionWrapper theme={"light"} />,
      // element: <UsersLimitReached/>,
    },
    {
      path:'oauth/callback/UsersLimitReached/ThankYou',
      element: <ThankYou/>,
    },
    {
      // path:'oauth/callback/AccessDenied',
      path:'AccessDenied/:status',
      element: <SubscriptionWrapper theme={"light"} />,
    },
    {
      // path:'oauth/callback/AccessDenied',
      path:'SubscriptionExpired/:status',
      element: <SubscriptionWrapper theme={"light"} />,
    },
    {
      // path:'oauth/callback/AccessDenied',
      path:'TrialEnded/:status',
      element: <SubscriptionWrapper theme={"light"} />,
    },
    {
      path: 'master',
      element: <ProtectedRoute> <Master /> </ProtectedRoute>,
      children: [
        {
          path: '*',
          element: <PageNotFound />,
        },
        {
          path: 'timesheet',
          element: <TimeSheetTableView />,
        },
        {
          path: 'user/timesheet',
          element: <UserTimeSheet />,
        },
        {
          path: 'resubmission',
          element: <RejectedList />,
        },
        {
          path: 'approvalrequests',
          element: <ApprovalList />,
        },
        {
          path: 'teams',
          element: <Teams />,
        },
        {
          path: 'allsetting',
          element: <AllSetting />,
        },
        {
          path: 'bulktimesheetentry',
          element: <BulkTimesheetEntry />,
        },
        {
          path: 'report',
          element: <Report />,
        },
        {
          path: 'reports',
          element: <CustomReport />,
        },
        {
          path: 'summary',
          element: <Summary />,
        },
        {
          path: 'user-summary',
          element: <UserSummary />,
        },
        {
          path: 'settings',
          element: <Settings />,
        },
      ]
    },
  ]);

  return <RouterProvider router={router} />
}

export default Router;