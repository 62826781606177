import React, { useState } from "react";
import { Drawer, Card, Typography, Avatar, Space } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import colors from "../../constants/color";

const { Text } = Typography;

function Comment(props) {
  let recordData = props.commentData ? props.commentData : ""

  // console.log(recordData);
  const { open, setOpen } = props;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Drawer
        title="All comments"
        placement="right"
        width={500}
        onClose={onClose}
        open={open}
      >
        {!recordData?.reviewerComments?.length && <Text>No Comment</Text>}
        {recordData.reviewerComments?.map((item, index) => (
          <Card style={{ width: 400, margin: 20, borderRadius: 5, borderBlockWidth: 1, borderColor: colors.CARD_BORDER }}>
            <Space wrap direction="vertical">
              <Space wrap direction="horizonatal" align="center">
                <Avatar style={styles.AvtarStyle} size='medium'>{item.userName?.charAt(0).toUpperCase()}</Avatar>
                <Space direction="vertical" size='10'>
                  <Text strong >
                    {item.userName}
                  </Text>
                  <Text style={{ fontSize: "10px" }}>
                    {moment(item?.logTime?.seconds * 1000).format() !=
                      "Invalid date"
                      ? moment(item?.logTime?.seconds * 1000).format(
                        "MMM DD, YYYY  [ at ]   h:mm a"
                      )
                      : moment(item.logTime).format("MMM DD, YYYY  [ at ]   h:mm a")}
                  </Text>
                </Space>
              </Space>
              <Text bold >{item.comment}</Text>
            </Space>
          </Card>
        ))}
      </Drawer>
    </div>
  )
}
const styles = {
  cardFont: {
    fontFamily: 'Poppins',
    //   letterSpacing: "0em",
    textAlign: 'left',
    fontSize: '10px',
  },
  AvtarStyle: {
    backgroundColor: '#1376E7',
    color: '#FFFFFF'
  }
}

export default Comment;