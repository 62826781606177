import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { PopupButton, PopupModal } from "react-calendly";
import { Button, Tooltip,Dropdown,Menu } from "antd";
import { CalendarOutlined,BulbOutlined,MailOutlined,FileMarkdownOutlined,FileTextFilled,} from '@ant-design/icons';
import { GrGroup } from "react-icons/gr";
import { FaGooglePlay, FaList,FaRegCalendarAlt  } from "react-icons/fa";
import Tour from "reactour";
import { tour_style, form_tour_style, guided_tour, calendly_style,dropdown_style, help_style } from "./TourSettings";
import { constants } from "../../constants";
import { LiaHandPointRightSolid,LiaCalendarWeekSolid  } from "react-icons/lia";
import EnquiryForm from "../Marketing/EnquiryForm";
import { MdMarkEmailRead } from "react-icons/md";
import { BsFileTextFill } from "react-icons/bs";
import { LuFileText } from "react-icons/lu";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import "./GuideTour.css";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";

export const GuidedTour = ({ sidebarStyle }) => {
  // sidebarHandler
  const [isTourOpen1, setIsTourOpen1] = useState(false);
  const [isTourOpen2, setIsTourOpen2] = useState(false);
  const [isTourOpen3, setIsTourOpen3] = useState(false);
  const [optionsVisibility, setOptionsVisibility] = useState(false);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  let loggedInUser = useSelector((state) => state.userSlice.user);
  
  const [isEnquiryOpen, setIsEnquiryOpen] = useState(false);

  const handleOk = () => {
    // Handle OK action in EnquiryForm
    setIsEnquiryOpen(false);
  };

  const handleCancel = () => {
    setIsEnquiryOpen(false);
  };

  const startContactUs = () => {
    setIsEnquiryOpen(true);
  };

  const stepsOptions = [
    {
      content: () => (
        <div >
          <div style= {{color:'#949497',width:'443px', marginBottom: '10px'}}> Begin the guided tour for </div>
          <div className="button-wrapper" >
            <Button
              visibility={optionsVisibility}
              size="small"
              type="link"
              className="custom-button"
              onClick={() => {
                setIsTourOpen1(true);
                setOptionsVisibility(false);
              }}
            >
              <GrGroup className = "custom-image"  /> 
              Create Team
            </Button>
          </div>
          <div className="button-wrapper">
          <Button 
            visibility={optionsVisibility}
            size="small"
            type="link"
            className="custom-button"
            onClick={() => {
              setIsTourOpen2(true);
              setOptionsVisibility(false);
            }}
          >
            <FaRegCalendarAlt className = "custom-image" /> 
            Fill timesheet weekly
          </Button>
          </div>
          <div className="button-wrapper" style={{ marginBottom: '5px' }}>
          <Button
            visibility={optionsVisibility}
            size="small"
            type="link"
            className="custom-button"

            onClick={() => {
              setIsTourOpen3(true);
              setOptionsVisibility(false);
            }}
          >
            {/* <FaList className = "custom-image" /> */}
            <FaList style={{ marginRight: '8px',
                             fontSize: '17px',
                             verticalAlign: 'top'}} />
                
            Fill individual timesheet
          </Button>
          </div>
        </div>
      ),
      style: tour_style,
    },
  ];

  const steps1 = [
    {
      selector: "#setting",
      content: () => (
        <div style={{ marginRight: "20px" }}>
          Go to Settings to set up a team
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#createTeam",
      content: () => (
        <div style={{ marginRight: "20px" }}>Create a team (fill a form)</div>
      ),
      style: tour_style,
    },
    {
      selector: "#workspaceName",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Choose a workspace from the Monday board
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#projectName",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Choose a project (Monday board) within the selected workspace
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#teamButton",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Click Next to proceed!
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#teamName",
      content: () => <div style={{ marginRight: "10px" }}>Enter team name</div>,
      style: tour_style,
    },
    {
      selector: "#assigneeColName",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Select an assignee (people column) for the chosen project
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#actualHours",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Select a number column to update hours spent when timesheet is approved
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#estimatedHours",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Select a number column where you estimate the task hours
        </div>
      ),
      style: tour_style,
    },

    {
      selector: "#teamButton",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Click Next to proceed!
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#pmName",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Choose a project manager from the list of subscribers
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#leadName",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Choose a lead from the list of subscribers
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#teamMember",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Choose members from the list of subscribers
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#teamButton",
      content: () => <div style={{ marginRight: "10px" }}>Just Click !</div>,
      style: tour_style,
    },
    {
      // selector: "#",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Congratulations! Now you can view created team here!
        </div>
      ),
      style: tour_style,
    },
  ];

  const steps2 = [
    {
      content: () => (
        <div style={{ marginRight: "20px" }}>
          This assumes you have an existing team under Setting - Team. If not,
          please begin by selecting "Create Team"
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#myTimesheet",
      content: () => (
        <div style={{ marginRight: "20px" }}>Navigate to "My Timesheet"</div>
      ),
      style: tour_style,
    },
    {
      selector: "#addRow",
      content: () => (
        <div style={{ marginRight: "20px" }}>
          You can add your time entry by clicking "Add row"
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#project",
      content: () => (
        <div style={{ marginRight: "20px" }}>
          Choose a project from your corrosponding team
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#task",
      content: () => (
        <div style={{ marginRight: "20px" }}>Pick a task assigned to you</div>
      ),
      style: tour_style,
    },
    {
      selector: "#timePicker",
      content: () => (
        <div style={{ marginRight: "20px" }}>
          Pick hours you spent on a task
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#badge",
      content: () => (
        <div style={{ marginRight: "20px" }}>
          Click on right corner of time picker to add description
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#description",
      content: () => <div style={{ marginRight: "20px" }}>Add description</div>,
      style: tour_style,
    },
    {
      selector: "#billableCheckbox",
      content: () => (
        <div style={{ marginRight: "20px" }}>Select billable/non-billable</div>
      ),
      style: tour_style,
    },
    {
      selector: "#addUpdateButton",
      content: () => (
        <div style={{ marginRight: "20px" }}>Click on add/update button</div>
      ),
      style: tour_style,
    },

    // {
    //   selector: '#popupIcon',
    //   content: () => (
    //     <div style = {{marginRight: '20px'}}>
    //            Close the right corner of time picker
    //     </div>
    //   ),
    //   style: tour_style
    // },

    {
      selector: "#saveButton",
      content: () => (
        <div style={{ marginRight: "20px" }}>Click Save to add your entry</div>
      ),
      style: tour_style,
    },
  ];

  const steps3 = [
    {
      content: () => (
        <div style={{ marginRight: "20px" }}>
          This assumes you have an existing team under Setting - Team. If not,
          please begin by selecting "Create Team"
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#myTimesheet",
      content: () => (
        <div style={{ marginRight: "20px" }}>Navigate to "My Timesheet"</div>
      ),
      style: tour_style,
    },
    {
      selector: "#single_time_entry",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          click to view list of time entries you added
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#add_time_entry",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Click to fill single time entry
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#timeentry_project",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Choose a project from your teams
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#timeentry_task",
      content: () => (
        <div style={{ marginRight: "10px" }}>Pick a task assigned to you</div>
      ),
      style: tour_style,
    },
    {
      selector: "#timeentry_date",
      content: () => <div style={{ marginRight: "10px" }}>Choose a date</div>,
      style: tour_style,
    },
    {
      selector: "#timeentry_logtime",
      content: () => (
        <div style={{ marginRight: "10px" }}>Pick hours you spent on task</div>
      ),
      style: tour_style,
    },
    {
      selector: "#timeentry_description",
      content: () => (
        <div style={{ marginRight: "10px" }}>Give details of the task</div>
      ),
      style: tour_style,
    },
    {
      selector: "#timeentry_billable",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          keep switch On for billable else off
        </div>
      ),
      style: tour_style,
    },
    {
      selector: "#timeentry_create",
      content: () => (
        <div style={{ marginRight: "10px" }}>
          Just click! Your entry will be created!
        </div>
      ),
      style: tour_style,
    },
  ];

  const steps4 =[
    {
        selector: "#setting",
        content: () => (
          <div style={{ marginRight: "20px" }}>
            Go to Settings to view a team
          </div>
        ),
        style: tour_style,
    },
    {
        content: () => (
          <div style={{ marginRight: "20px" }}>
            As you are a member only, please contact admin to create a team
          </div>
        ),
        style: tour_style,
    },
  ]

  const step = loggedInUser && (loggedInUser.userRole === constants.usersRole.SUPER_ADMIN || loggedInUser.userRole === constants.usersRole.ADMIN) ? steps1 : steps4

  const startTour = () => {
    setOptionsVisibility(true);
  };

  const startCalendly = () => {
    // console.log("Calendar clicked")
    setIsCalendlyOpen(true)
  }

  const iconStyle = {
    marginRight: '8px', // Adjust the margin to add space between icon and text
  };

  const CalendlyBtn = () => {
    const handleMenuClick = (e) => {
      // Perform actions based on the clicked menu item
      // console.log(`Clicked on menu item ${e.key}`);
      if(e.key==='1'){
        window.open('https://apprely.com/timerely-guide/', '_blank', 'noopener noreferrer');
        logEvent(analytics,"manual" , { "account_id": localStorage.getItem("accountId") });
      }
      else if (e.key === '2') {
        startCalendly();
        logEvent(analytics,"schedule_demo" , { "account_id": localStorage.getItem("accountId") });
      }
      else if(e.key=='3'){
        startTour();
        logEvent(analytics,"guide_a_tour" , { "account_id": localStorage.getItem("accountId") });
      }
      else if(e.key === '4'){
        startContactUs();
        logEvent(analytics,"contact_us" , { "account_id": localStorage.getItem("accountId") });
      }
    };
    const items = [
      {
        key: "1",
        onClick:handleMenuClick,
        label: <span style={dropdown_style}> <LuFileText  style={{ color: '#ed8e68', ...iconStyle }}/> Manual </span>,
      },
      {
        key: "2",
        onClick:handleMenuClick,
        label: <span style={dropdown_style}>
          <CalendarOutlined style={{ color: '#a079d9',...iconStyle }}/> Schedule a Demo
          </span>
      },
      {
        key: "3",
        onClick:handleMenuClick,
        label: <span style={dropdown_style}> <BulbOutlined style={{ color: '#008B8B' , ...iconStyle }}/> Guide a Tour </span>,
      },
      {
        key: "4",
        onClick:handleMenuClick,
        label: <span style={dropdown_style} > <MdOutlineMarkEmailRead style={{ color: 'green' ,...iconStyle }}/>  Contact Us </span>,
      },
    ];
    // const menu = (
    //   <Menu onClick={handleMenuClick}>
    //     <Menu.Item key="manual">Manual</Menu.Item>
    //     <Menu.Item key="schedule_demo">Schedule a demo</Menu.Item>
    //     <Menu.Item key="guide">Guide a Tour</Menu.Item>
    //     <Menu.Item key="contact_us">Contact Us</Menu.Item>
    //   </Menu>
    // );
    return (
      // <div className="calendly-button">
      <div className="calendly-button" style={{ left: sidebarStyle.indexOf('toggled') !== -1 ? '120px' : '210px' }}>
          <Dropdown style={calendly_style} menu={{ items }} disabled={false} placement="topLeft" arrow>
            <Button
              style={calendly_style} 
              type="primary"
              // className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            >
              <LiaHandPointRightSolid style={{ verticalAlign: 'middle', marginRight: '5px', color: "#FA6304" }} />
              &nbsp; Help 
            </Button>
          </Dropdown>
          
          {/* <Button 
            style={calendly_style} 
            onClick={startCalendly}>
            <LiaHandPointRightSolid style={{ color: "#FA6304" }} />
            &nbsp; Schedule a demo now
          </Button> */}

          {/* <Button
            type="primary"
            onClick={startTour}
            style={{ ...help_style, marginLeft: '10px' }}
          >
          Help
          </Button> */}
      </div>
    );
  };

  return (
    <>
      <CalendlyBtn/>
      <PopupModal
          url="https://calendly.com/onkarvhatkar/timerely-enquries"
          onModalClose={() => setIsCalendlyOpen(false) }
          open={isCalendlyOpen}
          rootElement={document.getElementById("root")}
        />

      <Tour
        steps={stepsOptions}
        isOpen={optionsVisibility}
        onRequestClose={() => setOptionsVisibility(false)}
        showNumber={false}
        showNavigation={false} //This hides the dots.
        showPrevNextButtons={false}
        prevButton={<span></span>} // Hides the prev button
        nextButton={<span></span>} // Hides the next button
        // startAt={0}
      />

      <Tour
        steps={step}
        isOpen={isTourOpen1}
        onRequestClose={() => setIsTourOpen1(false)}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        getCurrentStep={(curr) =>
          console.log(`The current step is ${curr + 1}`)
        }
        accentColor={calendly_style.color}  //defines navigation dots color
        showNumber={false}
        //   nextButton="Next"
        lastStepNextButton={
          <Button onClick={() => setIsTourOpen1(false)}>Done!</Button>
        }
        disableCloseOnEsc={true}
        disableOverlayClick={true}
        disableInteraction={false}
        disableFocusLock={true} // This allows to select workspace....
        // onAfterOpen={() => setIsTourOpen1(true)}
        startAt={0}
        // disableNavigation={true}
        // showNavigation={false} //This hides the dots.
      />

      <Tour
        steps={steps2}
        isOpen={isTourOpen2}
        onRequestClose={() => setIsTourOpen2(false)}
        accentColor={calendly_style.color}
        showNumber={false}
        lastStepNextButton={
          <Button onClick={() => setIsTourOpen2(false)}>Done!</Button>
        }
        disableCloseOnEsc={true}
        disableOverlayClick={true}
        disableInteraction={false}
        disableFocusLock={true} // This allows to select workspace....
        startAt={0}
      />

      <Tour
        steps={steps3}
        isOpen={isTourOpen3}
        onRequestClose={() => setIsTourOpen3(false)}
        showNumber={false}
        accentColor={calendly_style.color}
        lastStepNextButton={
          <Button onClick={() => setIsTourOpen3(false)}>Done!</Button>
        }
        disableCloseOnEsc={true}
        disableOverlayClick={true}
        disableInteraction={false}
        disableFocusLock={true} // This allows to select workspace....
        startAt={0}
      />
      {isEnquiryOpen ? (
      <EnquiryForm show={isEnquiryOpen} setShow={isEnquiryOpen} 
                   open={isEnquiryOpen} handleOk={handleOk} 
                   handleCancel={handleCancel} onCancel={() => setIsEnquiryOpen(false)} />
      ) : null}
    </>
  );
};
