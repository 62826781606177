import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import TeamList from "../Teams/TeamList";
import { constants } from "../../constants";
import UserList from "../Users";
import Help from "../Help";
import Subscription from "../Subscription";
const { TabPane } = Tabs;

const TabComponent = () => {
  let loggedInUser = useSelector((state) => state.userSlice.user);

  return (
    <Tabs
      defaultActiveKey="1"
      tabBarStyle={{ marginLeft: "20px", marginBottom: "5px" }}
    >
      <TabPane tab="Teams" key="1">
        <TeamList />
      </TabPane>

      {loggedInUser &&
        loggedInUser.userRole === constants.usersRole.SUPER_ADMIN && (
          <TabPane tab="All Users" key="2">
            <UserList />
          </TabPane>
        )}
      {loggedInUser &&
        (loggedInUser.userRole === constants.usersRole.SUPER_ADMIN ||
          loggedInUser.userRole === constants.usersRole.ADMIN) && (
          <TabPane tab="Subscription" key="6">
            <Subscription />
          </TabPane>
        )}
      <TabPane tab="About" key="5">
        <Help />
      </TabPane>
    </Tabs>
  );
};

export default TabComponent;
