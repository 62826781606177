import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { collection, query, where, or, and, onSnapshot } from "firebase/firestore";
import { Space, Table, Popconfirm, Button, Tooltip, Popover } from 'antd';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "firebase/compat/auth";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import TeamForm from "./TeamForm"
import { getFunctions } from "firebase/functions";
import teamFunctions from "../../firebaseService/teamFunctions";
import toastHandler from "../../firebaseService/toastHandler";
import { constants } from "../../constants";
import CreateTeamModal from "./CreateTeamModal";
import { userUpdatedInfo } from "../../store/UserData";
import sentryUtils from "../../utils/sentryUtils";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";

const TeamList = () => {
    let loggedInUser = useSelector(state => state.userSlice.user)
    const location = useLocation();
    const[firstTimeLogin, setFirstTimeLogin] = useState(false)
    const [pageSize, setPageSize] = useState(20);
    const functions = getFunctions();
    const user = firebase.auth().currentUser;
    var projectsList = [];
    const [loading, setLoading] = useState(true);

    const [showTeamForm, setShowTeamForm] = useState(false);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const dispatch = useDispatch();
    const [teamList, setTeamList] = useState([]);

    const [recordData, setRecordData] = useState(null);
    const [show, setShow] = useState(false);
    const [assigneeCol, setAssigneeCol] = useState([]);
    
    useEffect(() => {
        if((location && location.state && location.state.firstTimeLogin)){
            setFirstTimeLogin(true)
            logEvent(analytics,"team_first_time_login" , { "account_id": localStorage.getItem("accountId") });
        }

        if((loggedInUser && !loggedInUser.hasTeam) || firstTimeLogin) {
            // setShowTeamForm(true)
            setLoading(false)
            console.log('don\'t have team', showCreateTeamModal)
            setShowCreateTeamModal(true)
            
        } 
        // else {

        try {
            const teamsRef = collection(firestore, "teams");
            let q = query(
                teamsRef,
                and(
                    where("accountId", "==", localStorage.getItem("accountId")),
                    where("isDeleted", "==", false),
                    or(
                        where("projectManager.userId", "==", user.uid),
                        where("teamCreator.userId", "==", user.uid),
                        where("lead.userId", "==", user.uid),
                        where("teamMemberIds", "array-contains", user.uid)
                    )
                )
            );
            if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
                q = query(
                    teamsRef,
                    and(
                        where("accountId", "==", localStorage.getItem("accountId")),
                        where("isDeleted", "==", false),
                    )
                );
            }


            const teamsSnapshot = onSnapshot(
                q,
                (querySnapshot) => {
                    let teams = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data.docId = doc.id;
                        teams.push(data);
                        if (loggedInUser && loggedInUser.userRole !== undefined && loggedInUser.userRole === constants.usersRole.SUPER_ADMIN) {
                            data.actionAllow = true
                        } else {
                            data.actionAllow = false
                            let UsersAllowedToAction = [
                                data.projectManager.userId,
                                data.teamCreator.userId,
                                data.lead.userId
                            ]
                            if (_.indexOf(UsersAllowedToAction, user.uid) != -1) {
                                data.actionAllow = true
                            }
                        }
                        
                    });
                    if(teams.length > 0){
                        const updatedUser = {
                            ...loggedInUser,
                            hasTeam: true 
                          };
                        dispatch(userUpdatedInfo(updatedUser))
                    } else {
                        const updatedUser = {
                            ...loggedInUser,
                            hasTeam: false 
                          };
                        dispatch(userUpdatedInfo(updatedUser))
                    }
                    setTeamList(teams);
                    console.log("inside teamsSnapshot", teams);
                },
                (error) => {
                    console.log("inside teamsSnapshot", error.message);
                    toastHandler.error("Error while fetching team list");
                    sentryUtils.captureFirestoreException('TeamList', error, 'TeamList');
                }
            );
            setLoading(false);
            return () => {
                teamsSnapshot();
                console.log("componentWillUnmount");
            };
        } catch (error) {
            setLoading(false);
            console.log(error);
            sentryUtils.captureGenericException('TeamList', error, 'TeamList');
        }
    // }
    }, [user, firstTimeLogin]);

    useEffect(() =>{
        logEvent(analytics,"team_list" , { "account_id": localStorage.getItem("accountId") });
    }, [])

    for (let i = 0; i < teamList.length; i++) {
        projectsList.push(teamList[i]?.project?.value);
    }

    projectsList = projectsList.filter(function (v, i, self) { return i == self.indexOf(v); }).map(function (value) { return { text: value, value: value } });

    const columns = [
        {
            title: 'Team',
            dataIndex: 'teamName',
            key: 'teamName',
            width: constants.width.teamNameColumnWidth - 30,
            ellipsis: true,
            fixed: 'left',
            sorter: (a, b) => a?.teamName.localeCompare(b?.teamName),
        },
        {
            title: 'Project Name',
            key: 'project',
            dataIndex: 'project',
            width: constants.width.projectColumnWidth - 80,
            ellipsis: true,
            filters: projectsList,
            onFilter: (value, record) => ((record.project.value.indexOf(value) > -1)),
            filterSearch: true,
            sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
            render: (_, record) => (record.project.value),
        },
        {
            title: 'Project Manager',
            dataIndex: 'projectManager',
            width: constants.width.userColumnWidth - 100,
            ellipsis: true,
            key: 'projectManager',
            render: (_, record) => (record.projectManager.userName || 'NA'),
        },
        {
            title: 'Members',
            key: 'teamCount',
            width: constants.width.smallColumnWidth,
            dataIndex: 'teamCount',
        },
        {
            title: 'Action',
            key: 'action',
            width: constants.width.smallColumnWidth,
            render: (_, record) => (
                <Space direction="horizantal">
                    <div className={!record.actionAllow ? 'edit-button-disabled-icon' : 'edit-button-icon'}>
                        <Tooltip title="edit" placement={'left'}>
                            <Button
                                disabled={!record.actionAllow}
                                type="text"
                                onClick={() => (setRecordData(record), setShowTeamForm(!showTeamForm))}
                                icon={<EditOutlined />}
                            />
                        </Tooltip>
                    </div>    
                    &nbsp;
                    <div className={!record.actionAllow ? "delete-button-disabled-icon" : "delete-button-icon"}>
                        <Tooltip title="delete" placement={'right'}>
                            <Popconfirm
                                disabled={!record.actionAllow}
                                title="Are you sure delete this team?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleDelete(record)}
                            >
                                <Button disabled={!record.actionAllow} type="text" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </div>   
                </Space>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    }
    const handleDelete = (row) => {
        console.log("in delete");
        console.log(row);
        const deleteTeam = { project: { key: parseInt(row.project.key)} }
        teamFunctions.deleteTeam(deleteTeam,row.teamName);

    };
    const TeamFormModal = () => {
        if (showTeamForm) {
            return (
                <TeamForm show={showTeamForm} setShow={setShowTeamForm}
                    onCancel={() => setShowTeamForm(false)}
                    teamInfo={recordData} dataSource={teamList} />
            )
        }
        else {
            return (<></>)
        }
    }

    const StartCreateTeamModal = () => {
        if (showCreateTeamModal) {
            return (
                <CreateTeamModal 
                    show={ showCreateTeamModal} 
                    setShow={setShowCreateTeamModal}
                    onCancel={() => setShowCreateTeamModal(false)}
                    setOpenCreateForm = {setShowTeamForm}
                    firstTimeLogin = {firstTimeLogin}
                    
                     />
            )
        }
        else {
            return (<></>)
        }
    }
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [cardHeight, setCardHeight] = useState(0)
    const [ebannerHeight, setEBannerHeight] = useState(0)
    const [rbannerHeight, setRBannerHeight] = useState(0)
    const [lenghtTable, setLengthTable] = useState(" ")
    const cardRef = useRef(null);
    const enquiryHeight = useSelector(state => state.tableStyleSlice.enquiryHeight);
    const resubmissionHeight = useSelector(state => state.tableStyleSlice.resubmissionHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            },);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    useEffect(() => {
        if (resubmissionHeight) {
            const bannerHeight = resubmissionHeight.offsetHeight;
            setEBannerHeight(bannerHeight)
        }
        if (enquiryHeight) {
            const bannerHeight = enquiryHeight.offsetHeight;
            setRBannerHeight(bannerHeight)
        }
        if (cardRef.current) {
            const height = cardRef.current.offsetHeight;
            setCardHeight(height)
        }
        setLengthTable(`calc(100vh - 170px - ${rbannerHeight}px - ${ebannerHeight}px - ${cardHeight}px)`
        )
    },)

    return (
        <div class="card mb-4">
            <div class="card-header py-3" ref={cardRef}>
                <div className="float-right">
                    {(loggedInUser?.userRole === constants.usersRole.ADMIN || loggedInUser?.userRole === constants.usersRole.SUPER_ADMIN) && (
                        <Space direction="horizantal">
                            <Button id='createTeam' type="primary" onClick={() => (setRecordData({}), setShowTeamForm(!showTeamForm))}>
                                <i class="fas fa-plus fa-sm"></i>
                                &nbsp; Create Team
                            </Button>
                        </Space>
                    )}
                </div>
            </div>
            <Table
                loading={loading}
                columns={columns}
                dataSource={teamList}
                size={'small'}
                rowKey={'createdDate'}
                scroll={{ x: 700, y: lenghtTable }}
                pagination={
                    { pageSize: pageSize,                          
                      showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
                     }}
              /* rowSelection={{ ...rowSelection }} */ />
            <TeamFormModal />
            <StartCreateTeamModal/>
        </div>
    );
}

export default TeamList;