import { createSlice } from "@reduxjs/toolkit";

export const timesheetSlice = createSlice({
    name:'userTimesheetData',
    initialState: {
        userTimesheetData:[],
    },
    reducers: {
        setUserTimesheetData: (state, action) => {
           return {
            ...state,
            userTimesheetData: action.payload
            }
        },
    },
})

export const {setUserTimesheetData} = timesheetSlice.actions;

export default timesheetSlice.reducer;
