import React, { useState, useEffect } from "react";
import {Button, Alert} from 'antd';
import { useNavigate } from "react-router-dom";
import enquiry from "../../firebaseService/enquiryFunctions";
import "../../App.css";
import "firebase/compat/auth";
import {
    Form,
    Input, 
    Select,
    Modal,
    Image,
    Typography,
  } from "antd";

import EnquiryIcon from "../../assets/images/enquiry_icon.png";
const { Title, Paragraph } = Typography;
export const UserLimitForm = (props) => {  
    let navigate = useNavigate();
  
    // const {open, handleOk} = props;
    const [open, setOpen] = useState(true);  
    const [form] = Form.useForm();
    const { TextArea } = Input;    
      
    const onFinish = async (values) => {    
        
        let enquiryData = {
          email: values.email,
          enquiryType: "User Limit Reached",
          message: values.message,
        };
        console.log("enquiryData", enquiryData);
        const result = await enquiry.addEnquiry(enquiryData);
        console.log("result", result)
        if(result){
          // navigate to thank you
          setOpen(false)
          navigate("ThankYou")
        }
      };
    

    const modalTitle = (
    <div style={{ textAlign: "center", marginBottom: 0, paddingBottom: 0 }}>
        <Image
        src={EnquiryIcon}
        preview={false}
        style={{ marginBottom: "15px" }}
        />
        <Title
        style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "14px",
            marginBottom: "0px",
            paddingBottom: 0,
        }}
        >
        Quick Enquiry
        </Title>
        <Paragraph
        style={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "5px",
            marginBottom: "-10px",
            color: "gray",
        }}
        >
        Your account has reached free tier limit. Fill the form for further enquiry.
        </Paragraph>
    </div>
    );
  
  return (
    <div>
    <Modal
        open={open}
        width={400}
        transitionName=""
        maskTransitionName=""
        title={modalTitle}
        okText="Send"
        cancelText="Cancel"
        footer={[
          <Button
            key="approve"
            type="primary"
            className="button-style"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onFinish(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            Send
          </Button>,
        ]}
      >
      
    <Form
          form={form}
          layout="vertical"
          initialValues={{ modifier: "public" }}
          onFinish={onFinish}
        >         
            
          
          <Form.Item
            name="email"
            label={<span style={{fontSize: '12px' }}>Email</span>}
            style={{ marginTop: "15px" }}
            rules={[
              {
                type: "email",
                message: "Please enter valid email.",
              },
              {
                required: true,
                message: "Please enter your email.",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>         
          <Form.Item
            name="message"
            label={<span style={{ fontSize: '12px' }}>Message</span>}
            rows={4}
            style={{ marginBottom: "-10px" }}            
          >
            <TextArea placeholder="Enter your message..." />
          </Form.Item>
        </Form>
        </Modal>   
    </div>
  );
};
export default UserLimitForm;