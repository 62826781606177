import React, { useRef, useState, useEffect, Fragment } from "react";
import firestore from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import moment from "moment";
import { Popconfirm, Table, Button, Select, Modal, Tooltip } from "antd";
import { Space } from "antd";
import {
  UpCircleOutlined,
  DownCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  FaCalendarAlt
} from "react-icons/fa";
import "antd/dist/antd.css";
import TimeEntryFormModel from "./TimeEntryFormModel";
import toastHandler from "../../firebaseService/toastHandler";
import {
  collection,
  query,
  where,
  and,
  onSnapshot,
  or,
} from "firebase/firestore";
import { constants } from "../../constants";
import { DatePicker } from "antd";
import _ from "lodash";
import DateRange from "../../common/DateRange";
import { setUserTimesheetData } from "../../store/FirestoreData";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";

function UserTimeSheet() {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [orgDataSource, setOrgDataSource] = useState([]);
  const [outerColumns, setOuterColumns] = useState([]);
  const [expandableObj, setExandableObj] = useState(null);
  const [rowKey, setRowKey] = useState("id");
  const [recordData, setRecordData] = useState({});
  const [show, setShow] = useState(false);
  const [projectfilteredInfo, setProjectFilteredInfo] = useState("");
  const [groupByKey, setGroupByKey] = useState(null);
  const [lastPage, setLastPage] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let [startIndex,setStartIndex] = useState();
  let [endIndex,setEndIndex] = useState();
  let [total, setTotal] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [startDocument, setStartDocument] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [reset, setReset] = useState(false);
  const [realTimeData, setRealTimeData] = useState([]);
  const [listeners, setListeners] = useState([]);

  const dateFormat = constants.dateTime.DATE
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));

  let projectsList = [];

  const user = firebase.auth().currentUser;
  const dispatch = useDispatch();

  const TimeEntryModel = () => {
    if (show) {
      return (
        <Modal
          style={{ maxWidth: "500px" }}
          title="Time Entry"
          destroyOnClose={true}
          open={show}
          onOk={() => setShow(false)}
          onCancel={() => setShow(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
          footer={null}
        >
          {/* <TimeEntryFormModel onCancel={() => setShow(false)}
                 teamInfo={(recordData && {
                  id: recordData.id,
                  workSpace: recordData.workSpace,
                  task: recordData.task,
                  project: recordData.project,
                  description: recordData.description,
                  logHours: recordData.logHours,
                  logDate: recordData.logDate,
                  billable: recordData.billable
                })} dataSource={dataSource} /> */}

          {recordData.id && (
            <TimeEntryFormModel
              timeSheet={{
                id: recordData.id,
                // workSpace: recordData.workSpace,
                task: recordData.task,
                subtask: recordData.subtask,
                project: recordData.project,
                description: recordData.description,
                logHours: recordData.logHours,
                logDate: recordData.logDate,
                billable: recordData.billable,
                currentForm: "timeSheet",
              }}
              resetPage={resetPage}
              dataSource={dataSource}
              onCancel={() => setShow(false)}
            />
          )}
          {!recordData.id && (
            <TimeEntryFormModel
              resetPage={resetPage}
              dataSource={dataSource}
              onCancel={() => setShow(false)}
            />
          )}
          {/* <TimeEntryFormModel timeSheet={{...recordData}}  dataSource={dataSource} onCancel={() => setShow(false)}  /> */}
          {/* onCancel={() => setShow(false)}  */}
        </Modal>
      );
    } else {
      return <> </>;
    }
  };

  useEffect(() =>{
    logEvent(analytics,"user_timesheet" , { "account_id": localStorage.getItem("accountId") });
  }, [])

  useEffect(() => {
    if (user) {
      var db = firebase.firestore();
      const accountId = localStorage.getItem("accountId");
      const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection

      var listenersArray = listeners;
      // var listenersArray = [];
      var dataArray = [];
      console.log(startDate, endDate)
      const start = new Date(new Date(startDate).setUTCHours(-5, -30, 0) + 24*60*60*1000);
      const end = new Date(new Date(endDate).setUTCHours(18, 29, 59) + 24*60*60*1000);
      console.log(start, end)
      let queryRef = timeEntryRef
      .where("userId", "==", user.uid)
      .where("accountId", "==", accountId)
      .where("deleted", "==", false)
      .where("logDate", ">=", start)
      .where("logDate", "<=", end)
      .orderBy("logDate", "desc")

      if(startDocument!==0){
        queryRef = queryRef.startAfter(startDocument)
      }

       queryRef 
        .limit(pageSize)
        .get()
        .then((snapshots) => {
          if (snapshots.docs.length) {
            snapshots.docs.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              data.logDate = moment(data.logDate.seconds * 1000).format(
                constants.dateTime.DATE
              );
              dataArray.push(data);
            });
            // previous starting boundary becomes new ending boundary
            let startDoc = snapshots.docs[snapshots.docs.length - 1];
            setStartDocument(startDoc);

            if (dataArray.length < pageSize) {
              setHasMoreData(false);
            }
            // add newly fetched records
            let newDataArray = orgDataSource.concat(dataArray);
            setOrgDataSource(newDataArray);
            console.log('newDataArray', newDataArray)
            //create listener for every document
            let listener = timeEntryRef
              .where("userId", "==", user.uid)
              .where("accountId", "==", accountId)
              .where("deleted", "==", false)
              .where("logDate", ">=", start)
              .where("logDate", "<=", end)
              .orderBy("logDate", "desc")
              .startAt(snapshots.docs[0])
              .endAt(snapshots.docs[snapshots.docs.length - 1])
              .onSnapshot((docs) => {
                let updatedData = [];
                docs.forEach((doc) => {
                  let data = doc.data();
                  data.id = doc.id;
                  data.logDate = moment(data.logDate.seconds * 1000).format(
                    constants.dateTime.DATE
                  );
                  updatedData.push(data);
                });

                setRealTimeData(updatedData);
              });

            listenersArray.push(listener);
            setListeners(listenersArray);

            dispatch(setUserTimesheetData(newDataArray));
          } else {
            setHasMoreData(false);
            setLoading(false);
            // toastHandler.info("No more records.");
          }
          setLoading(false);
        });
    }
  }, [startDate, endDate, loadMore, reset]);

  useEffect(() => {
    if (user) {
      var db = firebase.firestore();
      const accountId = localStorage.getItem("accountId");
      const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection      
      console.log(startDate, endDate)
      const start = new Date(new Date(startDate).setUTCHours(-5, -30, 0) + 24*60*60*1000);
      const end = new Date(new Date(endDate).setUTCHours(18, 29, 59) + 24*60*60*1000);
      console.log(start, end)
      let queryRef = timeEntryRef
      .where("userId", "==", user.uid)
      .where("accountId", "==", accountId)
      .where("deleted", "==", false)
      .where("logDate", ">=", start)
      .where("logDate", "<=", end) 

      const snapshot = onSnapshot(
        queryRef,
        (querySnapshot) => {          
          if (querySnapshot.size === 0) {
            console.log("querySnapshot size 0");
          }
          else {                          
            total = querySnapshot.size;
            startIndex = (currentPage - 1) * pageSize + 1;            
            endIndex = Math.min((startIndex + pageSize - 1), total);         
            setTotal(querySnapshot.size); 
            setStartIndex(startIndex);
            setEndIndex(endIndex);
          }
        }) 
    }
  }, [startDate, endDate]);


  useEffect(() => {   
    return () => {
      detachListeners(); // This detach "firestore snapshot listener"
      console.log("componentWillUnmount");
    };
  }, []);

  function detachListeners() {
    listeners.forEach((listener) => listener());
  }
  function resetPage() {
    detachListeners()
    setStartDocument(0);
    setOrgDataSource([]);
    setDataSource([]);
    setLastPage(0);
    setCurrentPage(1);
    setHasMoreData(true);
    setReset(!reset)
  }
  useEffect(() => {
    if (realTimeData.length && orgDataSource.length) {
      let arrCopy = [...orgDataSource];
      for (let i = 0; i < orgDataSource.length; i++) {
        for (let j = 0; j < realTimeData.length; j++) {
          // To overwrite existing records with real time changes
          if (orgDataSource[i]["id"] === realTimeData[j]["id"]) {
            arrCopy[i] = realTimeData[j];
          }
        }
      }

      let finalDataSource = _.cloneDeep(arrCopy);
      setOrgDataSource(finalDataSource);
    }
  }, [realTimeData]);

  const displayUpdates = () => {
    if (groupByKey != null) {
      changeDataFormat(
        groupBy(orgDataSource, groupByKey),
        groupByKey,
        projectfilteredInfo
      );
    } else {
      setDataSource(orgDataSource);
    }
  };

  useEffect(() => {    
    if(groupByKey != null){
      startIndex = 1;     
      endIndex = orgDataSource.length;     
      setStartIndex(startIndex);
      setEndIndex(endIndex); 
    }  
    displayUpdates();
  }, [groupByKey, orgDataSource]);

  const taskList = [];
  for (let i = 0; i < orgDataSource.length; ++i) {
    let projectNameString = "";
    projectNameString += orgDataSource.map(function (element) {
      return element.project?.value;
    });

    taskList.push({
      key: i.toString(),
      date: orgDataSource[i][0]?.logDate, //logDate
      projectString: projectNameString,
      hours: "", //logDate
      data: orgDataSource[i],
    });
    projectsList.push(orgDataSource[i]?.project?.value);
  }

  projectsList = projectsList
    .filter(function (v, i, self) {
      return i == self.indexOf(v);
    })
    .map(function (value) {
      return { text: value, value: value };
    });

  const initOuterColumns = [
    {
      title: "Date",
      dataIndex: "logDate",
      key: "logDate",
      width: constants.width.dateColumnWidth,
      ellipsis: true,
      sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      width: constants.width.projectColumnWidth,
      ellipsis: true,
      filters: projectsList,
      onFilter: (value, record) =>
        record.project?.value
          ? record.project?.value === value
          : record.projectString?.indexOf(value) > -1,
      sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
      render: (_, record) => record?.project?.value || "-",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => record?.task?.value,
    },
    {
      title: "Subtask",
      dataIndex: "subtask",
      key: "subtask",
      width: constants.width.taskColumnWidth,
      ellipsis: true,
      render: (_, record) => record?.subtask?.value,
    },
    {
      title: "Hour(s)",
      dataIndex: "logHours",
      key: "logHours",
      width: constants.width.hoursColumnWidth,
      ellipsis: true,
      render: (_, record) => (
        <>
          {" "}
          {record.logHours}{" "}
          <span className="hrs">
            {" "}
            {record?.logHours?.split(":")[0] == "00" ? "Mins" : "Hrs"}
          </span>{" "}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: constants.width.descriptionClomnWidth,
      ellipsis: {
        showTitle: false,
      },
      render: (e, record) => (
        <Tooltip placement="leftBottom" title={record.description}>
          {_.capitalize(_.trim(record.description)) || '-'}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      align: 'center',
      width: constants.width.statusColumnWidth,
      ellipsis: true,
      render: (approvalStatus) => (
        <span className={approvalStatus.toLowerCase()}>{approvalStatus}</span>
      ),
    },
    {
      title: "Billable",
      dataIndex: "billable",
      key: "billable",
      width: constants.width.billableColumnWidth,
      render: (billable) =>
        billable ? (
          <Tooltip placement="right" title={"Biilable"}>
            {" "}
            <span className="billable">
              {" "}
              <CheckOutlined />
            </span>{" "}
          </Tooltip>
        ) : (
          <Tooltip placement="right" title={"Non biilable"}>
            {" "}
            <span className="billable"> </span>
            <span className="non-billable">
              {" "}
              <CloseOutlined />{" "}
            </span>{" "}
          </Tooltip>
        ),
    },
    {
      title: "Action",
      width: constants.width.actionColumnWidth,
      render: (_, record) => (
        <>
          <Space direction="horizantal">
            <div className={record.approvalStatus == "Approved" ? 'edit-button-disabled-icon' : 'edit-button-icon'}>
              <Button
                type="text"
                onClick={() => handleEdit(record)}
                icon={<EditOutlined />}
                disabled={record.approvalStatus == "Approved"}
              />
            </div>
            &nbsp;
            <div className={record.approvalStatus == "Approved" ? "delete-button-disabled-icon" : "delete-button-icon"}>
              <Popconfirm
                title="Are you sure delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDelete(record.id)}
                disabled={record.approvalStatus == "Approved"}
              >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  disabled={record.approvalStatus == "Approved"}
                />
              </Popconfirm>
            </div>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    setOuterColumns(initOuterColumns);
  }, [projectsList.length]);

  const handleEdit = (row) => {
    setRecordData(row);
    setShow(!show);
  };

  const handleAdd = () => {
    console.log("in add");
    setRecordData({});
    setShow(true);
  };

  const handleDelete = (id) => {
    if (id) {
      firestore
        .collection("timeEntry")
        .doc(id)
        .update({
          deleted: true,
        })
        .then((docRef) => {
          toastHandler.success('Record Deleted', 'deleteEntry')
          resetPage()
        });
    }
  };

  const ExpandedRowRender = (props) => {
    // console.log(props);
    const innerColumns = [
      {
        title: "Date",
        dataIndex: "logDate",
        key: "logDate",
        width: constants.width.dateColumnWidth,
        ellipsis: true,
        sorter: (a, b) => moment(a.logDate).unix() - moment(b.logDate).unix(),
      },
      {
        title: "Project",
        dataIndex: "projectName",
        key: "project",
        width: constants.width.projectColumnWidth,
        ellipsis: true,
        filteredValue: projectfilteredInfo || null,
        onFilter: (value, record) => record.projectName === value,
        sorter: (a, b) => a?.project?.value?.localeCompare(b?.project?.value),
      },
      {
        title: "Task",
        dataIndex: "task",
        key: "task",
        width: constants.width.taskColumnWidth,
        render: (_, record) => record.task.value || "-",
        ellipsis: true,
      },
      {
        title: "Subtask",
        dataIndex: "subtask",
        key: "subtask",
        width: constants.width.taskColumnWidth,
        render: (_, record) => record.subtask?.value || "-",
        ellipsis: true,
      },
      {
        title: "Hour(s)",
        dataIndex: "hours",
        key: "hours",
        width: constants.width.hoursColumnWidth,
        ellipsis: true,
        render: (_, record) => (
          <>
            {" "}
            {record.hours}{" "}
            <span className="hrs">
              {" "}
              {record.hours.split(":")[0] == "00" ? "Mins" : "Hrs"}
            </span>{" "}
          </>
        ),
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: constants.width.descriptionClomnWidth,
        ellipsis: {
          showTitle: false,
        },
        render: (e, record) => (
          <Tooltip placement="leftBottom" title={record.description}>
            {_.capitalize(_.trim(record.description)) || '-'}
          </Tooltip>
        ),
      },
      {
        title: "Status",
        dataIndex: "approvalStatus",
        key: "approvalStatus",
        align: 'center',
        width: constants.width.statusColumnWidth,
        render: (approvalStatus) => (
          <span className={approvalStatus.toLowerCase()}>{approvalStatus}</span>
        ),
      },
      {
        title: "Billable",
        dataIndex: "billable",
        key: "billable",
        width: constants.width.billableColumnWidth,
        render: (billable) =>
          billable ? (
            <Tooltip placement="right" title={"Biilable"}>
              {" "}
              <span className="billable">
                {" "}
                <CheckOutlined />
              </span>{" "}
            </Tooltip>
          ) : (
            <Tooltip placement="right" title={"Non biilable"}>
              {" "}
              <span className="billable"> </span>
              <span className="non-billable">
                {" "}
                <CloseOutlined />{" "}
              </span>{" "}
            </Tooltip>
          ),
      },
      {
        title: "ACTION",
        key: "action",
        width: constants.width.actionColumnWidth,
        render: (_, record) => (
          <Space direction="horizantal">
              <div className={record.approvalStatus == "Approved" ? 'edit-button-disabled-icon' : 'edit-button-icon'}>
                <Button
                  type="text"
                  onClick={() => handleEdit(record)}
                  icon={<EditOutlined />}
                  disabled={record.approvalStatus == "Approved"}
                />
              </div>
              &nbsp;
              <div className={record.approvalStatus == "Approved" ? "delete-button-disabled-icon" : "delete-button-icon"}>
                <Popconfirm
                  title="Are you sure delete this task?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleDelete(record.id)}
                  disabled={record.approvalStatus == "Approved"}
                >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  disabled={record.approvalStatus == "Approved"}
                />
                </Popconfirm>
              </div>
          </Space>
        ),
      },
    ];
    const dataRows = props.record.data.map((item, index) => ({
      ...item,
      key: item.id,
      date: item.logDate,
      hours: item.logHours,
      user: item.userName,
      billable: item.billable,
      approvalStatus: item.approvalStatus,
      logCost: "-",
      invoiceStatus: item.invoiceStatus,
      userName: item.userName,
      projectName: item.project?.value,
      task: item.task,
      subtask : item.subtask,
      // workSpace: item.workSpace
    }));

    return (
      <div className="inner-table">
        <Table
          rowKey={"key"}
          columns={innerColumns}
          showHeader={true}
          dataSource={dataRows}
          pagination={{ pageSize: 5 }}
          size="small"
          scroll={{ x: 1300 }}
        />
      </div>
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  function groupBy(collection, property) {
    var i = 0,
      val,
      index,
      values = [],
      result = [];
    for (; i < collection.length; i++) {
      val =
        typeof collection[i][property] == "object"
          ? collection[i][property].value
          : collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  function changeDataFormat(groupedClientData, groupByCol, filters) {
    setProjectFilteredInfo(filters);
    let taskListNew = [];
    for (let i = 0; i < groupedClientData.length; ++i) {
      let projectNameString = "";
      projectNameString += groupedClientData[i].map(function (element) {
        return element.project?.value;
      });

      taskListNew.push({
        key: i.toString(),
        logDate: groupedClientData[i][0]?.logDate, //logDate
        projectString: projectNameString,
        project: "", //logDate
        approvalStatus: groupedClientData[i][0]?.approvalStatus, //logDate
        data: groupedClientData[i],
      });
    }

    setDataSource(taskListNew);

    const outerColumnsNew = [
      {
        title: `Grouped by ${groupByCol
          .replace("approvalStatus", "status")
          .replace("logDate", "date")}`,
        dataIndex: groupByCol,
        key: groupByCol,
        width: 200,
        render: (_, record) =>
          typeof record["data"][0][groupByCol] == "object"
            ? record["data"][0][groupByCol]["value"]
            : record[groupByCol],
      },
      { title: "", dataIndex: "project" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
      { title: "" },
    ];
    setOuterColumns(outerColumnsNew);
    setExandableObj({
      expandedRowRender: (record, projectfilteredInfo) => (
        <ExpandedRowRender record={record} filters={projectfilteredInfo} />
      ),
      expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
          <UpCircleOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
        ),
    });
    setRowKey("key");
  }

  const setInitData = () => {
    setDataSource(orgDataSource);
    setOuterColumns(initOuterColumns);
    setExandableObj(null);
    setGroupByKey(null);
    setRowKey("id");
  };

  const groupTableRows = (selectedOption, filters) => {
    let opt = selectedOption;
    let groupByKeyArr = [];
    groupByKeyArr["logDate"] = "groupByDate";
    groupByKeyArr["approvalStatus"] = "groupByStatus";
    groupByKeyArr["project"] = "groupByProject";

    if (groupByKeyArr[selectedOption]) {
      console.log("groupTableRows", groupByKeyArr[selectedOption]);
      console.log("groupTableRows", opt);
      opt =
        typeof selectedOption == "object"
          ? selectedOption.value
          : groupByKeyArr[selectedOption];
    } else {
      opt = selectedOption.value;
    }

    switch (opt) {
      case "clear":
        setInitData();
        break;
      case "groupByDate":
        setGroupByKey("logDate");
        changeDataFormat(groupBy(orgDataSource, "logDate"), "logDate", filters);
        break;
      case "groupByStatus":
        setGroupByKey("approvalStatus");
        changeDataFormat(
          groupBy(orgDataSource, "approvalStatus"),
          "approvalStatus",
          filters
        );
        break;
      case "groupByProject":
        setGroupByKey("project");
        changeDataFormat(groupBy(orgDataSource, "project"), "project");
        break;
      default:
    }
  };

  const getNextPageRange = (currentPage,lastPage) =>{    
    if(currentPage === lastPage){
      setLoadMore(!loadMore);
      setCurrentPage(currentPage + 1);
   }        
    currentPage = currentPage + 1;    
    if(groupByKey == null){
      startIndex = (currentPage - 1) * pageSize + 1;       
      endIndex = Math.min((startIndex + pageSize - 1), total);     
      setStartIndex(startIndex);
      setEndIndex(endIndex); 
    }   
  };

  const getPrevPageRange = () =>{      
    currentPage = currentPage - 1;   
    setCurrentPage(currentPage); 
    if(groupByKey == null){
      startIndex = (currentPage - 1) * pageSize + 1;       
      endIndex = Math.min((startIndex + pageSize - 1), total);         
      setStartIndex(startIndex);
      setEndIndex(endIndex);
    }
    
  };

  const onChange = (pagination, filters, sorter, extra) => {    
    setCurrentPage(pagination.current);
    currentPage = pagination.current;
    setProjectFilteredInfo(filters.project);
    if(groupByKey == null){
      startIndex = (currentPage - 1) * pageSize + 1;    
      endIndex = Math.min((startIndex + pageSize - 1), total);         
      setStartIndex(startIndex);
      setEndIndex(endIndex);
    }
  };

  const updateDateRange = (range = []) => {
    if (range !== null) {
      const dateRange = range.map((date) => moment(date).format(dateFormat));
      const [start, end] = dateRange;

      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(moment().startOf("month").format(dateFormat));
      setEndDate(moment().format(dateFormat));
    }
    setStartDocument(0);
    setOrgDataSource([]);
    setDataSource([]);
    setLastPage(0);
    setCurrentPage(1);
    setHasMoreData(true);
  };

  const itemRender = (_, type, originalElement) => {
    setLastPage(_);
    if (type === "prev") {
      return (
        <>
          <span style= {{marginRight:"10px"}}>{startIndex} - {endIndex} of {total}</span>      
          <Button type="text" 
                  disabled={ currentPage === 1} 
                  onClick={getPrevPageRange}> Prev </Button>
        </>
        );
    }
    if (type === "next") {
      return (
        <>
          <Button
            type="text"
            disabled={!hasMoreData && ( currentPage === lastPage || groupByKey!= null)}
            onClick={() =>{                             
                getNextPageRange(currentPage,lastPage)}            
            }
          >
            Next
          </Button>    
        </>
      );
    }
    return originalElement;
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [cardHeight, setCardHeight] = useState(0)
  const [ebannerHeight, setEBannerHeight] = useState(0)
  const [rbannerHeight, setRBannerHeight] = useState(0)
  const [tableLength, setTableLength] = useState(" ")

  const cardRef = useRef(null);
  const enquiryHeight = useSelector(state => state.tableStyleSlice.enquiryHeight);
  const resubmissionHeight = useSelector(state => state.tableStyleSlice.resubmissionHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      },);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (resubmissionHeight) {
      const bannerHeight = resubmissionHeight.offsetHeight;
      setEBannerHeight(bannerHeight)
    }
    if (enquiryHeight) {
      const bannerHeight = enquiryHeight.offsetHeight;
      setRBannerHeight(bannerHeight)
    }
    if (cardRef.current) {
      const height = cardRef.current.offsetHeight;
      setCardHeight(height)
    }
    setTableLength(`calc(100vh - 125px - ${rbannerHeight}px - ${ebannerHeight}px - ${cardHeight}px)`
    )
  },)
  
  return (
    <div class="card mb-4">
      <div class="card-header py-3" ref={cardRef}>
        <DateRange updateDateRange={updateDateRange} dateFormat={dateFormat} style={{ width: "10px" }} />

        <div className="float-right">
          <Space direction="horizantal">
            <Link  to="/master/bulktimesheetentry">
              <Button style={{ color: '#1B2F78' }} icon = {<FaCalendarAlt />} > 
                 &nbsp; Bulk Time Entry 
              </Button>
            </Link>
            <Select
              labelInValue
              placeholder="Group by"
              style={{ width: 160 }}
              disabled={!dataSource.length}
              onChange={(selectedOption) =>
                groupTableRows(selectedOption, projectfilteredInfo)
              }
              options={[
                {
                  value: "clear",
                  label: "Clear",
                  onSelect: () => {
                    console.log("hi");
                  },
                },
                {
                  value: "groupByDate",
                  label: "Group By Date",
                },
                {
                  value: "groupByStatus",
                  label: "Group By Status",
                },
                {
                  value: "groupByProject",
                  label: "Group By Project",
                },
              ]}
            />
            <Button id="add_time_entry" onClick={() => handleAdd()} type="primary">
              <i class="fas fa-plus fa-sm"></i>
              &nbsp; Time Entry
            </Button>
            {/* <Button onClick={() => setLoadMore(!loadMore)} type="primary">
              <i class="fas fa-plus fa-sm"></i>
              &nbsp; Load More
            </Button> */}
          </Space>
        </div>
      </div>
      <Table
        loading={loading}
        columns={outerColumns}
        expandable={expandableObj}
        dataSource={dataSource}
        pagination={{
          pageSize: pageSize,
          current: currentPage,
          itemRender: itemRender,
          // position: ["topRight"],                             
        }}
        onChange={onChange}
        rowKey={rowKey}
        scroll={{ x: 1300, y: tableLength }}
      />
      <TimeEntryModel />
    </div>
  );
}
export default UserTimeSheet;
