import React, {useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import authFunctions from "../../firebaseService/authFunctions";
import firebase from "firebase/compat/app";
import { Space, Spin } from 'antd';
import mondaySdk from "monday-sdk-js";
import MondayConfig from "../../configs/MondayConfig";
import sentryUtils from "../../utils/sentryUtils";
import { analytics } from "../../firebase";
import { logEvent } from "@firebase/analytics";
import subscriptionStatus from "../../constants/subscription";

const OauthLoginVerifier = () => {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const monday = mondaySdk();
  monday.setApiVersion(MondayConfig.MONDAY_API_VERSION)
  const getSessionToken = async () => {
    let token = await monday.get("sessionToken");
    // console.log(token.data);
    // query for account & then store the details
    const query = `query {
      me {
          id
          account {
            id
          }
         }
      }`;
    const info = await monday.api(
        query,
        {
          options:{token:token.data},
        }
    ).catch((err) => {
      console.log(err.message);
      sentryUtils.captureMondayException('getSessionToken', err, 'OauthLoginVerifier');
    });
    const accId = info?.data?.me?.account?.id;
    const user_id =  info?.data?.me?.id;
    

    if (token.data != null && token.data !== 'undefined' && accId != null && accId !=='undefined' && user_id != null && user_id !=='undefined') {
      localStorage.removeItem("sessionToken_"+ accId + "_" + user_id ); 
      localStorage.setItem("sessionToken_"+ accId + "_" + user_id, token.data);
      localStorage.removeItem("accountId");
      localStorage.setItem("accountId", accId);
      localStorage.removeItem("userId");
      localStorage.setItem("userId", user_id);
    }
  };


  const getAccessTokenFromCode = async (code) => {
    console.log(" Inside getAccessTokenFromCode");
    const userId = localStorage.getItem("userId")
    const accId = localStorage.getItem("accountId");
    let token = await monday.get("sessionToken");
    authFunctions.getAccessTokenFromMonday(code)
    .then((result) => {
      console.log("result inside monday oauth login verificaton:", result);
      if (result !== undefined && result.data === "authorized"){
        monday.execute('valueCreatedForUser')
        logEvent(analytics,"new_user" , { "account_id": accId });
        const userId = localStorage.getItem("userId");
        localStorage.removeItem("authenticated_" + accId + "_" + userId);
        localStorage.setItem("authenticated_" + accId + "_" + userId, 'true');
        getFirestoreTokenFromSessionToken();
      } else if(result !== undefined && result.data === subscriptionStatus.USER_LIMIT_REACHED){
        console.log("limit reached")
        // navigate(`UsersLimitReached`);
        navigate(`/UsersLimitReached/${subscriptionStatus.USER_LIMIT_REACHED}`);
        localStorage.removeItem("sessionToken_" + accId + "_" + userId, token.data);
      } 
      else if(result !== undefined && result.data === subscriptionStatus.SUBSCRIPTION_EXPIRED){
        console.log("subscription expired")
        navigate(`/SubscriptionExpired/${subscriptionStatus.SUBSCRIPTION_EXPIRED}`);
        localStorage.setItem("sessionToken_" + accId + "_" + userId, token.data);
      } else if(result !== undefined && result.data === subscriptionStatus.TRIAL_ENDED){
        console.log("trial ended")
        navigate(`/TrialEnded/${subscriptionStatus.TRIAL_ENDED}`);
        localStorage.setItem("sessionToken_" + accId + "_" + userId, token.data);
      } 
      else {
        console.log("token expired");
        getSessionToken();
      }
    });
  };

  const getFirestoreTokenFromSessionToken = async () => {
    console.log(" Inside getFirestoreTokenFromSessionToken");
    const accId = localStorage.getItem("accountId");
      authFunctions.getFirestoreTokenFromSessionToken().then((result) => {
        // set state as logged in in local storage, which to be used for protected routes
        if (result.data === "monday session expired") {
          console.log("token expired");
          getSessionToken();
        } else {
          const userId = localStorage.getItem("userId");
          localStorage.removeItem("firestoreToken_" + accId + "_" + userId );
          localStorage.setItem("firestoreToken_" + accId + "_" + userId, result.data);
          userLoginwithToken();
        }
      });
  };

  const userLoginwithToken = async () => {
    const accId = localStorage.getItem("accountId");
    const userId = localStorage.getItem("userId");
    
    const firestoreToken = localStorage.getItem("firestoreToken_" + accId + "_" + userId);
    // console.log("firestoreToken", firestoreToken);
    const loggedInCurrentUser = await firebase
      .auth()
      .signInWithCustomToken(firestoreToken)
      .then((currentUser) => {
        // console.log(currentUser);
        console.log(currentUser.user.uid);
        // setAccountId();
        // navigate("/master/user/timesheet");
        navigate('/master/AllSetting', { state: { firstTimeLogin: true }})
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        if (errorCode === "auth/invalid-custom-token") {
          alert("The token you provided is not valid.");
        } else {
          console.error(error);
        }
      });
  };

  const setAccountId = () => {
    console.log("getting account id...");
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // console.log(idTokenResult.claims.account_id);
        localStorage.removeItem("accountId");
        localStorage.setItem("accountId", idTokenResult.claims.account_id);

        localStorage.removeItem("authenticated");
        localStorage.setItem("authenticated", true);
        navigate("/master/user/timesheet");
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    const code = searchParams.get('code');
    console.log('code====', code)
    if(!code) {
      // When user clicks on cancel auth, it gives code is null
      console.log('code does not exists', code)
      // navigate("AccessDenied")
      navigate(`/AccessDenied/${subscriptionStatus.ACCESS_DENIED}`)

    } else {
      console.log('====inside get access token form code====')
      getAccessTokenFromCode(code)
    }
  }, [searchParams]);

 
  return (
    <div class="container">
      <div class="container-fluid" >
        <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Spin tip="Authenticating..." size="large">
              <div className="content" />
            </Spin>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default OauthLoginVerifier;
