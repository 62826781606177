import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Row, message } from 'antd';
import mondaySdk from "monday-sdk-js";
import toastHandler from '../../../firebaseService/toastHandler';
import MondayConfig from '../../../configs/MondayConfig';
import sentryUtils from '../../../utils/sentryUtils';

const SelectAssigneeColumn = (props) => {
    const { placeholder, data, form, field, cb, error, value, disabled, selectedProject } = props;
    const { name } = field;
    const monday = mondaySdk();
    monday.setApiVersion(MondayConfig.MONDAY_API_VERSION)
    const [assigneeColumns, setAssigneeColumns] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [busy, setBusy] = useState(false);
    const [isFirst, setIsFirst] = useState(true)

    const fetchColumn = async () => {
        console.log('fetchColumn called')
        if (
            selectedProject !== null &&
            selectedProject !== '' &&
            selectedProject.key !== undefined
        ) {
            setBusy(true);

            let query = `query {
                boards (ids: ` + selectedProject.key + `) {
                  columns(types: people) {
                    id
                    title
                    type
                  }		
                }
              }`;


            const tokendata = await monday.get('sessionToken');
            const response = await monday.api(query, {
                options: { token: tokendata.data },
            }).catch((err) => {
                setBusy(false);
                console.log('error in fetch column --->', err.message);
                toastHandler.error(err.message);
                sentryUtils.captureMondayException('fetchColumn', error, 'selectAssigneeColumn');
            });

            let columnList = response.data.boards[0]?.columns || [];

            columnList = _.orderBy(columnList, ['title'], ['asc']);
            let columns = [];
            columnList.forEach((item, index) => {
                columns.push({
                    key: item.id,
                    label: item.title,
                    value: item.id,
                });
            });
            setAssigneeColumns(columns);
            setBusy(false);
        }
    };

    useEffect(() => {
        if (!_.isEmpty(selectedProject)) {
            fetchColumn();
            setIsFirst(true)
        }
    }, [selectedProject]);

    useEffect(() => {
        if(name === 'assigneeColName' && assigneeColumns.length === 0){
            if(!isFirst){
                message.error("Add people's column to your monday board");
                toastHandler.error("Add people's column to your monday board");
            } else {
                setIsFirst(false)
            }
        }
        if (assigneeColumns.length > 0 && value) {
            const data = assigneeColumns.filter((item) => item.value === value);
            if(data.length > 0){
                cb(data);
                setSelectedValue(data[0]);
            } else {
                setSelectedValue(null);
                if(value){
                    toastHandler.error("Your previouslly set people's column is deleted from monday board");
                }
            }
            
        } else {
            setSelectedValue(null);
        }
    }, [assigneeColumns, value])

    return (
        <div>
            <Row>
                <Select
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    name={name}
                    value={selectedValue}
                    showSearch
                    optionFilterProp='label'
                    onChange={(e) => {
                        const selectedValue = assigneeColumns.filter((item) => item.value === e);
                        cb(selectedValue)
                    }}
                    loading={busy}
                >
                    {assigneeColumns?.map((item) => (
                        <Select.Option key={item.key} value={item.value} label={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            </Row>
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div >
    )
};

export default SelectAssigneeColumn;
