import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Select, Row } from 'antd';

const SelectComponent = (props) => {
    const { placeholder, data, form, busy, field, cb, handleDeleteTeamMember, error, value, disabled, mode = '', teamMembers = [] } = props;
    const { name } = field;

    useEffect(() => {
        if (data.length > 0 && value) {
            if (mode !== 'multiple') {
                let data1 = data.filter((item) => item.value === value);
                cb(data1);
            }
        }
    }, [value])

    const handleChange = (i, v) => {
        cb(v);
    };

    return (
        <div>
            {(mode !== 'multiple') ?
                <Select
                    placeholder={placeholder}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    name={name}
                    value={value ? data.filter((item) => item.value === value) : null}
                    showSearch
                    optionFilterProp='label'
                    onChange={(e) => {
                        const selectedValue = data.filter((item) => item.value === e);
                        cb(selectedValue)
                    }}
                    loading={busy}
                >
                    {data.map((item) => (
                        <Select.Option key={item.key} value={item.value} label={item.label}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
                :
                <Select
                    mode="multiple"
                    allowClear={false}
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    defaultValue={teamMembers.filter((e) => (!e.isDeleted) && e.value)}
                    onChange={handleChange}
                    optionLabelProp="label"
                    options={data}
                    onDeselect={(name, value) => handleDeleteTeamMember(name, value)}
                // maxTagCount="responsive"
                />
            }
            {error ? <Row><span style={{ fontSize: 11, color: 'red' }}>{error}</span></Row> : null}
        </div >
    )
};

export default SelectComponent;
