import _ from 'lodash';
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
// import toastHandler from "../../firebaseService/toastHandler";

const db = firebase.firestore();

const fetchMondayAccount = async () => {

    let info = null

    const result = await db.collection("mondayAccounts").where("accountId", "==", localStorage.getItem("accountId")).limit(1).get()

    if (result.size) {
        result.forEach(doc => {
            info = doc.data()
        });
    }

    return info
}
export default fetchMondayAccount;