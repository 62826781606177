import React, { useState, useEffect, useRef } from "react";
import {Button, Alert} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom'
import "../../App.css";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { setResubmissionHeight } from '../../store/TableStyleData'
import { useDispatch } from "react-redux"

export const Resubmission = () => {  
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate()
  const user = firebase.auth().currentUser;
  const navigateToResubmission = () => {
    navigate('/master/resubmission')
    setShowAlert(false);
  }; 
  
const dispatch = useDispatch();
const bannerHeightRef = useRef(0);
const location = useLocation()
useEffect(() => {
    dispatch(setResubmissionHeight(bannerHeightRef.current));
}, [bannerHeightRef, dispatch]);

  useEffect(() => {
    
      if (user && location.pathname != "/master/resubmission") {
        var db = firebase.firestore();
        const accountId = localStorage.getItem("accountId");
        const timeEntryRef = db.collection("timeEntry"); // Create a query against the collection        
        const queryRef = timeEntryRef
          .where("userId", "==", user.uid)
          .where("approvalStatus", "in", ["Rejected"])
          .where("accountId", "==", accountId)
          .where("deleted", "==", false)          
          .get()
          .then((snapshots) => {            
            if (snapshots.docs.length) {        
              setShowAlert(true);  
            } else {
              setShowAlert(false);              
            }
          });
      }    
  },[location]);

  const TextComponent = () => {
    return (      
        <div className="link_resubmission">          
            {
              "You have few rejected timesheets."
            }          
          &nbsp;
          <Button type="link" className="primary" onClick={() => navigateToResubmission()}>
            {" "}
            Resubmit now{" "}
          </Button>
        </div>      
    );
  };

  return (
    <div className="banner_resubmission" ref={bannerHeightRef}>
      {showAlert && (<Alert
        description={<TextComponent />}
        type="warning"
        banner="true"
        showIcon
        // closable        
      />
      )}
    </div>
  );
};

