import * as Sentry from "@sentry/react"
const sentryUtils = {
  
  captureMondayException: (name, error_details, page_location) => {
    Sentry.captureException(name, {
      extra: {
        error_details: error_details,
        page_location: page_location,
      },
      tags: {
        type: 'MONDAY_QUERY_ERROR',
        "accountId": localStorage.getItem("accountId"),
      },
    });
  },

  captureFirestoreException: (name, error_details, page_location) => {
    Sentry.captureException(name, {
      extra: {
        error_details: error_details,
        page_location: page_location,
      },
      tags: {
        type: 'FIRESTORE_QUERY_ERROR',
        "accountId": localStorage.getItem("accountId"),
      },
    });
  },

  captureGenericException: (name, error_details, page_location) => {
    Sentry.captureException(name, {
      extra: {
        error_details: error_details,
        page_location: page_location,
      },
      tags: {
        type: 'GENERIC_ERROR',
        "accountId": localStorage.getItem("accountId"),
      },
    });
  },

  captureAPIException: (name, error_details, page_location) => {
    Sentry.captureException(name, {
      extra: {
        error_details: error_details,
        page_location: page_location,
      },
      tags: {
        type: 'API_ERROR',
        "accountId": localStorage.getItem("accountId"),
      },
    });
  },

  captureAPIMessageException: (name, error_details, page_location) => {
    Sentry.captureMessage(name, {
      extra: {
        error_details: error_details,
        page_location: page_location,
      },
      tags: {
        type: 'API_ERROR',
        "accountId": localStorage.getItem("accountId"),
      },
    });
  },

  
};

export default sentryUtils;
